import { Product } from '../swagger';
import { personalNumberToAge } from './personalNumberToAge';

export const byAge = (p1: Product, p2: Product) => {
  const age1 =
    p1.account &&
    p1.account.sensitive &&
    personalNumberToAge(p1.account.sensitive.personnummer);
  const age2 =
    p2.account &&
    p2.account.sensitive &&
    personalNumberToAge(p2.account.sensitive.personnummer);

  if (!age1) {
    return 1;
  }

  if (!age2) {
    return -1;
  }

  return age2 - age1;
};
