import Badge from '@material-ui/core/Badge';
import * as React from 'react';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { Account } from '../../hooks/useAccount';
import { PaymentMethods } from '../../hooks/usePaymentMethods';
import {
  Account as AccountModel,
  ConnectPaymentResponse,
  PaymentPayload,
  Person,
} from '../../swagger';
import { InfoDialog } from '../dialogs/InfoDialog';
import { PaymentMethodForm } from './PaymentMethodForm';
import { SubmitButton } from './SubmitButton';

type Props = {
  label?: string;
  force?: boolean;
  onComplete?: (response?: ConnectPaymentResponse) => void;
  onSubmit?: (payload: PaymentPayload) => void;
  hideBadge?: boolean;
  signup?: boolean;
  isBroker?: boolean;
  value?: PaymentPayload;
  mainPerson?: Person;
} & Pick<
  React.ComponentProps<typeof SubmitButton>,
  'size' | 'variant' | 'color' | 'disabled' | 'endIcon'
>;

export const AddPaymentMethodButton: React.FC<Props> = (props) => {
  const {
    label = 'add_payment',
    size = 'small',
    force = false,
    variant = 'contained',
    onComplete = () => undefined,
    onSubmit,
    color = 'secondary',
    hideBadge = false,
    signup = false,
    endIcon,
    disabled,
    isBroker,
    value,
  } = props;
  const { account, refreshAccount } = Account.useContainer();
  const { hasPaymentMethod, isPayer } = PaymentMethods.useContainer();
  const [isDialogOpen, toggleDialog] = React.useState(false);

  const openDialog = useCallback(() => {
    toggleDialog(true);
  }, []);

  const closeDialog = useCallback(
    (response?: ConnectPaymentResponse) => {
      refreshAccount();
      toggleDialog(false);
      onComplete(response);
    },
    [toggleDialog, refreshAccount, onComplete],
  );

  if (!signup && (!isPayer || (hasPaymentMethod && !force))) {
    return <></>;
  }

  if (account && account.status !== AccountModel.StatusEnum.Active) {
    return <></>;
  }

  return (
    <Badge
      color="error"
      badgeContent="!"
      invisible={hideBadge || hasPaymentMethod}
    >
      <SubmitButton
        label={label}
        color={color}
        size={size}
        variant={variant}
        onClick={openDialog}
        disabled={!isBroker && disabled}
        endIcon={endIcon}
      />
      <InfoDialog
        rich
        title={<FormattedMessage id="add_payment" />}
        isOpen={isDialogOpen}
        onClose={closeDialog}
      >
        <PaymentMethodForm
          value={value}
          isBroker={isBroker}
          onComplete={closeDialog}
          onSubmit={onSubmit}
          mainPerson={props.mainPerson}
        />
      </InfoDialog>
    </Badge>
  );
};
