import { clearPersonalNumber } from './clearPersonalNumber';
import { formatPersonalNumber } from './formatPersonalNumber';
import { personalNumberToAge } from './personalNumberToAge';

export const ageOrPnoToAge = (value = '', prefix = '') => {
  const personalNumber = clearPersonalNumber(value);
  const age =
    (personalNumber && personalNumberToAge(personalNumber)) ||
    parseInt(value.length <= 2 ? value : '', 10);

  if (!Number.isNaN(age)) {
    return `${prefix}${Math.floor(age)}`;
  }

  return '';
};

export const ageOrPnoToPno = (value = '', prefix = '') => {
  const personalNumber = clearPersonalNumber(value);

  return personalNumber
    ? `${prefix}${formatPersonalNumber(personalNumber)}`
    : '';
};
