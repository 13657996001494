import CssBaseline from '@material-ui/core/CssBaseline';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { OrderError } from './components/dialogs/OrderError';
import { Account } from './hooks/useAccount';
import { Auth } from './hooks/useAuth';
import { DrawerContainer } from './hooks/useDrawer';
import { Order } from './hooks/useOrder';
import { PaymentMethods } from './hooks/usePaymentMethods';
import { ProfileSelector } from './hooks/useProfileSelector';
import { Settings } from './hooks/useSettings';
import { Root } from './pages/Root';
import { useStyles } from './styles';
import { theme } from './styles/theme';

const App: React.FC = () => {
  useStyles();

  return (
    <BrowserRouter>
      <Settings.Provider>
        <DrawerContainer.Provider>
          <Auth.Provider>
            <Account.Provider>
              <ProfileSelector.Provider>
                <PaymentMethods.Provider>
                  <Order.Provider>
                    <ThemeProvider theme={theme}>
                      <CssBaseline />
                      <Root />
                      <OrderError />
                    </ThemeProvider>
                  </Order.Provider>
                </PaymentMethods.Provider>
              </ProfileSelector.Provider>
            </Account.Provider>
          </Auth.Provider>
        </DrawerContainer.Provider>
      </Settings.Provider>
    </BrowserRouter>
  );
};

export default App;
