import { useEffect, useState } from 'react';
import { createContainer } from 'unstated-next';
import { PriceGroups } from '../components/PricingTable';
import { Order, ProductPricing, Quiz as QuizModel } from '../swagger';
import { Account } from './useAccount';
import { useOrderParams } from './useOrderParams';
import { Quiz } from './useQuiz';

const defaultQuizAnswers = {
  needSecondOpinion: 1,
  needInsurance: 1,
  needInternational: 1,
  needLeadingDoctors: 1,
  needResult: 1,
} as QuizModel;

const useInsurance = () => {
  const { isCoinsured } = useOrderParams();
  const { account } = Account.useContainer();
  const [age, setAge] = useState<number | undefined>(undefined);
  const { quiz, saveOrCreateQuiz, answerQuestion } = Quiz.useContainer();
  const isDone = !Object.values(quiz).some(
    (value) => value === null || value === undefined,
  );
  const invalidAge = Boolean(age && ((age < 18 && !isCoinsured) || age > 64));
  const validAge = Boolean(age && !invalidAge);
  const product: QuizModel.ProductTypesEnum | undefined = invalidAge
    ? undefined
    : quiz.productTypes && quiz.productTypes[0];
  const pricing: ProductPricing | undefined = quiz &&
    product &&
    quiz.pricing && { ...quiz.pricing[product] };

  if (validAge && pricing && pricing.single) {
    pricing.single = Object.entries(pricing.single as PriceGroups).reduce(
      (acc, [key, value]) => {
        const [, max] = key.split(/\D/).map((v) => parseInt(v, 10));
        if ((age || 0) <= max) {
          acc[key] = value;
        }
        return acc;
      },
      {} as PriceGroups,
    );
  }

  const [personal, setPersonal] = useState({
    personnummer: '',
    email: '',
    phone: '',
    valid: false,
  });

  const [order, setOrder] = useState<Order | null | undefined>(undefined);

  useEffect(() => {
    if (!personal.valid && account && account.sensitive && !isCoinsured) {
      setPersonal({
        personnummer: account.sensitive.personnummer,
        email: account.sensitive.email,
        phone: account.sensitive.phone,
        valid: personal.valid,
      });
    }
  }, [account]);

  useEffect(() => {
    if (quiz.needResult !== 1) {
      saveOrCreateQuiz({
        ...quiz,
        ...defaultQuizAnswers,
      });
    }
  }, [quiz.needResult]);

  return {
    quiz,
    answerQuestion,
    isDone,
    product,
    pricing,
    age,
    setAge,
    personal,
    setPersonal,
    order,
    setOrder,
  };
};

export const Insurance = createContainer(useInsurance);
