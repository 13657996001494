import { useRouteMatch } from 'react-router';
import { useSearchParam } from 'react-use';
import { Account } from './useAccount';
import { useRoutes } from './useRoutes';

export const useOrderParams = () => {
  const { account } = Account.useContainer();
  const { insurance } = useRoutes();
  const {
    params: { id },
  } = useRouteMatch<{ id: string }>();
  const owner = useSearchParam('owner');
  const orderId = useSearchParam('id');
  const isChild = useSearchParam('child');
  const isCoinsured = Boolean(owner && orderId);
  const getCoinsuranceLink = (forChild = false) => {
    const params = new URLSearchParams();

    if (account) {
      params.append('owner', account.id);
    }

    if (id) {
      params.append('id', id);
    }

    if (forChild) {
      params.append('child', 'true');
    }

    return `${insurance}/?${params}`;
  };
  const coinsurance = getCoinsuranceLink();
  const coinsuranceChild = getCoinsuranceLink(true);

  return {
    owner,
    orderId,
    coinsurance,
    coinsuranceChild,
    isCoinsured,
    isChild,
  };
};
