import Typography from '@material-ui/core/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Content } from '../components/Content';
import { FAQSchema } from '../components/FAQSchema';
import { Markdown } from '../components/Markdown';
import { Page } from '../types';

export const FAQ: Page = () => {
  return (
    <Content text>
      <Typography variant="h3">
        <FormattedMessage id="faq.heading" />
      </Typography>
      <Markdown>faq.text</Markdown>
      <div style={{ clear: 'both' }} />

      <FAQSchema />
    </Content>
  );
};

FAQ.page = 'faq';
