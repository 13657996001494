import Bugsnag from '@bugsnag/js';
import DoneIcon from '@material-ui/icons/Done';
import React from 'react';
import { Person } from '../swagger';
import { api } from '../utils/api';
import { SubmitButton } from './forms/SubmitButton';

export const OrderProductInviteButton: React.FC<{ person: Person }> = ({
  person,
}) => {
  const [loading, setLoading] = React.useState(false);
  const [sent, setSent] = React.useState(false);

  const id = person.quiz?.id;

  const invite = React.useCallback(async () => {
    try {
      setLoading(true);

      if (id && person.email && person.personnummer) {
        await api.quizControllerInvite(id, {
          email: person.email,
          personnummer: person.personnummer,
        });
      }
      setSent(true);
      setTimeout(() => {
        setSent(false);
      }, 3000);
    } catch (e) {
      console.error(e);
      Bugsnag.notify(e);
    } finally {
      setLoading(false);
    }
  }, [id, person.email, person.personnummer]);

  return (
    <SubmitButton
      label={sent ? 'offer.sent' : 'send.again'}
      onClick={invite}
      loading={loading}
      size="small"
      variant={sent ? 'text' : 'outlined'}
      endIcon={sent && <DoneIcon />}
    />
  );
};
