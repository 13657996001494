import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import { Account } from '../hooks/useAccount';
import { ProfileSelector } from '../hooks/useProfileSelector';
import { useRoutes } from '../hooks/useRoutes';

export const ProfileSelectorTrigger = () => {
  const { account, admin } = Account.useContainer();
  const { setShouldSelect } = ProfileSelector.useContainer();
  const {
    push,
    location: { pathname },
  } = useHistory();
  const { mina } = useRoutes();

  const brokers = (account && account.brokers) || [];

  const shouldRender =
    !!brokers.length || !!account?.companies?.length || admin;

  const onClick = useCallback(() => {
    if (pathname !== mina) {
      push(mina);
    }
    setShouldSelect(true);
  }, [setShouldSelect, pathname, mina]);

  if (!shouldRender) {
    return null;
  }

  return (
    <Tooltip arrow title="Switch profile">
      <IconButton color="secondary" onClick={onClick}>
        <RecentActorsIcon />
      </IconButton>
    </Tooltip>
  );
};
