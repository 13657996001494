import * as React from 'react';
import { useRoutes } from '../../hooks/useRoutes';
import { LinkButton } from './SubmitButton';

export const InsureSelfButton: React.FC = () => {
  const { insurance } = useRoutes();

  return (
    <LinkButton
      label="choose_insurance"
      to={insurance}
      size="small"
      color="primary"
    />
  );
};
