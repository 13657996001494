import * as React from 'react';

const LazyFaktablad = React.lazy<typeof import('../pdf/Faktablad').Faktablad>(
  () =>
    import('../pdf/Faktablad').then((module) => ({
      default: module.Faktablad,
    })),
);
export const SuspenseFaktablad: typeof import('../pdf/Faktablad').Faktablad = (
  props,
) => (
  <React.Suspense fallback={''}>
    <LazyFaktablad {...props} />
  </React.Suspense>
);

SuspenseFaktablad.page = 'faktablad';
