import Bugsnag from '@bugsnag/js';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { theme } from '../../styles/theme';
import {
  BankRequest,
  BankRequestAccount,
  BankRequestPayload,
} from '../../swagger';
import { api } from '../../utils/api';
import { formatPersonalNumber } from '../../utils/formatPersonalNumber';
import { LinkButton } from '../forms/SubmitButton';
import { InfoDialog } from './InfoDialog';
import BankEnum = BankRequestPayload.BankEnum;
import StatusEnum = BankRequest.StatusEnum;

const styles = makeStyles({
  buttons: {
    '& .MuiButton-root': {
      borderRadius: 0,
    },

    '& .MuiButton-label': {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      textAlign: 'left',
    },

    '& .MuiTypography-root': {
      marginBottom: 0,
    },
  },
});

type Props = {
  open: boolean;
  personnummer?: string;
  onClose: (account?: BankRequestAccount) => void;
};

const banks: Record<BankEnum, string> = {
  [BankEnum.FSPA]: 'Swedbank',
  [BankEnum.FSPASB]: 'Swedbank Sparbankerna',
  [BankEnum.ICA]: 'ICA',
  [BankEnum.LFB]: 'Lansforsakringar',
  [BankEnum.NB]: 'Nordea',
  [BankEnum.OEB]: 'DanskeBank',
  [BankEnum.SBAB]: 'SBAB',
  [BankEnum.SEB]: 'SEB',
  [BankEnum.SHB]: 'Handelsbanken',
  [BankEnum.SKB]: 'Skandia',
  [BankEnum.SYD]: 'Sparbanken Syd',
};

export const BankAccountsDialog: React.FC<Props> = (props) => {
  const { open, personnummer, onClose } = props;
  const { buttons } = styles();
  const [loading, setLoading] = React.useState(false);
  const [clicked, setClicked] = React.useState(false);
  const [request, setRequest] = React.useState<BankRequest>();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const notStarted = !request?.qr && !request?.token && !!personnummer;
  const isWaiting = request?.status === StatusEnum.Waiting;
  const isReady = request?.status === StatusEnum.Success;
  const isFailed = request?.status === StatusEnum.Failed;

  const startFetching = React.useCallback(
    async (bank: BankEnum) => {
      if (!personnummer) {
        return;
      }

      setLoading(true);

      try {
        setRequest(
          await api.bankControllerStartFetching({
            personnummer,
            bank,
          }),
        );
      } catch (e) {
        console.error(e);
        Bugsnag.notify(e);
      } finally {
        setLoading(false);
      }
    },
    [personnummer],
  );

  React.useEffect(() => {
    if (!open) {
      setRequest(undefined);
      setClicked(false);
    }
  }, [open]);

  React.useEffect(() => {
    if (clicked) {
      setTimeout(() => {
        setClicked(false);
      }, 12000);
    }
  }, [clicked]);

  React.useEffect(() => {
    setLoading(isWaiting && notStarted);
    let stillOpen = true;

    if (request?.id && request?.status === StatusEnum.Waiting) {
      setTimeout(async () => {
        const req = await api.bankControllerCheckFetching(request.id);
        if (stillOpen) {
          setRequest(req);
        }
      }, 2000);
    }

    return () => {
      stillOpen = false;
    };
  }, [request, open]);

  const getTitle = () => {
    if (isWaiting) {
      return 'BankID auth...';
    }

    if (isReady) {
      return 'Select bank account';
    }

    if (isFailed) {
      return 'Please, try again';
    }

    return 'Fetch bank accounts';
  };

  const bankIDLink = new URL('https://app.bankid.com/');
  bankIDLink.searchParams.append('autostarttoken', request?.token || '');
  bankIDLink.searchParams.append('redirect', 'null');

  return (
    <InfoDialog title={getTitle()} isOpen={open} onClose={onClose}>
      {(!request || notStarted) && personnummer && (
        <>
          <Typography>
            Select bank from which we should fetch list of bank accounts for{' '}
            {formatPersonalNumber(personnummer)}. After authorising with BankID,
            we will show your bank clearing and account numbers and will not
            store any data.
          </Typography>
          <ButtonGroup
            className={buttons}
            orientation="vertical"
            color="primary"
            variant="text"
            disabled={loading}
            fullWidth
          >
            {Object.entries(banks)
              .sort(([, nameA], [, nameB]) => nameA.localeCompare(nameB))
              .map(([key, name]) => (
                <Button
                  key={key}
                  onClick={() => startFetching(key as BankEnum)}
                >
                  {name}
                </Button>
              ))}
          </ButtonGroup>
        </>
      )}
      {isWaiting && (
        <>
          {!isMobile && request?.qr && (
            <>
              <Typography>Scan the image using Mobile BankID app.</Typography>
              <img
                src={request.qr}
                width={300}
                alt="QR"
                style={{
                  imageRendering: 'pixelated',
                  display: 'block',
                  margin: '0 auto',
                }}
              />
            </>
          )}
          {isMobile && request?.token && (
            <>
              <Typography>
                Click the button to open Mobile BankID app, authorise and return
                to this page.
              </Typography>
              {!clicked && (
                <LinkButton
                  label="open_bankid"
                  to={bankIDLink.toString()}
                  onClick={() => setTimeout(() => setClicked(true), 500)}
                  target="_blank"
                />
              )}
              {clicked && (
                <CircularProgress
                  variant="indeterminate"
                  color="secondary"
                  size={24}
                />
              )}
            </>
          )}
        </>
      )}
      {isReady && (
        <>
          <ButtonGroup
            className={buttons}
            orientation="vertical"
            color="primary"
            variant="text"
            disabled={loading}
            fullWidth
          >
            {request?.accounts
              ?.sort((accA, accB) => {
                if (accA.autogiro) return -1;
                if (accB.autogiro) return 1;
                return 0;
              })
              .map((account) => (
                <Button key={account.name} onClick={() => onClose(account)}>
                  <span>
                    {account.name}{' '}
                    {account.autogiro && (
                      <Chip
                        color="secondary"
                        label={<FormattedMessage id="autogiro" />}
                      />
                    )}
                  </span>
                  <Typography variant="body2">
                    {account.clearing} {account.account}
                  </Typography>
                </Button>
              ))}
          </ButtonGroup>
        </>
      )}
    </InfoDialog>
  );
};
