import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { AddPerson } from '../components/AddPerson';
import { Content } from '../components/Content';
import { InsuranceCompletionDialog } from '../components/dialogs/InsuranceCompletionDialog';
import { InsuranceQuizDialog } from '../components/dialogs/InsuranceQuizDialog';
import { Documents } from '../components/Documents';
import { SubmitButton } from '../components/forms/SubmitButton';
import { InsurancePricingSelect } from '../components/insurance/InsurancePricingSelect';
import { InsuranceConsent } from '../components/InsuranceConsent';
import { InsuranceHeader } from '../components/InsuranceHeader';
import { InsurancePayment } from '../components/InsurancePayment';
import { InsurancePersons } from '../components/InsurancePersons';
import { InsurancePreferences } from '../components/InsurancePreferences';
import { Markdown } from '../components/Markdown';
import { PricingFrom } from '../components/PricingFrom';
import { Account } from '../hooks/useAccount';
import { Insurance as InsuranceHook } from '../hooks/useInsurance';
import { useMultiPreview } from '../hooks/useMultiPreview';
import { PaymentMethods } from '../hooks/usePaymentMethods';
import { useProductOrder } from '../hooks/useProductOrder';
import { useQuiz } from '../hooks/useQuiz';
import { useRoutes } from '../hooks/useRoutes';
import bankidLogo from '../public/bankid_white.svg';
import { insuranceStyles } from '../styles/insurance';
import { Order, OrderPayload, Person, Quiz } from '../swagger';
import { Page } from '../types';
import { ageOrPnoToAge } from '../utils/ageOrPersonalNumber';
import { breakdownPersons } from '../utils/breakdownPersons';
import { clearPersonalNumber } from '../utils/clearPersonalNumber';
import { parseQuiz } from '../utils/parseQuiz';
import { InsuranceDone } from './InsuranceDone';
import ProductTypesEnum = Quiz.ProductTypesEnum;
import TypeEnum = Person.TypeEnum;

export const Insurance: Page = () => {
  const { card, actions, buttons, signature, after } = insuranceStyles();
  const {
    account,
    pricing: completePricing,
    refreshAccount,
    admin,
  } = Account.useContainer();
  const [order, setOrder] = React.useState<Order>();

  const checkup = useProductOrder({
    sessionStorageKey: 'insurance',
    order,
    defaultPaymentPeriod: order?.paymentPeriod || 1,
  });

  const preview = useMultiPreview(
    checkup,
    setOrder,
    OrderPayload.ProductTypeEnum.HealthPlus,
  );

  const { hasPaymentMethod } = PaymentMethods.useContainer();
  const { saveOrCreateQuiz } = useQuiz();
  const { mina } = useRoutes();
  const [finalOrderId, setFinalOrderId] = React.useState('');
  const [submitting, setSubmitting] = React.useState(false);
  const [currentPerson, setCurrentPerson] = React.useState<Person>();

  const { main, spouse } = breakdownPersons(checkup.persons);
  const pricing = (order?.mainPricing || completePricing || {})[
    ProductTypesEnum.HealthPlus
  ];
  const mainQuiz = main?.quiz && parseQuiz(main.quiz);
  const canGetInsurance = checkup.isBroker
    ? checkup.persons.length
    : !!order?.type &&
      !!mainQuiz?.isDone &&
      (hasPaymentMethod ||
        typeof checkup.pendingMethod?.manual === 'boolean') &&
      checkup.consent;

  const closeDialog = React.useCallback(() => {
    setCurrentPerson(undefined);
  }, []);

  const savePerson = React.useCallback(
    (person: Person, shouldCloseDialog = true) => {
      const updatedPersons = [...checkup.persons];
      const existingPerson = updatedPersons.find((pr) => pr === person);

      if (existingPerson) {
        Object.assign(existingPerson, person);
      } else {
        updatedPersons.push(person);
      }

      const {
        main: m,
        spouse: s,
        children: c,
      } = breakdownPersons(updatedPersons);
      checkup.setPersons(
        [m, s, ...c].filter((v): v is Person => typeof v === 'object'),
      );

      if (shouldCloseDialog) {
        closeDialog();
      }
    },
    [checkup.persons],
  );

  const addChild = React.useCallback(async () => {
    setCurrentPerson({
      type: Person.TypeEnum.Child,
      quiz: await saveOrCreateQuiz({} as Quiz),
    });
  }, []);

  const addSpouse = React.useCallback(async () => {
    setCurrentPerson({
      type: Person.TypeEnum.Spouse,
      quiz: await saveOrCreateQuiz({} as Quiz),
    });
  }, []);

  const removePerson = React.useCallback(
    async (person: Person) => {
      checkup.setPersons(
        person.type === TypeEnum.Main
          ? []
          : checkup.persons.filter((pr) => pr !== person),
      );
      if (person.type === TypeEnum.Main) {
        checkup.setReady(false);
      }
      closeDialog();
    },
    [checkup.persons],
  );

  const onSuccess = React.useCallback(
    async (finalOrder?: Order) => {
      if (!checkup.isBroker) {
        await refreshAccount();
        setSubmitting(false);
        checkup.setPersons([]);
      }

      setFinalOrderId(finalOrder?.id || 'brokerOffer');
    },
    [mina, refreshAccount, checkup.isBroker],
  );

  const startSubmitting = React.useCallback(() => {
    setSubmitting(true);
  }, []);

  const stopSubmitting = React.useCallback(() => {
    setSubmitting(false);
  }, []);

  const updatePerson = React.useCallback(
    (pr) => {
      savePerson(pr, false);
    },
    [savePerson],
  );

  if (finalOrderId) {
    return (
      <InsuranceDone isBroker={checkup.isBroker} finalOrderId={finalOrderId} />
    );
  }

  return (
    <InsuranceHook.Provider>
      <InsuranceHeader isBroker={checkup.isBroker} isAdmin={admin} />
      <Content className={checkup.isBroker ? 'is-broker' : undefined}>
        {!checkup.isBroker && (
          <Box pb={2} px={2}>
            <Markdown
              variant="body2"
              color="textPrimary"
              children="insurance.summary"
            />
          </Box>
        )}

        <Box pb={4}>
          <Typography
            variant="h4"
            style={{ color: '#105BBF', margin: '12px 0 20px' }}
          >
            <FormattedMessage id="persons" />
          </Typography>

          <Card elevation={1} className={card} style={{ position: 'relative' }}>
            <InsurancePersons
              isBroker={checkup.isBroker}
              persons={checkup.persons}
              products={order?.products}
              setCurrentPerson={setCurrentPerson}
              removePerson={removePerson}
              updatePerson={updatePerson}
            />
          </Card>
          {order?.type && (
            <Card elevation={0} className={`${card} ${actions}`}>
              <CardContent>
                <Box className={buttons}>
                  <Box>
                    {!spouse && (
                      <AddPerson
                        isBroker={checkup.isBroker}
                        type={Person.TypeEnum.Spouse}
                        order={order}
                        onClick={addSpouse}
                        pricing={pricing}
                      />
                    )}

                    <AddPerson
                      isBroker={checkup.isBroker}
                      type={Person.TypeEnum.Child}
                      order={order}
                      onClick={addChild}
                      pricing={pricing}
                    />
                  </Box>
                </Box>
              </CardContent>
            </Card>
          )}
        </Box>

        <InsuranceQuizDialog
          label={checkup.isBroker ? 'save' : undefined}
          isBroker={checkup.isBroker}
          person={currentPerson}
          onClose={closeDialog}
          onSave={savePerson}
        />

        <Typography
          variant="h4"
          style={{ color: '#105BBF', margin: '12px 0 20px' }}
        >
          <FormattedMessage id="payment_details" />
        </Typography>

        <Box pb={4}>
          <Card elevation={1} className={card} style={{ position: 'relative' }}>
            <InsurancePayment
              isBroker={checkup.isBroker}
              order={order}
              pendingMethod={checkup.pendingMethod}
              setPendingMethod={checkup.setPendingMethod}
              mainPerson={main}
            />
          </Card>
          <Card elevation={0} className={`${card} ${actions}`}>
            <CardContent className="insurance-section">
              <InsurancePreferences
                isBroker={checkup.isBroker}
                isAdmin={admin}
                order={order}
                setPaymentPeriod={checkup.setPaymentPeriod}
                startDate={checkup.startDate}
                setStartDate={checkup.setStartDate}
              />
              {!checkup.offerId && (
                <InsurancePricingSelect
                  selectedPricingId={order?.mainPricing?.id}
                  selectedBrokerId={checkup.selectedBroker}
                  pricings={checkup.pricings}
                  brokers={checkup.availableBrokers}
                  onPricingChange={checkup.setSelectedPricing}
                  onBrokerChange={checkup.setSelectedBroker}
                />
              )}
            </CardContent>
          </Card>
        </Box>

        <Card elevation={0} className={`${card} ${signature}`}>
          <CardContent style={{ paddingLeft: 0, paddingRight: 0 }}>
            {!checkup.isBroker && (
              <Box
                px={2}
                className={
                  !checkup.consent ? 'yellow-marker marker-top' : undefined
                }
              >
                <InsuranceConsent
                  labels={[
                    'insurance_consent1',
                    'insurance_consent2',
                    'insurance_consent3',
                    'insurance_consent5',
                  ]}
                  consent={checkup.consent}
                  onConsent={checkup.setConsent}
                />
              </Box>
            )}
            {pricing && (
              <Box pb={2}>
                <PricingFrom
                  type={order?.type}
                  age={parseInt(ageOrPnoToAge(main?.personnummer), 10)}
                  pricing={pricing}
                  style={{ fontSize: '18px' }}
                  paymentPeriod={checkup.paymentPeriod}
                >
                  <FormattedMessage id="total" />{' '}
                </PricingFrom>
              </Box>
            )}
            <Box className={!submitting ? 'yellow-marker' : undefined}>
              <SubmitButton
                size="large"
                label={checkup.isBroker ? 'submit' : 'insurance_create'}
                loading={preview.loading || submitting}
                disabled={!canGetInsurance || submitting}
                onClick={startSubmitting}
                endIcon={
                  !account ? (
                    <img
                      src={bankidLogo}
                      alt="BankID"
                      width={24}
                      height={24}
                      className="invert-disabled"
                    />
                  ) : (
                    <DoneAllIcon />
                  )
                }
              />
            </Box>
          </CardContent>
          <InsuranceCompletionDialog
            payload={{
              persons: checkup.persons,
              paymentMethod: checkup.pendingMethod,
              paymentPeriod: checkup.paymentPeriod,
              pricingId: order?.mainPricing?.id,
              brokerId: checkup.selectedBroker,
              consent: checkup.consent,
              offerId: checkup.offerId,
              startDate: checkup.startDate,
            }}
            isOpen={submitting}
            onClose={stopSubmitting}
            onSuccess={onSuccess}
            initPersonnummer={
              checkup.pendingMethod?.personnummer
                ? clearPersonalNumber(checkup.pendingMethod?.personnummer)
                    ?.length === 10
                  ? main?.personnummer
                  : checkup.pendingMethod?.personnummer
                : main?.personnummer
            }
          />
        </Card>

        {!checkup.isBroker && (
          <Box className={after} pt={3}>
            <Box>
              <Markdown children="insurance.after.1" variant="body2" />
            </Box>
            <Box>
              <Markdown children="insurance.after.2" variant="body2" />
            </Box>
            <Box>
              <Markdown children="insurance.after.3" variant="body2" />
            </Box>
            <Box>
              <Markdown children="insurance.after.4" variant="body2" />
            </Box>
          </Box>
        )}

        {!checkup.isBroker && (
          <Box pt={2} pl={3} pr={3}>
            <Documents />
          </Box>
        )}
      </Content>
    </InsuranceHook.Provider>
  );
};

Insurance.page = 'insurance';
