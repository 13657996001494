import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';
import doctorPaper from '../public/doctor_paper.svg';
import doctorScanner from '../public/doctor_scanner.svg';
import doctorTable from '../public/doctor_table.svg';
import doctorXRay from '../public/doctor_xray.svg';
import { theme } from '../styles/theme';

const styles = makeStyles<typeof theme, Props>({
  main: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
  },
  container: {
    height: '100%',
    paddingBottom: ({ overlap }) => overlap,
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'stretch',
    overflow: 'hidden',
  },
  holder: {
    flex: 1,
    position: 'relative',

    '& img': {
      position: 'absolute',
    },
  },
  table: {
    height: '37%',
    left: '0%',
    bottom: '-0.8%',
    display: 'block',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      left: '38%',
      height: 'calc(37% * 0.8)',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  scanner: {
    height: '46%',
    left: '9%',
    bottom: '-0.5%',
    display: 'block',
    [theme.breakpoints.down('md')]: {
      left: '-2%',
    },
    [theme.breakpoints.down('sm')]: {
      left: '-10%',
      height: 'calc(46% * 0.8)',
    },
    [theme.breakpoints.down('xs')]: {
      height: 'calc(46% * 0.7)',
    },
  },
  paper: {
    height: '40%',
    right: '1%',
    bottom: '0%',
    display: 'block',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
      right: '48%',
      height: 'calc(40% * 0.8)',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      right: '1%',
      height: 'calc(40% * 0.7)',
    },
  },
  xray: {
    height: '48%',
    right: '8%',
    bottom: '-0.7%',
    display: 'block',
    [theme.breakpoints.down('sm')]: {
      right: '-5%',
      height: 'calc(48% * 0.8)',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
});

interface Props {
  overlap: string;
}

export const Illustration: React.FC<Props> = React.memo(({ overlap }) => {
  const { main, container, holder, table, scanner, paper, xray } = styles({
    overlap,
  });

  return (
    <Box className={main}>
      <Container className={container}>
        <Box className={holder}>
          <img
            src={doctorScanner}
            className={scanner}
            alt="doctor and patient scanner"
          />
          <img
            loading="lazy"
            src={doctorTable}
            className={table}
            alt="doctor table"
          />
          <img src={doctorPaper} className={paper} alt="doctor results paper" />
          <img
            loading="lazy"
            src={doctorXRay}
            className={xray}
            alt="doctor and patient xray"
          />
        </Box>
      </Container>
    </Box>
  );
});
