export const removeStyleTags = (rootElement: Element) => {
  const styles = document.querySelectorAll('head style');

  if (rootElement) {
    rootElement.innerHTML = '';
  }

  styles.forEach(
    (style) => style.parentElement && style.parentElement.removeChild(style),
  );
};
