import { DateTime } from 'luxon';
import { clearPersonalNumber } from './clearPersonalNumber';

export const personalNumberToDate = (input: string): DateTime | undefined => {
  const personnummer = clearPersonalNumber(input);

  if (personnummer) {
    return DateTime.fromFormat(personnummer.substr(0, 8), 'yyyyMMdd');
  }

  return;
};
