import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import SecurityIcon from '@material-ui/icons/Security';
import React from 'react';
import { Account, Broker } from '../swagger';

const AdminIcon = SecurityIcon;
const BrokerIcon = BusinessCenterIcon;
const CompanyIcon = LocationCityIcon;
const PersonIcon = AccountCircleIcon;

const isBroker = (account?: Account | Broker): account is Broker =>
  !!account && 'name' in account;

export const getAccountIcon = (
  account?: Account | Broker,
  admin?: boolean,
  broker?: boolean,
  props: React.ComponentProps<typeof SvgIcon> = { color: 'secondary' },
) => {
  if (admin) {
    return <AdminIcon {...props} />;
  }

  if (broker || isBroker(account)) {
    return <BrokerIcon {...props} />;
  }

  if (account?.type === Account.TypeEnum.Company) {
    return <CompanyIcon {...props} />;
  }

  return <PersonIcon {...props} />;
};
