import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { SubmitButton } from '../components/forms/SubmitButton';
import { ProfileSelector as ProfileSelectorContainer } from '../hooks/useProfileSelector';
import { getAccountIcon } from '../icons/Profile';
import { Account, Broker } from '../swagger';

const ProfileOption: React.FC<{ profile: Account | Broker }> = ({
  profile,
}) => {
  const { selectProfile } = ProfileSelectorContainer.useContainer();
  const name =
    'sensitive' in profile &&
    profile.sensitive &&
    (profile.sensitive.fullName || profile.sensitive.personnummer);
  const brokerName = 'name' in profile && profile.name;
  const [loading, setLoading] = React.useState(false);

  const handleClick = React.useCallback(async () => {
    setLoading(true);
    await selectProfile(profile.id);
  }, [selectProfile, profile.id]);

  return (
    <SubmitButton
      label={name || brokerName || profile.id}
      loading={loading}
      variant="outlined"
      style={{ marginBottom: '16px', justifyContent: 'flex-start' }}
      onClick={handleClick}
      startIcon={getAccountIcon(profile)}
    />
  );
};

export const ProfileSelector: React.FC = () => {
  const { profileOptions } = ProfileSelectorContainer.useContainer();

  return (
    <Box display="flex" flexDirection="column">
      <Typography>
        <FormattedMessage id="choose_account" />
      </Typography>

      {profileOptions.map((profile) =>
        profile?.id ? (
          <ProfileOption key={profile.id} profile={profile} />
        ) : null,
      )}
    </Box>
  );
};
