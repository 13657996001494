import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import useBoolean from 'react-use/lib/useBoolean';
import { Order } from '../../hooks/useOrder';
import { SubmitButton } from '../forms/SubmitButton';
import { Markdown } from '../Markdown';
import { InfoDialog } from './InfoDialog';

const styles = makeStyles({
  reasons: {
    width: '100%',

    '& .MuiTypography-body1': {
      margin: 0,
      width: '100%',
    },
    '& .MuiInputBase-input': {
      fontSize: '1rem',
    },
  },
});

type Props = {
  id: string;
  isOpen: boolean;
  onClose: () => void;
};

export const CancellationDialog: React.FC<Props> = (props) => {
  const { id, isOpen, onClose } = props;
  const { removeProduct } = Order.useContainer();
  const { reasons } = styles();
  const { messages } = useIntl();
  const [removing, toggleRemoving] = useBoolean(false);
  const [note, setNote] = React.useState('');
  const [customNote, setCustomNote] = React.useState('');
  const otherReason = `${messages.cancellationReason4}`;
  const reason = (note === otherReason && customNote) || note;

  const remove = React.useCallback(async () => {
    toggleRemoving();
    await removeProduct(id, reason);
    toggleRemoving();
  }, [id, reason, removeProduct]);

  return (
    <InfoDialog
      title={<FormattedMessage id="cancel.title" />}
      isOpen={isOpen}
      onClose={onClose}
      actions={
        <SubmitButton
          loading={removing}
          label="cancel.cta"
          size="medium"
          color="secondary"
          onClick={remove}
          disabled={!reason}
        />
      }
    >
      <Markdown>cancel.text</Markdown>
      <Markdown>cancellationReason.intro</Markdown>
      <FormControl component="fieldset" className={reasons}>
        <RadioGroup
          aria-label="gender"
          name="gender1"
          value={note}
          onChange={(_, value) => setNote(value)}
        >
          <FormControlLabel
            value={messages.cancellationReason1}
            control={<Radio />}
            label={messages.cancellationReason1}
          />
          <FormControlLabel
            value={messages.cancellationReason2}
            control={<Radio />}
            label={messages.cancellationReason2}
          />
          <FormControlLabel
            value={messages.cancellationReason3}
            control={<Radio />}
            label={messages.cancellationReason3}
          />
          <FormControlLabel
            value={otherReason}
            control={<Radio />}
            label={
              <TextField
                fullWidth
                placeholder={otherReason}
                onFocus={() => setNote(otherReason)}
                onInput={({ target }) =>
                  setCustomNote((target as HTMLInputElement).value)
                }
              />
            }
          />
        </RadioGroup>
      </FormControl>
    </InfoDialog>
  );
};
