import { createTheme } from '@material-ui/core/styles';
import { theme } from './theme';

const footerText = {
  ...theme.typography.body2,
  fontSize: '0.8rem',
  color: 'rgba(255, 255, 255, 0.8)',
  marginBottom: '8px',
};
export const darkTheme = createTheme({
  ...theme,
  palette: {
    ...theme.palette,
  },
  typography: {
    ...theme.typography,
    body2: footerText,
  },
  overrides: {
    ...theme.overrides,
    MuiListItem: {},
    MuiLink: {
      root: {
        ...(theme.overrides?.MuiLink?.root || {}),
        ...footerText,
        marginBottom: '0',
      },
      underlineHover: {
        '&:hover': {
          textDecoration: 'none',
          borderBottomColor: '#fff',
        },
      },
    },
    MuiSelect: {
      icon: {
        color: 'inherit',
      },
    },
    MuiMenuItem: {
      root: {
        color: theme.palette.text.primary,
      },
    },
    MuiInput: {
      root: {
        marginBottom: '8px',
      },
      underline: {
        '&:before': {
          borderBottomColor: 'rgba(255, 255, 255, 0.8)',
        },
        '&:after': {
          borderBottomColor: 'rgba(255, 255, 255, 0.8)',
        },
      },
    },
    MuiDialogTitle: {
      root: {
        color: theme.palette.text.primary,
      },
    },
  },
});
