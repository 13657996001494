import React from 'react';
import { Pricing } from '../swagger';
import { api } from '../utils/api';

export const usePricings = (shouldFetch: boolean, deps: any[] = []) => {
  const [pricings, setPricings] = React.useState<Pricing[]>([]);

  const loadPricings = React.useCallback(async () => {
    setPricings(await api.adminControllerGetPricings());
  }, []);

  React.useEffect(() => {
    if (shouldFetch) {
      loadPricings().catch((e) => console.error(e));
    } else if (pricings.length) {
      setPricings([]);
    }
  }, [shouldFetch, ...deps]);

  return [pricings, setPricings, loadPricings] as const;
};
