import React from 'react';
import { Page } from '../../types';

const LazyReferral = React.lazy<typeof import('../Referral').Referral>(() =>
  import('../Referral').then((module) => ({ default: module.Referral })),
);

export const SuspenseReferral: Page = (props) => (
  <React.Suspense fallback={''}>
    <LazyReferral {...props} />
  </React.Suspense>
);

SuspenseReferral.page = 'referral';
