import makeStyles from '@material-ui/core/styles/makeStyles';
import * as React from 'react';
import billectaLogo from '../public/bankgirot.svg';
import trustlyLogo from '../public/trustly.svg';
import { PaymentMethod } from '../swagger';

const styles = makeStyles({
  img: {
    height: '25px',
    verticalAlign: 'middle',
    border: '0 none',
  },
});

interface Props {
  gateway: PaymentMethod.GatewayEnum;
}

export const PaymentGatewayLogo: React.FC<Props> = ({ gateway }) => {
  const { img } = styles();

  if (gateway === PaymentMethod.GatewayEnum.Trustly) {
    return <img src={trustlyLogo} alt={`${gateway}`} className={img} />;
  }

  if (gateway === PaymentMethod.GatewayEnum.Billecta) {
    return <img src={billectaLogo} alt={`${gateway}`} className={img} />;
  }

  return <>${`${gateway}`}</>;
};
