import Box from '@material-ui/core/Box';
import React from 'react';
import { useParams } from 'react-router';
import { Content } from '../components/Content';
import { Documents } from '../components/Documents';
import { SubmitButton } from '../components/forms/SubmitButton';
import { InsuranceConsent } from '../components/InsuranceConsent';
import { InsuranceHeader } from '../components/InsuranceHeader';
import {
  InsuranceProductStatus,
  Status,
} from '../components/InsuranceProductStatus';
import { InsuranceQuizTable } from '../components/InsuranceQuizTable';
import { Markdown } from '../components/Markdown';
import { Account } from '../hooks/useAccount';
import { Auth } from '../hooks/useAuth';
import { Insurance as InsuranceHook } from '../hooks/useInsurance';
import { useQuiz } from '../hooks/useQuiz';
import { useRouter } from '../hooks/useRouter';
import { useRoutes } from '../hooks/useRoutes';
import { Settings } from '../hooks/useSettings';
import bankidLogo from '../public/bankid_white.svg';
import { theme } from '../styles/theme';
import { Person, Product } from '../swagger';
import { Page } from '../types';
import { clearPersonalNumber } from '../utils/clearPersonalNumber';
import TypeEnum = Person.TypeEnum;

export const Coinsurance: Page = () => {
  const { id } = useParams<{ id: string }>();
  const { settings } = Settings.useContainer();
  const { account, refreshAccount } = Account.useContainer();
  const { startAuth, status: authStatus } = Auth.useContainer();
  const [loading, setLoading] = React.useState(true);
  const [submitting, setSubmitting] = React.useState(false);
  const currentPersonalnumber = account?.sensitive?.personnummer;
  const { history } = useRouter();
  const { mina } = useRoutes();

  const { quiz, load, answerQuestion, saveOrCreateQuiz, giveConsent, isDone } =
    useQuiz();
  const [person, setPerson] = React.useState<Person>({ type: TypeEnum.Main });
  const clearPNO = clearPersonalNumber(person.personnummer);
  const isSamePNO = currentPersonalnumber === clearPNO;
  const completed = isDone && typeof quiz.age === 'number';

  React.useEffect(() => {
    if (person.quiz !== quiz) {
      setPerson({ ...person, quiz });
    }
  }, [quiz, person.quiz]);

  React.useEffect(() => {
    setLoading(true);
    load(id).finally(() => {
      setLoading(false);
      setPerson({ ...person, quiz });
    });
  }, [id]);

  const complete = () => {
    setSubmitting(true);

    if (clearPNO && !isSamePNO) {
      startAuth({ personnummer: clearPNO });
    }
  };

  React.useEffect(() => {
    if (submitting && isSamePNO) {
      saveOrCreateQuiz(quiz, true).finally(() => {
        setSubmitting(false);

        refreshAccount().then((acc) => {
          const order = acc?.orders?.length === 1 && acc.orders[0];

          if (order) {
            history.push(`${mina}/order-${order.id}`);
          } else if (
            acc?.products?.some((pr) =>
              [Product.StatusEnum.Inactive, Product.StatusEnum.Active].includes(
                pr.status,
              ),
            )
          ) {
            history.push(mina);
          }
        });
      });
    }
  }, [submitting, isSamePNO]);

  React.useEffect(() => {
    if (
      submitting &&
      !authStatus &&
      !currentPersonalnumber &&
      !settings.token
    ) {
      setSubmitting(false);
    }
  }, [submitting, authStatus, currentPersonalnumber, settings.token]);

  return (
    <InsuranceHook.Provider>
      <InsuranceHeader />
      <Content>
        <Markdown children="coinsurance.text" />
        <Box pb={4}>
          {!!quiz.id && !loading && (
            <>
              {!completed && (
                <InsuranceQuizTable
                  person={person}
                  onAnswer={answerQuestion}
                  onPerson={(p) => setPerson({ ...p })}
                />
              )}
              <InsuranceConsent
                labels={[
                  'insurance_consent1',
                  'insurance_consent2',
                  'insurance_consent3',
                  'insurance_consent5',
                ]}
                consent={Boolean(quiz.needResult)}
                onConsent={giveConsent}
              />
              <InsuranceProductStatus person={person}>
                {(status) => {
                  const disabled =
                    status !== Status.SUCCESS ||
                    completed ||
                    !clearPNO ||
                    !quiz.needResult;

                  if (completed) {
                    return (
                      <Box pt={2}>
                        <Markdown
                          children="quiz.completed"
                          style={{ color: theme.palette.text.primary }}
                        />
                      </Box>
                    );
                  }

                  return (
                    <>
                      <SubmitButton
                        label="save_quiz"
                        disabled={disabled}
                        loading={submitting}
                        onClick={complete}
                        endIcon={
                          !isSamePNO ? (
                            <img
                              src={bankidLogo}
                              alt="BankID"
                              width={24}
                              height={24}
                              className="invert-disabled"
                            />
                          ) : undefined
                        }
                      />
                    </>
                  );
                }}
              </InsuranceProductStatus>
            </>
          )}
        </Box>
        <Box pt={2} pl={3} pr={3}>
          <Documents />
        </Box>
      </Content>
    </InsuranceHook.Provider>
  );
};

Coinsurance.page = 'coinsurance';
