import { FormControl, MenuItem, Select } from '@material-ui/core';
import React from 'react';
import { Lab } from '../../swagger';
import { api } from '../../utils/api';

interface LabSelectProps {
  onChange: (locality: string) => void;
}

export const LabsSelect: React.FC<LabSelectProps> = (props) => {
  const [labs, setLabs] = React.useState<Lab[]>([]);

  React.useEffect(() => {
    api
      .productControllerGetLabs()
      .then((labsResult) => sortAndRemoveDuplicates(labsResult))
      .catch((error) => console.log(error));
  }, []);

  const sortAndRemoveDuplicates = (labs: Lab[]): void => {
    setLabs(
      labs
        .sort((a, b) => (a.locality > b.locality ? 1 : -1))
        .reduce((a: Lab[], b: Lab) => {
          if (!a.length) a[0] = b;
          if (b.locality != a[a.length - 1].locality) a.push(b);
          return a;
        }, []),
    );
  };

  return (
    <>
      <FormControl style={{ width: '14em', float: 'left' }} variant="outlined">
        <Select
          labelId="labs-select-label"
          id="labs-select"
          onChange={(event) =>
            props.onChange(event.currentTarget.value as string)
          }
        >
          {labs.map((lab) => (
            <MenuItem value={lab.locality}>{lab.locality}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};
