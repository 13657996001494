import { alpha } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { blue, Lato } from '../styles';
import { theme, uppercased } from '../styles/theme';
import { Quiz } from '../swagger';

export const styles = makeStyles({
  options: {
    whiteSpace: 'nowrap',
    textAlign: 'right',
    paddingLeft: 0,

    '& b': {
      ...uppercased,
      textTransform: 'uppercase',
      fontSize: '0.8125rem',
      display: 'inline-block',
      padding: '3px 9px',
      border: '1px solid rgba(0, 46, 92, 0.5)',
      minWidth: '3rem',
      textAlign: 'center',
      borderRadius: '40px',
      cursor: 'pointer',
      color: blue,
      lineHeight: 1.75,

      '&:last-of-type': {
        marginLeft: '12px',
      },
    },
  },
  selected: {
    cursor: 'default!important',
    borderColor: `${blue}!important`,
    boxShadow: `inset 1px 0px 0px 1px ${blue}`,
  },
  unselected: {
    color: `${theme.palette.text.secondary}!important`,
    borderColor: `rgba(0,0,0,0.1)!important`,
    opacity: 0.7,
  },
  textField: {
    fontFamily: Lato,
    fontSize: 'inherit',
    fontWeight: 'bold',
    background: 'transparent',
    border: '0 none',
    WebkitAppearance: 'none',
    borderBottom: `1px solid ${alpha(blue, 0.4)}`,
    textAlign: 'center',
  },
});

export const QuizTableBMI: React.FC<{ children: number | undefined }> = ({
  children,
}) => {
  if (children) {
    return (
      <>
        , <FormattedMessage id="bmi" /> {children}
      </>
    );
  }
  return <></>;
};

type Props = {
  type: keyof Quiz;
  currentAnswer?: number;
  noToAll?: boolean;
  onAnswer?: (type: keyof Quiz, answer: number) => void;
};

export const QuizTableOptions: React.FC<Props> = (props) => {
  const { type, noToAll, onAnswer, currentAnswer } = props;
  const { selected, unselected, textField } = styles();
  const [answer, setAnswer] = React.useState<number | undefined>(currentAnswer);
  const answerQuestion = (value: typeof answer) => {
    setAnswer(value);

    if (value !== undefined && onAnswer) {
      onAnswer(type, value);
    }
  };

  React.useEffect(() => {
    setAnswer(currentAnswer);
  }, [currentAnswer]);

  let yesClass: string | undefined;
  let noClass: string | undefined;

  if (noToAll) {
    yesClass = unselected;
    noClass = selected;
  } else if (answer) {
    yesClass = selected;
    noClass = unselected;
  } else if (answer === 0) {
    yesClass = unselected;
    noClass = selected;
  }

  return (
    <>
      <b className={yesClass} onClick={() => answerQuestion(1)}>
        <FormattedMessage id="yes" />
        {type === 'diabetes' && (
          <>
            , <FormattedMessage id="bmi" />
            <input
              placeholder="XX"
              className={textField}
              size={2}
              value={answer && answer > 1 ? answer : ''}
              onChange={(event) =>
                answerQuestion(parseInt(event.target.value, 10) || 0)
              }
            />
          </>
        )}
      </b>
      <b className={noClass} onClick={() => answerQuestion(0)}>
        <FormattedMessage id="no" />
      </b>
    </>
  );
};
