import React from 'react';
import { useIntl } from 'react-intl';

type Question = {
  '@type': 'Question';
  name: string;
  acceptedAnswer: {
    '@type': 'Answer';
    text: string;
  };
};

export const FAQSchema: React.FC = React.memo(() => {
  const { messages, locale } = useIntl();
  const text = `${messages['faq.text'] || ''}`
    .split(/#+\s([^\n]+)\n([^#]+)/)
    .map((t) => t.trim())
    .filter(Boolean);
  const faq: Question[] = [];
  const json = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    name: messages['faq.heading'],
    headline: messages['faq.heading'],
    inLanguage: locale,
    mainEntity: faq,
  };

  for (let i = 0; i < text.length; i += 2) {
    const question = text[i];
    const answer = text[i + 1];

    if (question && answer) {
      faq.push({
        '@type': 'Question',
        name: question,
        acceptedAnswer: {
          '@type': 'Answer',
          text: answer,
        },
      });
    }
  }

  return (
    <script type="application/ld+json">
      {JSON.stringify(json, undefined, 2)}
    </script>
  );
});
