import Bugsnag from '@bugsnag/js';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Account } from '../../hooks/useAccount';
import { Order, OrderPayload } from '../../swagger';
import { api } from '../../utils/api';
import { AuthForm } from '../forms/AuthForm';
import { InfoDialog, InfoDialogProps } from './InfoDialog';

type Props = InfoDialogProps & {
  payload: OrderPayload;
  onSuccess: (order?: Order) => void;
  initPersonnummer?: string;
  isCheckup?: boolean;
};

export const InsuranceCompletionDialog: React.FC<Props> = (props) => {
  const { payload, onSuccess, ...rest } = props;
  const { account } = Account.useContainer();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');

  React.useEffect(() => {
    if (props.isOpen && account && !loading) {
      setLoading(true);
      setError('');
      api
        .orderControllerMultiCreate(payload)
        .then((order) => {
          onSuccess(order);
          window.sessionStorage.clear();
        })
        .catch(async (response: Response) => {
          try {
            const err = await response.json();
            Bugsnag.notify(err, (event) => {
              event.addMetadata('payload', payload);
              event.addMetadata('response', response);
            });
            setError(err?.message || response.statusText);
          } catch (e) {
            Bugsnag.notify(e, (event) => {
              event.addMetadata('payload', payload);
              event.addMetadata('response', response);
            });
            setError(response.statusText);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [props.isOpen, account, onSuccess, payload]);

  return (
    <InfoDialog
      {...rest}
      title={
        !props.isCheckup ? (
          <FormattedMessage id="insurance_create" />
        ) : (
          <FormattedMessage id="final_step" />
        )
      }
      maxWidth="sm"
    >
      {!account && <AuthForm initPersonnummer={props.initPersonnummer} />}
      {loading && (
        <Box display="flex" justifyContent="center">
          <CircularProgress
            variant="indeterminate"
            color="secondary"
            size={48}
          />
        </Box>
      )}
      {error}
    </InfoDialog>
  );
};
