import Box from '@material-ui/core/Box';
import * as React from 'react';
import { IntlProvider } from 'react-intl';
import { Redirect, RouteComponentProps, Switch } from 'react-router';
import { LinkButton } from '../components/forms/SubmitButton';
import { Logo } from '../components/Logo';
import { MetaRenderer } from '../components/MetaRenderer';
import { SimpleRoute } from '../components/PageRoute';
import { Account } from '../hooks/useAccount';
import { useLangRedirect } from '../hooks/useLangRedirect';
import { Meta } from '../hooks/useMeta';
import { useRouter } from '../hooks/useRouter';
import { Language, Settings } from '../hooks/useSettings';
import { locales } from '../locales';
import { Layout } from './Layout';
import {
  SuspenseAgreement,
  SuspenseFaktablad,
  SuspenseLetter,
  SuspensePurchaseInfo,
} from './lazy';
import { SuspenseBroker } from './lazy/Broker';
import { SuspenseBrokerAgreement } from './lazy/BrokerAgreement';
import { SuspenseBrokerChild } from './lazy/BrokerChild';
import { SuspenseBrokerCoinsured } from './lazy/BrokerCoinsured';
import { SuspenseEmployee } from './lazy/Employee';
import { SuspenseGroup } from './lazy/Group';
import { SuspenseTermsAndConditions } from './lazy/TermsAndConditions';

const IntercomCommunicator = React.memo(() => {
  const { account } = Account.useContainer();

  React.useEffect(() => {
    try {
      Intercom('update', {
        email: account && account.sensitive && account.sensitive.email,
        user_id: account && account.id,
        name: account && account.sensitive && account.sensitive.fullName,
      });
    } catch (e) {
      console.error(e.toString());
    }
  }, [account]);

  return null;
});

const TokenLoader = React.memo(() => {
  const { reloadAccount } = Account.useContainer();
  const { update } = Settings.useContainer();
  const { history } = useRouter();

  React.useEffect(() => {
    const params = new URLSearchParams(history.location.search);
    const token = params.get('token');

    if (token) {
      update({ token, auth: '', persist: false });
      void reloadAccount();
    }
  }, []);

  return null;
});

const DoubleSlashFixer = React.memo(() => {
  const { location, history } = useRouter();

  React.useEffect(() => {
    if (location.pathname.includes('//')) {
      const pathname = location.pathname.replace(/\/\//g, '/');

      history.replace({
        ...location,
        pathname,
      });
    }
  }, [location.pathname]);

  return null;
});

const RedirectFromRoot = React.memo(() => {
  const currentURL = new URL(window.location.href);
  const { settings } = Settings.useContainer();
  const token = currentURL.searchParams.get('token');
  const root = `/${settings.language}`;
  const [opacity, setOpacity] = React.useState(0);
  const redirect = useLangRedirect();

  React.useEffect(() => {
    if (!redirect && navigator.userAgent !== 'ReactSnap') {
      const timer = setTimeout(() => setOpacity(1), 500);
      return () => clearTimeout(timer);
    }
  }, [redirect]);

  return (
    <>
      <Box
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          transition: 'all 1s',
          opacity,
        }}
      >
        <Logo />
        <Box style={{ margin: '16px' }}>
          {Object.entries(Language).map(([key, lang]) => (
            <LinkButton label={key} to={`/${lang}`} key={key} />
          ))}
        </Box>
      </Box>
      {navigator.userAgent !== 'ReactSnap' && redirect && (
        <Redirect
          to={{
            pathname: token ? `${root}/mina` : root,
            search: window.location.search,
          }}
        />
      )}
    </>
  );
});

export const Root: React.FC = React.memo(() => {
  const { settings, update } = Settings.useContainer();
  const messages = locales[settings.language];

  const render = React.useCallback(
    (props: RouteComponentProps<{ lang: string }>) => {
      const lang = props.match.params.lang;
      const isValidLang = Object.values(Language).includes(lang as Language);

      if (settings.language !== lang && isValidLang) {
        update({
          language: lang as Language,
        });
      }

      return <Layout lang={lang} />;
    },
    [settings.language],
  );

  return (
    <IntlProvider locale={settings.language} messages={messages}>
      {/*<InContextEditor messages={messages} />*/}
      <Meta.Provider>
        <Switch>
          <SimpleRoute
            exact
            path="/agreement-:id"
            component={SuspenseAgreement}
          />
          <SimpleRoute exact path="/letter-:id" component={SuspenseLetter} />
          <SimpleRoute
            exact
            path="/broker-agreement-:id"
            component={SuspenseBrokerAgreement}
          />
          <SimpleRoute exact path="/faktablad" component={SuspenseFaktablad} />
          <SimpleRoute
            exact
            path="/purchaseinfo"
            component={SuspensePurchaseInfo}
          />
          <SimpleRoute
            exact
            path="/termsandconditions"
            component={SuspenseTermsAndConditions}
          />
          <SimpleRoute exact path="/broker" component={SuspenseBroker} />
          <SimpleRoute exact path="/employee" component={SuspenseEmployee} />
          <SimpleRoute exact path="/group" component={SuspenseGroup} />
          <SimpleRoute
            exact
            path="/broker-coinsured"
            component={SuspenseBrokerCoinsured}
          />
          <SimpleRoute
            exact
            path="/broker-child"
            component={SuspenseBrokerChild}
          />
          <SimpleRoute path="/:lang" render={render} />
          <SimpleRoute component={RedirectFromRoot} />
        </Switch>
        <MetaRenderer />
        <IntercomCommunicator />
        <TokenLoader />
        <DoubleSlashFixer />
      </Meta.Provider>
    </IntlProvider>
  );
});
