import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grow from '@material-ui/core/Grow';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { useEffect } from 'react';
import { Form } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { Auth } from '../../hooks/useAuth';
import { useBankIdHint } from '../../hooks/useBankIdHint';
import { Settings } from '../../hooks/useSettings';
import { duration } from '../../styles';
import { AuthPayload, AuthRequest } from '../../swagger';
import { LinkButton, SubmitButton } from './SubmitButton';

interface Props {
  initPersonnummer?: string;
}

export const AuthForm: React.FC<Props> = () => {
  const { settings } = Settings.useContainer();
  const { status, startAuth, cancelAuth, resetAuth } = Auth.useContainer();
  const [showForm, toggleForm] = React.useState(!settings.auth);
  const [showStatus, toggleStatus] = React.useState(!!settings.auth);
  const hintMessage = useBankIdHint();

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (settings.auth) {
      toggleForm(false);
      timer = setTimeout(() => toggleStatus(true), duration);
    } else if (!settings.token) {
      toggleStatus(false);
      timer = setTimeout(() => toggleForm(true), duration);
    }

    return () => clearTimeout(timer);
  }, [settings.auth]);

  const initialValues: AuthPayload = {};
  const isOngoing = status === AuthRequest.StatusEnum.Pending;

  return (
    <Box
      flex={1}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
    >
      <Grow
        in={showForm}
        unmountOnExit={true}
        mountOnEnter={true}
        timeout={duration}
      >
        <Form initialValues={initialValues} onSubmit={startAuth}>
          {({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit}>
              <Box textAlign="center" p={1}>
                <SubmitButton
                  label="sign_in"
                  loading={submitting || !!settings.auth}
                />
              </Box>
            </form>
          )}
        </Form>
      </Grow>
      <Grow
        in={showStatus}
        unmountOnExit={true}
        mountOnEnter={true}
        timeout={duration}
      >
        <Box
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
          alignSelf={'stretch'}
        >
          <React.Fragment>
            {isOngoing && settings.auth?.qr && (
              <img src={settings.auth.qr} width={160} height={160} />
            )}
            {hintMessage && (
              <Box flex={1} textAlign={'center'} p={2} pb={1}>
                <Typography variant="h6">{hintMessage}</Typography>
              </Box>
            )}
            <Box p={1}>
              {status === AuthRequest.StatusEnum.Failed ? (
                <Button
                  variant={'outlined'}
                  color={'primary'}
                  size="medium"
                  onClick={resetAuth}
                >
                  <FormattedMessage id="try_again" />
                </Button>
              ) : (
                isOngoing && (
                  <Box
                    flex={1}
                    display="flex"
                    flexDirection="column"
                    style={{ gap: 16 }}
                  >
                    {isOngoing && settings.auth?.autoStartToken && (
                      <LinkButton
                        href={`https://app.bankid.com/?autostarttoken=${settings.auth.autoStartToken}&redirect=${window.location.href}`}
                        label="bankid_this_device"
                        variant={'outlined'}
                        size="medium"
                        color={'primary'}
                      ></LinkButton>
                    )}
                    <Button
                      variant={'outlined'}
                      color={'secondary'}
                      size="medium"
                      onClick={cancelAuth}
                    >
                      <FormattedMessage id="cancel" />
                    </Button>
                  </Box>
                )
              )}
            </Box>
          </React.Fragment>
        </Box>
      </Grow>
    </Box>
  );
};
