import Stepper from '@material-ui/core/Stepper';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import useBoolean from 'react-use/lib/useBoolean';
import { yellow } from '../styles';
import { PaymentTransaction } from '../swagger';
import { toDateTime } from '../utils/toDateTime';
import { InfoDialog } from './dialogs/InfoDialog';
import { Transaction } from './Transaction';

interface TransactionsProps {
  transactions: PaymentTransaction[];
  startFrom?: number;
  collapse?: boolean;
  reverse?: boolean;
  detailed?: boolean;
}

const styles = makeStyles({
  stepper: {
    padding: 0,
    background: 'transparent',

    '& .MuiStepIcon-active': {
      color: yellow,
    },
  },
});

export const Transactions: React.FC<TransactionsProps> = React.memo(
  ({
    transactions,
    startFrom = 0,
    collapse = false,
    reverse = false,
    detailed = false,
    children,
  }) => {
    let prevAmount = 0;
    const { stepper } = styles();
    const sortedTransactions = [...transactions].sort(
      (a, b) => toDateTime(a.paid).toMillis() - toDateTime(b.paid).toMillis(),
    );

    if (reverse) {
      sortedTransactions.reverse();
    }

    const visibleTransactions = sortedTransactions.splice(
      collapse ? sortedTransactions.length - 1 : 0,
    );
    const lastTransaction = visibleTransactions.length - 1;
    const [showHistory, toggleHistory] = useBoolean(false);

    return (
      <>
        <Stepper activeStep={0} orientation="vertical" className={stepper}>
          {visibleTransactions.map((transaction, i) => {
            const sameAmount = transaction.amount === prevAmount;
            const isLast = i === lastTransaction;

            prevAmount = transaction.amount;

            if (!detailed && sameAmount && !isLast) {
              return undefined;
            }

            return (
              <Transaction
                detailed={detailed}
                onClick={collapse && toggleHistory}
                transaction={transaction}
                index={i + startFrom}
                last={isLast}
                key={`transaction_${i}`}
              >
                {children}
              </Transaction>
            );
          })}
        </Stepper>
        {collapse && (
          <InfoDialog
            title={
              <FormattedMessage
                id={reverse ? 'upcoming_payments' : 'previous_payments'}
              />
            }
            isOpen={showHistory}
            onClose={toggleHistory}
          >
            <Transactions
              transactions={transactions}
              reverse={!reverse}
              detailed
            />
          </InfoDialog>
        )}
      </>
    );
  },
);
