import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Account } from '../../hooks/useAccount';
import { Broker, Pricing } from '../../swagger';

interface Props {
  selectedPricingId?: string;
  selectedBrokerId?: string;
  pricings?: Pricing[];
  brokers?: Broker[];
  onPricingChange(pricingId: string): void;
  onBrokerChange(pricingId: string | undefined): void;
}

export const InsurancePricingSelect: React.FC<Props> = ({
  selectedPricingId,
  selectedBrokerId,
  pricings = [],
  brokers = [],
  onPricingChange,
  onBrokerChange,
}) => {
  const { admin } = Account.useContainer();

  return (
    <Box display="flex" style={{ flex: '100%' }}>
      {!!pricings.length && (
        <Box pb={2} style={{ flex: '1' }}>
          <Typography
            variant="h6"
            color="textSecondary"
            style={{ margin: '8px 0' }}
          >
            <FormattedMessage id="admin.pricing.title" />
          </Typography>
          <Select
            native
            margin="dense"
            value={selectedPricingId}
            variant="outlined"
            onChange={(event) => onPricingChange(`${event.target.value}`)}
          >
            {pricings.map((pr) => (
              <option key={pr.id} value={pr.id}>
                {pr.id}
              </option>
            ))}
          </Select>
        </Box>
      )}
      {!!brokers?.length && (
        <Box pb={2} style={{ flex: '1' }}>
          <Typography
            variant="h6"
            color="textSecondary"
            style={{ margin: '8px 0' }}
          >
            <FormattedMessage id="broker.code" />
          </Typography>
          <Select
            native
            margin="dense"
            value={selectedBrokerId}
            variant="outlined"
            onChange={(event) => {
              onBrokerChange((event.target.value as string) || undefined);
            }}
          >
            {admin && <option key="undefined" value={undefined} />}
            {brokers.map((br) => (
              <option key={br.id} value={br.id}>
                {br.code} {br.name}
              </option>
            ))}
          </Select>
        </Box>
      )}
    </Box>
  );
};
