import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CloseIcon from '@material-ui/icons/Close';
import * as React from 'react';
import { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useRouter } from '../hooks/useRouter';
import { duration, easing } from '../styles';
import { theme } from '../styles/theme';
import { SheetProps } from '../types';
import { parent } from '../utils/url';

const useStyles = makeStyles((th: Theme) =>
  createStyles({
    root: {
      left: 0,
      top: 0,
      maxHeight: '100%',
      width: '100%',

      '& .MuiTypography-h5': {
        marginBottom: 0,
      },
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100%',
    },
    suspend: {
      pointerEvents: 'none',
      filter: 'saturate(0.4)',
    },
    scroll: {
      padding: th.spacing(4, 2),

      [th.breakpoints.down('xs')]: {
        padding: th.spacing(1, 0.5),
      },
    },
    paper: {
      transform: 'translateY(0px)',
      willChange: 'transform, filter',
      transition: `all ${duration}ms ${easing}`,
      transformOrigin: 'center top',
    },
  }),
);

const getLevels = (path?: string) =>
  !path ? 0 : path.split('/').filter((p) => !!p).length;

const isBlurrable = (element: Element): element is HTMLInputElement =>
  'blur' in element;

export const Sheet: React.FC<SheetProps> = (props) => {
  const {
    children,
    title,
    button,
    active,
    maxWidth = 'sm',
    noMargins = false,
  } = props;
  const { location } = useRouter();
  const upToXS = useMediaQuery(theme.breakpoints.up('sm'));
  const [level, setLevel] = React.useState<number | undefined>(undefined);
  const isMatched = props.match === location.pathname;
  const isActive = active || isMatched;
  const styles = useStyles();
  const back = React.useMemo(() => parent(props.match), [props.match]);

  useEffect(() => {
    if (
      !isActive &&
      document.activeElement &&
      isBlurrable(document.activeElement)
    ) {
      document.activeElement.blur();
    }

    setTimeout(() => {
      if (active) {
        setLevel(0);
      } else {
        setLevel(getLevels(location.pathname) - getLevels(props.match));
      }
    });

    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, duration);
  }, [active, location.pathname, props.match]);

  const renderButton = () => {
    if (button === false) {
      return undefined;
    }

    return (
      <Box style={{ marginBottom: '16px' }}>
        {button || (
          <IconButton
            aria-label="Close"
            color="primary"
            to={back}
            component={RouterLink}
          >
            <CloseIcon />
          </IconButton>
        )}
      </Box>
    );
  };

  const transparent = level === undefined || level < 0;
  const shift = level === undefined ? 1 : level;
  const opacity = transparent ? 0 : 1 - Math.max(level || 0, 0) * 0.2;
  const contrast = isActive ? '1' : '0.2';
  const brightness = isActive ? '1' : '1.6';
  const padding = props.p === undefined ? 3 : props.p;
  const translateY = theme.spacing(-3 * shift);
  const scale = 1 - shift * 0.05;
  const paddingY =
    theme.spacing(Math.max(0, !upToXS ? padding - 1 : padding)) + 'px';
  const paddingX =
    theme.spacing(Math.max(0, !upToXS ? padding - 1 : padding)) + 'px';

  return (
    <Box
      className={styles.root}
      style={{
        position: !level ? 'relative' : 'absolute',
        overflow: !level ? 'initial' : 'hidden',
      }}
    >
      <Container
        maxWidth={maxWidth}
        className={`${styles.container} ${styles.scroll} ${
          isMatched ? 'matched' : 'non-matched'
        }`}
      >
        <Paper
          className={`${styles.paper} ${!isActive && styles.suspend}`}
          elevation={!level ? 2 : 0}
          style={{
            transform: `translateY(${translateY}px) scale(${scale})`,
            filter: `contrast(${contrast}) brightness(${brightness}) opacity(${opacity})`,
          }}
        >
          <LinearProgress
            variant={
              props.loading && typeof props.loading !== 'number'
                ? 'query'
                : 'determinate'
            }
            color="secondary"
            value={
              typeof props.loading === 'number'
                ? Math.round(props.loading * 100)
                : 100
            }
          />
          <Box
            style={{
              paddingTop: paddingY,
              paddingBottom: noMargins ? 0 : paddingY,
              paddingLeft: paddingX,
              paddingRight: paddingX,
            }}
          >
            <Box display={'flex'} alignItems={'center'}>
              <Box flex={1}>
                {title && <Typography variant={'h3'}>{title}</Typography>}
              </Box>
              {renderButton()}
            </Box>
            {!noMargins && children}
          </Box>
          {noMargins && children}
        </Paper>
        {props.footer}
      </Container>
    </Box>
  );
};
