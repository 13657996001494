import { TextField } from 'final-form-material-ui';
import React from 'react';
import { useIntl } from 'react-intl';
import { validateEmail } from '../../utils/validateEmail';
import { validatePersonalNumber } from '../../utils/validatePersonalNumber';
import { PaymentMethodField } from './PaymentMethodField';

export const PaymentMethodAccount: React.FC<{
  isBroker?: boolean;
}> = React.memo(({ isBroker }) => {
  const intl = useIntl();
  const validatePN = React.useCallback((pn = '') => {
    if (!validatePersonalNumber(pn)) {
      return intl.messages.invalid_personal_number;
    }
  }, []);
  const validateE = React.useCallback(
    (email = '') => {
      if (isBroker && !email) return;
      return validateEmail(email) ? undefined : intl.messages.invalid_email;
    },
    [isBroker],
  );
  const validatePhone = React.useCallback(
    (phone = '') => {
      if (isBroker && !phone) return;
      return `${phone}`.trim().length > 6
        ? undefined
        : intl.messages.invalid_phone;
    },
    [isBroker],
  );

  return (
    <>
      <PaymentMethodField
        autoFocus
        type="tel"
        name="personnummer"
        component={TextField}
        validate={validatePN}
        label={`${intl.messages.personal_number} / ${intl.messages.company_number}`}
      />
      <PaymentMethodField
        type="email"
        name="email"
        component={TextField}
        validate={validateE}
        label={intl.messages.email_for_receipts}
      />
      <PaymentMethodField
        type="tel"
        name="phone"
        component={TextField}
        validate={validatePhone}
        label={intl.messages.phone}
      />
    </>
  );
});
