import Box from '@material-ui/core/Box';
import React from 'react';
import { Field } from 'react-final-form';

export const PaymentMethodField: React.FC<
  React.ComponentProps<typeof Field>
> = (props) => {
  return (
    <Box pb={3}>
      <Field {...props} variant="outlined" />
    </Box>
  );
};
