import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { alpha } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';
import { Switch } from 'react-router';
import { DrawerMenu } from '../components/DrawerMenu';
import { Footer } from '../components/Footer';
import { Header } from '../components/Header';
import { PageRoute, SimpleRoute } from '../components/PageRoute';
import { CTA } from '../hooks/useCTA';
import { Login } from '../hooks/useLogin';
import { Quiz as QuizContainer } from '../hooks/useQuiz';
import { Language } from '../hooks/useSettings';
import { gradient } from '../styles';
import { theme } from '../styles/theme';
import { NotFound } from './404';
import { Checkup } from './Checkup';
import { Coinsurance } from './Coinsurance';
import { Cookies } from './Cookies';
import { FAQ } from './FAQ';
import { Insurance } from './Insurance';
import { Landing } from './Landing';
import { SuspenseReferral } from './lazy';
import { SuspenseAbout } from './lazy/About';
import { SuspenseBrokerSignup } from './lazy/BrokerSignup';
import { SuspenseCheckupOrder } from './lazy/CheckupOrder';
import { SuspenseContacts } from './lazy/Contacts';
import { Mina } from './Mina';

const styles = makeStyles({
  container: {
    width: '100%',
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    color: alpha(theme.palette.text.secondary, 0.65),
  },
  content: {
    flex: '1',
    background: gradient,
  },
});

export const Layout: React.FC<{ lang: string }> = React.memo(({ lang }) => {
  const { container, content } = styles();
  const isValidLang = Object.values(Language).includes(lang as Language);

  return (
    <Box className={container}>
      <Login.Provider>
        <QuizContainer.Provider>
          <CTA.Provider>
            <Header />

            <Box className={`${content} gradient-container`}>
              {isValidLang && (
                <Switch>
                  <SimpleRoute
                    exact
                    path={`/${lang}/referral`}
                    component={SuspenseReferral}
                  />
                  <SimpleRoute
                    exact
                    path={`/${lang}/insurance/:offerId?`}
                    component={Insurance}
                  />
                  <SimpleRoute
                    exact
                    path={`/${lang}/quiz/:id`}
                    component={Coinsurance}
                  />
                  <SimpleRoute
                    exact
                    path={`/${lang}/about`}
                    component={SuspenseAbout}
                  />
                  <SimpleRoute exact path={`/${lang}/faq`} component={FAQ} />
                  <SimpleRoute
                    exact
                    path={`/${lang}/contacts`}
                    component={SuspenseContacts}
                  />
                  <SimpleRoute
                    exact
                    path={`/${lang}/cookies`}
                    component={Cookies}
                  />
                  <SimpleRoute
                    exact
                    path={`/${lang}/broker/:token?`}
                    component={SuspenseBrokerSignup}
                  />
                  <PageRoute path={`/${lang}/mina`} component={Mina} />
                  <SimpleRoute
                    exact
                    path={`/${lang}/checkup/order`}
                    component={SuspenseCheckupOrder}
                  />
                  <SimpleRoute
                    exact
                    path={`/${lang}/checkup`}
                    component={Checkup}
                  />
                  <SimpleRoute exact path={`/${lang}`} component={Landing} />
                  <SimpleRoute component={NotFound} />
                </Switch>
              )}
              {!isValidLang && (
                <Switch>
                  <SimpleRoute component={NotFound} />
                </Switch>
              )}
            </Box>

            <Box
              style={{ background: gradient }}
              className="gradient-container"
            >
              <Container>
                <Footer />
              </Container>
            </Box>

            <DrawerMenu />
          </CTA.Provider>
        </QuizContainer.Provider>
      </Login.Provider>
    </Box>
  );
});
