import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { DateTime } from 'luxon';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Lato } from '../styles';
import { Order } from '../swagger';
import { InfoDialog } from './dialogs/InfoDialog';
import { PaymentIntervals } from './forms/PaymentIntervals';
import { Markdown } from './Markdown';

const styles = makeStyles({
  date: {
    width: '100%',

    '& .MuiTextField-root': {
      width: '100%',
    },

    '& .MuiInputBase-input': {
      textTransform: 'uppercase',
      fontSize: '16px',
      fontFamily: Lato,
      fontWeight: 'bold',
    },
  },
});

type Props = {
  order?: Order;
  setPaymentPeriod: (period: number) => void;
  startDate: string;
  setStartDate: (date: string) => void;
  isBroker?: boolean;
  isAdmin?: boolean;
  isHealthCheckup?: boolean;
};

export const InsurancePreferences: React.FC<Props> = React.memo((props) => {
  const {
    order,
    setPaymentPeriod,
    startDate,
    setStartDate,
    isAdmin,
    isBroker,
    isHealthCheckup,
  } = props;
  const { date } = styles();
  const [loading] = React.useState(false);
  const [error, setError] = React.useState('');
  const minDate = DateTime.utc()
    .plus(isAdmin ? { month: -1 } : { day: 1 })
    .toISODate();
  const maxDate = DateTime.fromISO(minDate, { zone: 'utc' })
    .plus(isAdmin ? { month: 3 } : { month: 1 })
    .toISODate();

  React.useEffect(() => {
    if (startDate < minDate) {
      setStartDate(minDate);
    } else if (startDate > maxDate) {
      setStartDate(maxDate);
    }
  }, [startDate]);

  return (
    <>
      <Box pr={2}>
        <Typography
          variant="h6"
          color="textSecondary"
          style={{ margin: '8px 0' }}
        >
          <FormattedMessage id="payment_interval" />
        </Typography>
        <PaymentIntervals
          disabled={!isBroker && !order?.type}
          loading={loading}
          paymentPeriod={order?.paymentPeriod}
          onSelect={setPaymentPeriod}
          isHealthCheckup={isHealthCheckup}
        />
      </Box>
      <Box className={date}>
        <Typography
          variant="h6"
          color="textSecondary"
          style={{ margin: '8px 0' }}
        >
          <FormattedMessage id="start_date" />
        </Typography>
        <TextField
          disabled={!isBroker && !order?.type}
          name="startDate"
          type="date"
          variant="outlined"
          value={startDate}
          onChange={({ target: { value } }) => setStartDate(value)}
          size="small"
          inputProps={{
            min: minDate,
            max: maxDate,
          }}
        />
      </Box>
      <InfoDialog isOpen={!!error} onClose={() => setError('')}>
        <Markdown
          children="payment_method_failure"
          variant="body1"
          color="textPrimary"
          style={{ textAlign: 'center' }}
        />
      </InfoDialog>
    </>
  );
});
