import * as React from 'react';

const LazyBroker = React.lazy<typeof import('../pdf/Broker').Broker>(() =>
  import('../pdf/Broker').then((module) => ({ default: module.Broker })),
);
export const SuspenseBroker: typeof import('../pdf/Broker').Broker = (
  props,
) => (
  <React.Suspense fallback={''}>
    <LazyBroker {...props} />
  </React.Suspense>
);

SuspenseBroker.page = 'broker';
