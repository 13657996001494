import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { useRoutes } from '../hooks/useRoutes';
import { theme } from '../styles/theme';
import { Illustration } from './Illustration';
import { Markdown } from './Markdown';

const styles = makeStyles({
  insuranceContainer: {
    position: 'relative',
    marginBottom: '-32px',

    [theme.breakpoints.down('xs')]: {
      marginBottom: '-8px',
    },
  },
  header: {
    padding: '48px 0 48px',
    color: 'white',
    textAlign: 'center',
    '&.is-broker-header': {
      padding: '32px 0 16px',
    },

    '& .MuiList-root': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    '& .MuiListItem-root': {
      width: 'auto',
    },
  },
  insuranceHeader: {
    [theme.breakpoints.down('sm')]: {
      '&:not(.is-broker-header)': {
        paddingBottom: '112px',
      },
    },
  },
  link: {
    color: 'inherit',
    textDecoration: 'none',
  },
});

type Props = {
  isBroker?: boolean;
  isAdmin?: boolean;
  isHealthCheckup?: boolean;
};

export const InsuranceHeader: React.FC<Props> = (props) => {
  const { isBroker, isAdmin, isHealthCheckup } = props;
  const { header, insuranceContainer, insuranceHeader, link } = styles();
  const { root } = useRoutes();
  const prefix = isHealthCheckup ? 'checkup' : 'insurance';
  const getTitle = () => {
    if (isAdmin) {
      return `${prefix}_create`;
    }
    if (isBroker) {
      return `broker.${prefix}.title`;
    }
    return `${prefix}.title`;
  };

  const classNames = [header];
  if (!isHealthCheckup) {
    classNames.push(insuranceHeader);
  }
  if (isBroker) {
    classNames.push('is-broker-header');
  }

  return (
    <Container className={insuranceContainer} maxWidth="md">
      {!isBroker && !isHealthCheckup && <Illustration overlap="0vh" />}
      <Box className={classNames.join(' ')}>
        <Typography variant="h3">
          <FormattedMessage id={getTitle()} />
        </Typography>
        {!isBroker &&
          (isHealthCheckup ? (
            <Markdown>checkup.usp</Markdown>
          ) : (
            <Link to={root} className={link}>
              <Markdown>insurance.usp</Markdown>
            </Link>
          ))}
      </Box>
    </Container>
  );
};
