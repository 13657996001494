import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Redirect } from 'react-router';
import SwipeableViews from 'react-swipeable-views';
import { OrderActions } from '../components/OrderActions';
import { OrderCheckupInfo } from '../components/OrderCheckupInfo';
import { OrderPayment } from '../components/OrderPayment';
import { OrderPersons } from '../components/OrderPersons';
import { Sheet } from '../components/Sheet';
import { Account } from '../hooks/useAccount';
import { useRoutes } from '../hooks/useRoutes';
import { Page } from '../types';
import { isOrderHealthCheckup } from '../utils/isHealthCheckup';

export const Order = (({ match, id }) => {
  const { account } = Account.useContainer();
  const { mina } = useRoutes();
  const order =
    account && account.orders && account.orders.find((o) => o.id === id);
  const [tab, setTab] = React.useState(0);
  const ref = React.useRef<{ updateHeight: () => void }>(null);

  React.useLayoutEffect(() => {
    if (ref.current && ref.current.updateHeight) {
      ref.current.updateHeight();
    }
  });

  React.useEffect(() => {
    const handleUpdateHeight = () => {
      if (ref.current && ref.current.updateHeight) {
        ref.current.updateHeight();
      }
    };

    window.addEventListener('updateSwipeableViews', handleUpdateHeight, {
      passive: true,
    });

    return () => {
      window.removeEventListener('updateSwipeableViews', handleUpdateHeight);
    };
  }, [ref.current]);

  if (!order) {
    return <Redirect to={mina} push={false} />;
  }

  const isValid = !!order.type || order.transactions.some((tr) => tr.id);
  const isHealthCheckup = isOrderHealthCheckup(order);

  return (
    <Sheet
      match={match}
      title={
        <FormattedMessage
          id={isHealthCheckup ? 'checkup.heading' : 'order.title'}
        />
      }
    >
      <>
        {order.type && (
          <Box>
            <OrderActions order={order} />
          </Box>
        )}
        <AppBar elevation={0} position="static" color="inherit">
          <Tabs
            value={tab}
            onChange={(_, value) => setTab(value)}
            indicatorColor="primary"
            textColor="primary"
            variant="standard"
          >
            <Tab label={<FormattedMessage id="persons" />} />
            {isValid && (
              <Tab label={<FormattedMessage id="payment_details" />} />
            )}
          </Tabs>
        </AppBar>
        <SwipeableViews
          ref={ref as any}
          index={tab}
          onChangeIndex={(index) => setTab(index)}
          animateHeight
          disabled
          axis="x"
        >
          <Box py={2}>
            <OrderPersons order={order} />
            {isOrderHealthCheckup(order) && (
              <OrderCheckupInfo onLoad={() => ref.current?.updateHeight()} />
            )}
          </Box>
          {isValid && (
            <Box py={2}>
              <OrderPayment order={order} />
            </Box>
          )}
        </SwipeableViews>
      </>
    </Sheet>
  );
}) as Page<{ id: string }>;

Order.page = 'order';
