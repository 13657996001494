import { useIntl } from 'react-intl';
import { AuthRequest } from '../swagger';
import { Auth } from './useAuth';

export const useBankIdHint = () => {
  const intl = useIntl();
  const { status, hint } = Auth.useContainer();

  return (
    intl.messages[`bankid_${hint}`] ||
    (status === AuthRequest.StatusEnum.Failed &&
      intl.messages.bankid_expiredTransaction)
  );
};
