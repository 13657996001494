import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import { CheckupProductSchema } from '../components/CheckupProductSchema';
import { CheckupHero } from '../components/Hero';
import { LabsMap, labsMapStyles } from '../components/LabsMap';
import { Markdown } from '../components/Markdown';
import { USP } from '../components/USP';
import { googleMapsURL } from '../config';
import { Page } from '../types';
import { BoxCenteredHeading, BoxContainer } from './Landing';

export const Checkup: Page = () => {
  const { landingContainer, loading, map } = labsMapStyles();
  const isPrerender = navigator.userAgent === 'ReactSnap';

  return (
    <BoxContainer>
      <CheckupHero />
      <USP
        dark
        primary={<Markdown>checkup.why</Markdown>}
        secondary={<Markdown>checkup.why_cta</Markdown>}
        secondPrimary={<Markdown>checkup.usp1</Markdown>}
        secondSecondary={<Markdown>checkup.usp1_cta</Markdown>}
      />

      <USP
        id="usps"
        reverse
        primary={<Markdown>checkup.usp2_cta</Markdown>}
        secondary={<Markdown>checkup.usp2</Markdown>}
      />

      <USP
        dark
        primary={
          <>
            <BoxCenteredHeading>
              <Markdown>checkup.how</Markdown>
            </BoxCenteredHeading>
            <Grid container spacing={2}>
              <Grid item sm={6} md={3}>
                <Markdown>checkup.how1</Markdown>
              </Grid>
              <Grid item sm={6} md={3}>
                <Markdown>checkup.how2</Markdown>
              </Grid>
              <Grid item sm={6} md={3}>
                <Markdown>checkup.how3</Markdown>
              </Grid>
              <Grid item sm={6} md={3}>
                <Markdown>checkup.how4</Markdown>
              </Grid>
            </Grid>
          </>
        }
      />

      {!isPrerender && (
        <Container>
          <LabsMap
            googleMapURL={googleMapsURL}
            containerElement={<div className={landingContainer} />}
            loadingElement={<div className={loading} />}
            mapElement={<div className={map} />}
          />
        </Container>
      )}

      <Box pb={4} />

      <CheckupProductSchema />
    </BoxContainer>
  );
};

Checkup.page = 'checkup';
