import * as React from 'react';

const LazyCheckupOrder = React.lazy<
  typeof import('../CheckupOrder').CheckupOrder
>(() =>
  import('../CheckupOrder').then((module) => ({
    default: module.CheckupOrder,
  })),
);
export const SuspenseCheckupOrder: typeof import('../CheckupOrder').CheckupOrder =
  (props) => (
    <React.Suspense fallback={''}>
      <LazyCheckupOrder {...props} />
    </React.Suspense>
  );

SuspenseCheckupOrder.page = 'checkup-order';
