import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { currencyFormat } from '../styles';
import { theme } from '../styles/theme';
import { Order, ProductPricing } from '../swagger';

export interface PriceGroups {
  // 18-25, 26-35, any
  [group: string]: number;
}

interface PricingTableProps {
  pricing: ProductPricing;
  checkboxes?: boolean;
  withMonth?: boolean;
}

const sortedTypes = [
  Order.TypeEnum.Single,
  Order.TypeEnum.Couple,
  Order.TypeEnum.SingleChild,
  Order.TypeEnum.Family,
];

export interface PricingGroupsProps {
  priceGroups: PriceGroups;
  price?: number;
  checkboxes?: boolean;
  withMonth?: boolean;
  bold?: boolean;
}

export const PricingGroups: React.FC<PricingGroupsProps> = (props) => {
  const {
    priceGroups,
    price = 0,
    checkboxes,
    withMonth = false,
    bold = false,
  } = props;
  const groups = Object.keys(priceGroups).filter(
    (group) => priceGroups[group] >= price,
  );

  return (
    <Table>
      <TableBody>
        {groups.map((group) => {
          const formattedPrice = (
            <>
              <FormattedNumber
                value={priceGroups[group] as number}
                {...currencyFormat}
              />
              {withMonth && ' / månad'}
            </>
          );

          return (
            <TableRow key={`priceTable-${group}`}>
              <TableCell key="price" style={{ whiteSpace: 'nowrap' }}>
                {checkboxes ? (
                  <FormControlLabel
                    labelPlacement="end"
                    label={
                      bold ? <strong>{formattedPrice}</strong> : formattedPrice
                    }
                    control={
                      <Checkbox
                        name="interval"
                        color="primary"
                        style={{ paddingTop: 0 }}
                      />
                    }
                  />
                ) : (
                  formattedPrice
                )}
              </TableCell>
              {group !== 'any' && (
                <TableCell key="group" style={{ whiteSpace: 'nowrap' }}>
                  {group}
                  {group === '65-85' && (
                    <i
                      style={{
                        display: 'block',
                        fontStyle: 'normal',
                        color: theme.palette.text.secondary,
                        whiteSpace: 'normal',
                        lineHeight: '120%',
                      }}
                    >
                      <FormattedMessage id="customers_only" />
                    </i>
                  )}
                </TableCell>
              )}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export const PricingTable: React.FC<PricingTableProps> = ({
  pricing,
  checkboxes,
  withMonth,
}) => {
  return (
    <>
      {sortedTypes.map((orderType) => {
        const priceGroups = pricing[orderType];

        if (!priceGroups) {
          return <></>;
        }

        return (
          <Box style={{ breakInside: 'avoid' }} key={`priceTable-${orderType}`}>
            <Typography variant="h5" color="secondary">
              <FormattedMessage id={`pricing.${orderType}`} />
            </Typography>
            <PricingGroups
              withMonth={withMonth}
              priceGroups={priceGroups}
              checkboxes={checkboxes}
            />
          </Box>
        );
      })}
    </>
  );
};
