import AppBar from '@material-ui/core/AppBar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Redirect } from 'react-router';
import { Document } from '../components/Document';
import { Sheet } from '../components/Sheet';
import { Account } from '../hooks/useAccount';
import faktablad from '../public/faktablad2023.pdf';
import terms from '../public/villkor2023.pdf';
import { theme } from '../styles/theme';
import { Product } from '../swagger';
import { Page } from '../types';
import { parent } from '../utils/url';

const styles = makeStyles({
  tabs: {
    '& .MuiTab-root': {
      ...theme.typography.h5,
      letterSpacing: 0,
      textTransform: 'none',
    },
    '& .MuiTabs-flexContainer': {
      justifyContent: 'flex-start',
    },
  },
});

type Docs = 'letter' | 'agreement' | 'terms' | 'faktablad';

export const Documents: Page<{ product: string }> = ({
  match,
  product: productId,
}) => {
  const { account } = Account.useContainer();
  const { tabs } = styles();
  const product = [
    ...(account?.products || []),
    ...(account?.orders || []).reduce(
      (all, o) => [...all, ...(o.products || [])],
      [] as Product[],
    ),
  ].find((p) => p.id === productId);
  const order =
    account?.orders?.find((o) => o.products.some((p) => p.id === productId)) ||
    product?.order;
  const payerId = order?.payer?.id;
  const isInsured = product?.account?.id === account?.id;
  const [tab, setTab] = React.useState<Docs>(
    payerId ? 'letter' : isInsured ? 'agreement' : 'faktablad',
  );

  if (!account || !product) {
    return <Redirect to={parent(match)} />;
  }

  const accountId =
    (product && product.account && product.account.id) || account.id;

  const documents: Record<Docs, string> = {
    letter: `/document/${payerId}/letter-${encodeURIComponent(productId)}.pdf`,
    agreement: `/document/${accountId}/${encodeURIComponent(productId)}.pdf`,
    terms,
    faktablad,
  };

  return (
    <Sheet
      noMargins
      match={match}
      title={
        <AppBar elevation={0} position="static" color="inherit">
          <Tabs
            className={tabs}
            value={tab}
            onChange={(_, value) => setTab(value)}
            indicatorColor="primary"
            textColor="primary"
            variant="standard"
          >
            {payerId && (
              <Tab label={<FormattedMessage id="letter" />} value="letter" />
            )}
            {isInsured && (
              <Tab
                label={<FormattedMessage id="agreement" />}
                value="agreement"
              />
            )}
            <Tab
              label={<FormattedMessage id="faktablad" />}
              value="faktablad"
            />
            <Tab label={<FormattedMessage id="terms" />} value="terms" />
          </Tabs>
        </AppBar>
      }
      maxWidth="md"
    >
      <Document path={documents[tab]} fullWidth={false} />
    </Sheet>
  );
};

Documents.page = 'docs';
