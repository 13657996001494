import Box from '@material-ui/core/Box';
import * as React from 'react';
import { ProductPricing } from '../swagger';
import { Details } from './dialogs/Details';
import { PricingTable } from './PricingTable';

export const PricingDetails: React.FC<{ pricing: ProductPricing }> = ({
  children,
  pricing,
}) => {
  return (
    <Details
      details={
        <Box style={{ columns: 2, columnGap: '32px' }} p={3} pb={0}>
          <PricingTable pricing={pricing} />
        </Box>
      }
    >
      {children}
    </Details>
  );
};
