import Bugsnag from '@bugsnag/js';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert/Alert';
import * as React from 'react';
import { Form, FormRenderProps, useField } from 'react-final-form';
import { Account } from '../../hooks/useAccount';
import { PaymentMethods } from '../../hooks/usePaymentMethods';
import {
  ConnectPaymentResponse,
  InlineResponse400,
  PaymentPayload,
  Person,
} from '../../swagger';
import { api } from '../../utils/api';
import { Markdown } from '../Markdown';
import { PaymentAutoManual } from './PaymentAutoManual';
import { PaymentMethodAccount } from './PaymentMethodAccount';
import {
  BankProps,
  PaymentMethodBankAccount,
} from './PaymentMethodBankAccount';
import { PaymentMethodCompany } from './PaymentMethodCompany';
import { SubmitButton } from './SubmitButton';

type Props = {
  value?: PaymentPayload;
  onComplete: (response?: ConnectPaymentResponse) => void;
  onSubmit?: (payload: PaymentPayload) => void;
  isBroker?: boolean;
  mainPerson?: Person;
};

type FormBodyProps = BankProps & {
  handleSubmit: () => void;
  submitting: boolean;
  invalid: boolean;
  isBroker?: boolean;
};

const FormBody: React.FC<FormBodyProps> = React.memo((props) => {
  const { bank, handleSubmit, change, submitting, invalid, isBroker } = props;
  const { error } = PaymentMethods.useContainer();
  const {
    input: { value: isManual },
  } = useField<boolean | null>('manual', {
    defaultValue: null,
    allowNull: true,
  });

  return (
    <form
      onSubmit={handleSubmit}
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <PaymentMethodAccount isBroker={isBroker} />
      <PaymentMethodCompany isBroker={isBroker} />
      <PaymentAutoManual isBroker={isBroker} />
      {isManual === false && (
        <PaymentMethodBankAccount
          bank={bank}
          change={change}
          isBroker={isBroker}
        />
      )}

      {error && (
        <Alert severity="error">
          <Box>
            <Markdown children="payment_method_failure" variant={false} />{' '}
            {error}
          </Box>
        </Alert>
      )}
      <Box pb={3}>
        <SubmitButton
          type="submit"
          disabled={invalid}
          loading={submitting}
          label={
            isBroker
              ? 'add_payer'
              : isManual === false
              ? 'connect_bank'
              : 'add_payment'
          }
        />
      </Box>
    </form>
  );
});

const isResponse = (e: any): e is Response => 'status' in e;

export const PaymentMethodForm: React.FC<Props> = (props) => {
  const { value, onComplete, onSubmit, isBroker } = props;
  const { setError } = PaymentMethods.useContainer();
  const { account, refreshAccount } = Account.useContainer();
  const addPaymentMethod = React.useCallback(
    async (payload: PaymentPayload) => {
      if (onSubmit) {
        await onSubmit(payload);
        return onComplete();
      }

      try {
        const response = await api.paymentControllerConnectPaymentMethod(
          payload,
        );

        if (response.id) {
          await refreshAccount();
          onComplete(response);
        } else {
          throw new Error('Cannot connect payment method');
        }
      } catch (e) {
        console.error(e);
        if (isResponse(e)) {
          const error: InlineResponse400 = await e.json();
          Bugsnag.notify(error.message);
          setError(error.message);
        } else {
          Bugsnag.notify(e);
          setError(e.message);
        }
      }
    },
    [onSubmit],
  );
  const initialValues: PaymentPayload = value || {
    email:
      (!isBroker && account?.sensitive?.email) || props.mainPerson?.email || '',
    phone:
      (!isBroker && account?.sensitive?.phone) || props.mainPerson?.phone || '',
    personnummer:
      (!isBroker && account?.sensitive?.personnummer) ||
      props.mainPerson?.personnummer ||
      '',
    manual: isBroker ? null : false,
  };

  const renderForm = React.useCallback(
    ({
      handleSubmit,
      submitting,
      invalid,
      form: { change },
      values: { bank },
    }: FormRenderProps<PaymentPayload>) => {
      return (
        <FormBody
          isBroker={isBroker}
          handleSubmit={handleSubmit}
          submitting={submitting}
          invalid={invalid}
          change={change}
          bank={bank}
        />
      );
    },
    [],
  );

  return (
    <>
      <Markdown children="payment.intro" variant="body2" color="textPrimary" />

      <Form
        initialValues={initialValues}
        onSubmit={addPaymentMethod}
        render={renderForm}
      />
    </>
  );
};
