import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';
import { theme } from '../styles/theme';

const styles = makeStyles({
  player: {
    width: '100%',
    maxWidth: '720px',
    margin: '24px auto -32px',
    borderRadius: '12px',
    overflow: 'hidden',

    [theme.breakpoints.down('xs')]: {
      width: 'calc(100% + 64px)',
      margin: '24px -32px -56px',
    },

    '& div': {
      paddingTop: '56.25%',
      height: '0',
      position: 'relative',
    },

    '& video': {
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: '0',
      left: '0',
    },
  },
});

export const Video: React.FC<{ video?: string; videoPoster?: string }> = ({
  videoPoster,
  video,
}) => {
  const { player } = styles();
  return (
    <Box className={player}>
      <Box>
        <video
          src={video}
          poster={videoPoster}
          controls
          playsInline
          preload="metadata"
        />
      </Box>
    </Box>
  );
};
