import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import React from 'react';
import { FormattedMessage, IntlFormatters, useIntl } from 'react-intl';
import { currencyFormat } from '../styles';
import { theme } from '../styles/theme';
import { Order, Person, ProductPricing } from '../swagger';
import { SubmitButton } from './forms/SubmitButton';

const styles = makeStyles({
  container: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'center',

    '&:last-of-type': {
      marginBottom: 0,
    },

    '& .MuiButton-root': {
      marginRight: theme.spacing(1.5),
    },

    '& .MuiTypography-body2': {
      minWidth: '280px',
      margin: `${theme.spacing(1)}px 0`,
      flex: 1,
    },
  },
});

type Props = {
  type: Person.TypeEnum;
  order?: Order;
  onClick: () => void;
  loading?: boolean;
  isBroker?: boolean;
  pricing?: ProductPricing;
};

const getMessage = (
  type: Person.TypeEnum,
  order: Order,
  formatNumber: IntlFormatters['formatNumber'],
  isBroker?: boolean,
  pricing?: ProductPricing,
): { id: string; values?: { price: string; extra: string } } | undefined => {
  const currentPrice = order?.price || 0;

  const couplePrice = pricing?.couple.any || 0;
  const singleChildPrice = pricing?.single_child.any || 0;
  const familyPrice = pricing?.family.any || 0;

  const coupleExtra = Math.max(0, couplePrice - currentPrice);
  const singleChildExtra = Math.max(0, singleChildPrice - currentPrice);
  const familyExtra = Math.max(0, familyPrice - currentPrice);
  const orderType =
    order?.type || (isBroker ? Order.TypeEnum.Single : undefined);

  if (!orderType) {
    return undefined;
  }

  if (type === Person.TypeEnum.Child) {
    if (
      [Order.TypeEnum.SingleChild, Order.TypeEnum.Family].includes(orderType)
    ) {
      return {
        id: 'child.insure_free',
      };
    }
    if (orderType === Order.TypeEnum.Single) {
      return {
        id: 'child.insure',
        values: {
          price: formatNumber(singleChildPrice, currencyFormat),
          extra: formatNumber(singleChildExtra, currencyFormat),
        },
      };
    }
    if (orderType === Order.TypeEnum.Couple) {
      return {
        id: 'child.insure',
        values: {
          price: formatNumber(familyPrice, currencyFormat),
          extra: formatNumber(familyExtra, currencyFormat),
        },
      };
    }
  }

  if (
    type === Person.TypeEnum.Spouse &&
    ![Order.TypeEnum.Couple, Order.TypeEnum.Family].includes(orderType)
  ) {
    if (orderType === Order.TypeEnum.Single) {
      return {
        id: 'spouse.insure',
        values: {
          price: formatNumber(couplePrice, currencyFormat),
          extra: formatNumber(coupleExtra, currencyFormat),
        },
      };
    }
    if (orderType === Order.TypeEnum.SingleChild) {
      return {
        id: 'spouse.insure',
        values: {
          price: formatNumber(familyPrice, currencyFormat),
          extra: formatNumber(familyExtra, currencyFormat),
        },
      };
    }
  }
};

const getIcon = (type: Person.TypeEnum) => {
  if (type === Person.TypeEnum.Child) {
    return <GroupAddIcon color="primary" />;
  }

  return <PersonAddIcon color="primary" />;
};

export const AddPerson: React.FC<Props> = (props) => {
  const {
    type,
    order,
    onClick,
    loading,
    isBroker,
    pricing = order?.mainPricing?.health_plus,
  } = props;
  const { container } = styles();
  const { formatNumber } = useIntl();
  const message =
    order && getMessage(type, order, formatNumber, isBroker, pricing);

  if (!message || (!isBroker && !order?.type)) {
    return null;
  }

  return (
    <Box className={container}>
      <SubmitButton
        loading={loading}
        label={`${type}.cta`}
        onClick={onClick}
        variant="outlined"
        size="small"
        endIcon={getIcon(type)}
      />
      <Typography variant="body2" color="textPrimary">
        <FormattedMessage {...message} />
      </Typography>
    </Box>
  );
};
