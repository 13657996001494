import Bugsnag from '@bugsnag/js';
import { useEffect, useState } from 'react';
import { createContainer } from 'unstated-next';
import { Account as AccountModel, Broker, Pricing, Product } from '../swagger';
import { api, getAuthHeaders, sendBeacon } from '../utils/api';
import { byAge } from '../utils/byAge';
import { toDateTime } from '../utils/toDateTime';
import { defaultSettings, Settings } from './useSettings';

let lastToken: string | undefined;

const useAccount = () => {
  const { settings, update } = Settings.useContainer();
  const [state, setState] = useState<{
    loading: boolean;
    pricing?: Pricing;
    account?: AccountModel;
    basePricing?: Pricing;
  }>({ loading: true });
  let brokers: Broker[] = [];
  let admin = false;
  let by: string | undefined;
  let iss: 'admin' | 'broker' | undefined;

  try {
    const jwtParts = settings.token.split('.');
    const jwtData = jwtParts.length === 3 ? atob(jwtParts[1]) : '{}';
    const data = JSON.parse(jwtData) as {
      brokers?: string;
      admin?: boolean;
      id: string;
      by?: string;
      iss?: 'admin' | 'broker';
    };
    const brokerIds = (data.brokers || '').split(',');

    if (data.by) {
      by = data.by;
    }

    if (state.account && state.account.brokers) {
      brokers = state.account.brokers.filter((br) => brokerIds.includes(br.id));
    }

    if (data.iss) {
      iss = data.iss;
    }

    admin = data.admin || false;
  } catch (e) {
    console.error(e);
    Bugsnag.notify(e);
  }

  useEffect(() => {
    if (by && by !== 'pdf') {
      try {
        sendBeacon('auth_success', { by });
      } catch (e) {
        console.error(e);
      }
    }
  }, [by]);

  const reloadAccount = async () => {
    if (lastToken === settings.token) {
      return;
    }

    lastToken = settings.token;

    if (!state.loading) {
      setState({
        ...state,
        loading: true,
      });
    }

    if (settings.token) {
      try {
        const [updatedAccount, pricing, basePricing] = await Promise.all([
          api.accountControllerGet(),
          api.orderControllerGetPricing({
            headers: getAuthHeaders(),
          }),
          api.orderControllerGetPricingById('BASE'),
        ]);

        lastToken = undefined;

        setState({
          account: updatedAccount,
          loading: false,
          pricing,
          basePricing,
        });

        return updatedAccount;
      } catch (e) {
        console.error(e);
        Bugsnag.notify(e);
        update(defaultSettings);
      }
    }

    lastToken = '';
    setState({
      account: undefined,
      loading: false,
      pricing: await api.orderControllerGetPricing({
        headers: getAuthHeaders(),
      }),
      basePricing: await api.orderControllerGetPricingById('BASE'),
    });
  };

  useEffect(() => {
    void reloadAccount();
  }, [settings.token]);

  const refreshAccount = async () => {
    if (!settings.token) {
      return;
    }

    const freshAccount = await api.accountControllerGet();

    setState({
      ...state,
      account: freshAccount,
    });

    return freshAccount;
  };

  const orders = (state.account && state.account.orders) || [];
  const products = (state.account && state.account.products) || [];
  const validOrders = orders
    .filter(
      (order) =>
        order.type ||
        order.transactions.some((tr) => tr.id) ||
        order.products.every((pr) => pr.status === Product.StatusEnum.Pending),
    )
    .sort((a, b) =>
      b.created && a.created
        ? toDateTime(b.created).toMillis() - toDateTime(a.created).toMillis()
        : 0,
    );
  const validProducts = products
    .filter(
      (product) =>
        product.status !== Product.StatusEnum.Cancelled ||
        product.transactions.some((tr) => tr.id),
    )
    .sort(byAge);

  return {
    ...state,
    brokers,
    admin,
    reloadAccount,
    refreshAccount,
    validOrders,
    validProducts,
    by,
    iss,
  };
};

export const Account = createContainer(useAccount);
