import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Redirect } from 'react-router';
import { Content } from '../components/Content';
import { useLangRedirect } from '../hooks/useLangRedirect';
import { Page } from '../types';

export const NotFound: Page = () => {
  const redirect = useLangRedirect();

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <Content>
      <Typography variant="h3">Page not found</Typography>
    </Content>
  );
};

NotFound.page = 'notFound';
