import Button from '@material-ui/core/Button';
import DoneIcon from '@material-ui/icons/Done';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import useCopyToClipboard from 'react-use/lib/useCopyToClipboard';

export const OrderProductCopyButton: React.FC<{ link: string }> = ({
  link,
}) => {
  const [copyState, copy] = useCopyToClipboard();
  const [copied, setCopied] = React.useState(false);

  React.useEffect(() => {
    if (copied) {
      const timer = setTimeout(() => {
        setCopied(false);
      }, 3000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [copied]);

  const copyLink = React.useCallback(() => {
    copy(link);
    setCopied(true);
  }, [link]);

  if (copyState.noUserInteraction) {
    return (
      <Button
        onClick={copyLink}
        size="small"
        variant={copied ? 'text' : 'outlined'}
        color="primary"
        endIcon={copied && <DoneIcon />}
      >
        <FormattedMessage id={copied ? 'copied' : 'copy_link'} />
      </Button>
    );
  }

  return null;
};
