import * as React from 'react';

const LazyContacts = React.lazy<typeof import('../Contacts').Contacts>(() =>
  import('../Contacts').then((module) => ({ default: module.Contacts })),
);
export const SuspenseContacts: typeof import('../Contacts').Contacts = (
  props,
) => (
  <React.Suspense fallback={''}>
    <LazyContacts {...props} />
  </React.Suspense>
);

SuspenseContacts.page = 'contacts';
