import React, { useEffect } from 'react';
import { createContainer } from 'unstated-next';
import { Account } from './useAccount';
import { Settings } from './useSettings';

const useLogin = () => {
  const { account } = Account.useContainer();
  const {
    settings: { auth },
  } = Settings.useContainer();

  const [locked, setLocked] = React.useState(false);
  const [authShown, toggleAuth] = React.useState(false);

  useEffect(() => {
    if (!locked) {
      if (auth) {
        toggleAuth(true);
      } else if (account && authShown) {
        toggleAuth(false);
      }
    }
  }, [authShown, account, auth, locked]);

  return {
    locked,
    authShown,
    toggleAuth,
    setLocked,
  };
};

export const Login = createContainer(useLogin);
