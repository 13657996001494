import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Product } from '../swagger';
import { Details } from './dialogs/Details';
import { Markdown } from './Markdown';

interface Props {
  type: Product.TypeEnum;
}

export const InsuranceDetails: React.FC<Props> = ({ type }) => {
  return (
    <Details
      details={
        <Box p={3}>
          <Markdown children={`product.${type}.description`} />
        </Box>
      }
    >
      <Typography variant="h6">
        <FormattedMessage id={`product.${type}.title`} />
      </Typography>
    </Details>
  );
};
