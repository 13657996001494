import ButtonGroup from '@material-ui/core/ButtonGroup';
import React from 'react';
import { HEALTH_CHECKUP_PERIODS, PERIODS } from '../../utils/periods';
import { SubmitButton } from './SubmitButton';

type Props = {
  paymentPeriod?: number;
  loading: boolean;
  onSelect: (period: number) => void;
  disabled?: boolean;
  isHealthCheckup?: boolean;
};

export const PaymentIntervals: React.FC<Props> = ({
  paymentPeriod,
  loading,
  onSelect,
  disabled,
  isHealthCheckup,
}) => {
  const periods = isHealthCheckup ? HEALTH_CHECKUP_PERIODS : PERIODS;

  return (
    <ButtonGroup color="primary" disabled={disabled}>
      {Object.entries(periods).map(([label, period]) => (
        <SubmitButton
          key={label}
          label={label.toLowerCase()}
          disabled={loading}
          size="small"
          variant={period === paymentPeriod ? 'contained' : undefined}
          onClick={() => onSelect(period)}
        />
      ))}
    </ButtonGroup>
  );
};
