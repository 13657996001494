import Bugsnag from '@bugsnag/js';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import useToggle from 'react-use/lib/useToggle';
import { Account } from '../hooks/useAccount';
import { Order, OrderOptionsPayload, PaymentMethod } from '../swagger';
import { api } from '../utils/api';
import { isOrderHealthCheckup } from '../utils/isHealthCheckup';
import { formatName } from '../utils/paymentMethod';
import { InvoiceReference } from './forms/InvoiceReference';
import { PaymentIntervals } from './forms/PaymentIntervals';
import { PaymentGatewayLogo } from './PaymentGatewayLogo';

export const OrderPaymentMethod: React.FC<{
  order: Order;
  className: string;
}> = ({ order, className }) => {
  const { account, refreshAccount } = Account.useContainer();
  const [loading, toggleLoading] = useToggle(false);

  const activePaymentMethod =
    account &&
    account.paymentMethods &&
    account.paymentMethods.find((pm) =>
      [
        PaymentMethod.StatusEnum.Active,
        PaymentMethod.StatusEnum.Pending,
      ].includes(pm.status),
    );

  const isHealthCheckup = isOrderHealthCheckup(order);
  const isPending =
    activePaymentMethod &&
    activePaymentMethod.status === PaymentMethod.StatusEnum.Pending;

  const updateOrder = React.useCallback(
    async (payload: OrderOptionsPayload) => {
      toggleLoading();

      try {
        await api.orderControllerUpdateOrder(order.id, payload);
        await refreshAccount();
      } catch (e) {
        console.error(e);
        Bugsnag.notify(e);
      }

      toggleLoading();
    },
    [order.id, refreshAccount, toggleLoading],
  );

  if (!account || !order.payer || account.id !== order.payer.id) {
    return null;
  }

  return (
    <>
      <TableRow>
        <TableCell className={className}>
          <FormattedMessage id="payment_method" />
        </TableCell>
        <TableCell>
          {activePaymentMethod && (
            <>
              <Typography variant="h6">
                {formatName(activePaymentMethod)}
              </Typography>
              <PaymentGatewayLogo gateway={activePaymentMethod.gateway} />
            </>
          )}
          {isPending && (
            <Typography
              variant="h6"
              color="secondary"
              style={{ lineHeight: 'inherit' }}
            >
              <FormattedMessage id="payment_method_pending" />
            </Typography>
          )}
          {!activePaymentMethod && (
            <Typography
              variant="h6"
              color="error"
              style={{ lineHeight: 'inherit' }}
            >
              <FormattedMessage id="no_payment_method" />
            </Typography>
          )}
        </TableCell>
      </TableRow>
      {!isHealthCheckup && (
        <TableRow>
          <TableCell className={className}>
            <FormattedMessage id="payment_interval" />
          </TableCell>
          <TableCell>
            <PaymentIntervals
              loading={loading}
              paymentPeriod={order.paymentPeriod}
              onSelect={(paymentPeriod) => updateOrder({ paymentPeriod })}
            />
          </TableCell>
        </TableRow>
      )}
      <TableRow>
        <TableCell className={className}>
          <FormattedMessage id="invoice_reference" />
        </TableCell>
        <TableCell>
          <InvoiceReference
            loading={loading}
            defaultValue={order.invoiceReference}
            onChange={(invoiceReference) => updateOrder({ invoiceReference })}
          />
        </TableCell>
      </TableRow>
    </>
  );
};
