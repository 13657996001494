import Bugsnag from '@bugsnag/js';
import { useState } from 'react';
import { createContainer } from 'unstated-next';
import { Quiz as QuizModel } from '../swagger';
import { api, getAuthHeaders } from '../utils/api';
import { parseQuiz, Question } from '../utils/parseQuiz';

export const QuestionTypes: (keyof QuizModel)[] = [
  'needSecondOpinion',
  'needLeadingDoctors',
  'needInternational',
  'needInsurance',
  'needResult',
  'cancer',
  'potentialCancer',
  'tumor',
  'leukemia',
  'chronic',
  'heart',
  'diabetes',
  'brain',
  'examinations',
];

export const healthQuestions: (keyof QuizModel)[] = [
  ...QuestionTypes.filter((type) => !type.startsWith('need')),
];

export const useQuiz = () => {
  const [quiz, updateQuiz] = useState({} as QuizModel);
  const {
    products,
    isDone,
    questions,
    totalQuestions,
    activeQuestion,
    hasProducts,
  } = parseQuiz(quiz);

  const saveOrCreateQuiz = async (payload: QuizModel, complete = false) => {
    try {
      updateQuiz(payload);

      const savedQuiz = await api.quizControllerSave(complete, payload, {
        headers: getAuthHeaders(),
      });

      updateQuiz(savedQuiz);

      return savedQuiz;
    } catch (e) {
      console.error(e);
      Bugsnag.notify(e);
      updateQuiz(quiz);
      return payload;
    }
  };

  const answerQuestion = async (
    type: Question['type'],
    answer: number | null,
  ) => {
    await saveOrCreateQuiz({
      ...quiz,
      [type]: answer,
    });
  };

  const giveConsent = async (consent: boolean) => {
    const value = consent ? 1 : (null as unknown as number);

    await saveOrCreateQuiz({
      ...quiz,
      needInsurance: value,
      needInternational: value,
      needLeadingDoctors: value,
      needSecondOpinion: value,
      needResult: value,
    });
  };

  const prevQuestion = async () => {
    const prev = questions[activeQuestion - 1];
    if (prev) {
      await answerQuestion(prev.type, null);
    }
  };

  const reset = (initialQuiz = {} as QuizModel) => {
    updateQuiz(initialQuiz);
  };

  const load = async (id: string) => {
    const loadedQuiz = await api.quizControllerGet(id);
    updateQuiz(loadedQuiz);
    return loadedQuiz;
  };

  return {
    quiz,
    activeQuestion,
    pricing: quiz && quiz.pricing,
    progress: activeQuestion / totalQuestions,
    answerQuestion,
    giveConsent,
    prevQuestion,
    load,
    reset,
    hasProducts,
    isDone,
    questions,
    products,
    saveOrCreateQuiz,
  };
};

export const Quiz = createContainer(useQuiz);
