import React from 'react';
import { PageRoute, SimpleRoute } from '../components/PageRoute';
import { useRoutes } from '../hooks/useRoutes';
import { Documents } from './Documents';
import { InsuranceBrokerRedirect } from './InsuranceBrokerRedirect';
import { SuspenseQuiz } from './lazy';
import { Order } from './Order';

export const MinaRoutes: React.FC = React.memo(() => {
  const { mina } = useRoutes();

  return (
    <>
      <PageRoute path={`${mina}/new`} component={SuspenseQuiz} />
      <SimpleRoute
        path={`${mina}/insurance`}
        component={InsuranceBrokerRedirect}
      />
      <PageRoute path={`${mina}/order-:id`} component={Order} />
      <PageRoute path={`${mina}/order-:id/new`} component={SuspenseQuiz} />

      <PageRoute
        path={`${mina}/order-:id/docs-:product`}
        component={Documents}
      />

      <PageRoute path={`${mina}/docs-:product`} component={Documents} />
    </>
  );
});
