import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { bankName } from 'clearingnummer';
import React from 'react';
import { useField } from 'react-final-form';
import bankidLogo from '../../public/bankid.svg';
import { BankRequestAccount, PaymentPayload } from '../../swagger';
import { clearPersonalNumber } from '../../utils/clearPersonalNumber';
import { BankAccountsDialog } from '../dialogs/BankAccountsDialog';
import { SubmitButton } from './SubmitButton';

export const PaymentMethodFetchAccounts: React.FC<{
  change: (name: keyof PaymentPayload, value?: any) => void;
}> = ({ change }) => {
  const {
    input: { value: pn },
  } = useField('personnummer');
  const [open, setOpen] = React.useState(false);
  const personnummer = clearPersonalNumber(pn);
  const isCompany = personnummer && personnummer.length === 10;
  const openDialog = React.useCallback(() => {
    setOpen(true);
  }, []);
  const closeDialog = React.useCallback((account?: BankRequestAccount) => {
    if (account?.account) {
      change('clearing', account.clearing);
      change('account', account.account);
      change('bank', bankName(account.clearing || '') || '');
    }
    setOpen(false);
  }, []);

  if (isCompany) {
    return null;
  }

  return (
    <Box>
      <SubmitButton
        size="small"
        variant="outlined"
        color="primary"
        disabled={!personnummer}
        onClick={openDialog}
        label="fetch_bank"
        endIcon={<img src={bankidLogo} alt="BankID" width={24} height={24} />}
      />
      <Typography variant="body1" align="center">
        or enter manually:
      </Typography>
      <BankAccountsDialog
        open={open}
        personnummer={personnummer}
        onClose={closeDialog}
      />
    </Box>
  );
};
