import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';
import { theme } from '../styles/theme';
import { Markdown } from './Markdown';

const styles = makeStyles({
  control: {
    alignItems: 'flex-start',
    marginRight: 0,

    '& .MuiFormControlLabel-label': {
      color: theme.palette.text.primary,
      fontSize: theme.typography.body2.fontSize,
    },
  },
  checkbox: {
    paddingTop: '2px',
  },
});

export const InsuranceConsent: React.FC<{
  onConsent: (given: boolean) => void;
  labels?: string[];
  consent?: boolean;
}> = (props) => {
  const {
    consent = false,
    onConsent,
    labels = [
      'insurance_consent1',
      'insurance_consent2',
      'insurance_consent3',
      'insurance_consent4',
      'insurance_consent5',
    ],
  } = props;
  const { control, checkbox } = styles();
  const [checked, setChecked] = React.useState(consent);
  const onChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setChecked(event.target.checked);
      onConsent(event.target.checked);
    },
    [onConsent],
  );

  React.useEffect(() => {
    if (consent !== checked) {
      setChecked(consent);
    }
  }, [checked, consent]);

  return (
    <FormGroup>
      <FormControlLabel
        className={control}
        control={
          <Checkbox
            className={checkbox}
            color="primary"
            onChange={onChange}
            checked={checked}
          />
        }
        label={labels.map((label, i) => (
          <Markdown
            key={i}
            color="textPrimary"
            children={label}
            variant="body2"
            style={{ textAlign: 'left' }}
          />
        ))}
      />
    </FormGroup>
  );
};
