import Popover from '@material-ui/core/Popover';
import makeStyles from '@material-ui/core/styles/makeStyles';
import * as React from 'react';
import useBoolean from 'react-use/lib/useBoolean';
import { blue } from '../../styles';
import { theme } from '../../styles/theme';

const underscoreBase = {
  borderBottom: '1px dashed rgba(0, 46, 92, 0.2)',
  cursor: 'pointer',
  color: blue,

  '& *': {
    display: 'inline',
    lineHeight: 'inherit',
  },
};

export const detailsStyles = makeStyles({
  underscore: underscoreBase,
  underscoreError: {
    ...underscoreBase,
    borderBottomColor: theme.palette.error.light,
    color: theme.palette.error.main,
  },
});

interface DetailsProps {
  details: JSX.Element;
}

export const Details: React.FC<DetailsProps> = ({ children, details }) => {
  const { underscore } = detailsStyles();
  const [detailsOpen, toggleDetails] = useBoolean(false);
  const ref = React.useRef<HTMLDivElement | null>(null);

  const handleToggle = (e: React.MouseEvent) => {
    if ('stopPropagation' in e) {
      e.stopPropagation();
    }
    toggleDetails();
  };

  return (
    <>
      <span ref={ref} onClick={handleToggle} className={underscore}>
        {children}
      </span>
      {ref.current && (
        <Popover
          open={detailsOpen}
          onClose={handleToggle}
          onClick={handleToggle}
          anchorEl={ref.current}
          anchorOrigin={{
            horizontal: 'center',
            vertical: 'center',
          }}
          transformOrigin={{
            horizontal: 'center',
            vertical: 'center',
          }}
        >
          {details}
        </Popover>
      )}
    </>
  );
};
