import * as React from 'react';

const LazyAbout = React.lazy<typeof import('../About').About>(() =>
  import('../About').then((module) => ({ default: module.About })),
);
export const SuspenseAbout: typeof import('../About').About = (props) => (
  <React.Suspense fallback={''}>
    <LazyAbout {...props} />
  </React.Suspense>
);

SuspenseAbout.page = 'about';
