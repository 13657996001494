import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';
import faktabladPreview from '../public/faktablad-preview.png';
import faktablad from '../public/faktablad2023.pdf';
import purchaseinfoPreview from '../public/purchaseinfo-preview.png';
import purchaseinfo from '../public/purchaseinfo2023.pdf';
import villkorPreview from '../public/villkor-preview.png';
import villkor from '../public/villkor2023.pdf';
import { DocumentDialog } from './dialogs/DocumentDialog';
import { LinkButton } from './forms/SubmitButton';

const styles = makeStyles({
  document: {
    margin: '48px 0 12px',
    textAlign: 'left',
    whiteSpace: 'nowrap',

    '& img': {
      width: '80px',
      margin: '-48px 8px -56px -12px',
      transform: 'rotate(-4.8deg)',
      display: 'inline-block',
      boxShadow:
        '1px 2px 0 rgba(0,0,0,0.1), 7px 8px 17px -3px rgba(0,0,0,0.15)',
    },
  },
});

export const Documents: React.FC = () => {
  const { document } = styles();
  const [documentState, setDocument] = React.useState({
    href: '',
    title: '',
  });

  return (
    <Box
      display="flex"
      justifyContent="space-evenly"
      flexWrap="wrap"
      m="0 -24px"
    >
      <LinkButton
        label="purchaseinfo"
        href={purchaseinfo}
        onClick={(e: React.MouseEvent) => {
          e.preventDefault();
          setDocument({ href: purchaseinfo, title: 'purchaseinfo' });
        }}
        variant="outlined"
        color="secondary"
        size="medium"
        className={document}
      >
        <img loading="lazy" src={purchaseinfoPreview} alt="" />
      </LinkButton>
      <LinkButton
        label="faktablad"
        href={faktablad}
        onClick={(e: React.MouseEvent) => {
          e.preventDefault();
          setDocument({ href: faktablad, title: 'faktablad' });
        }}
        variant="outlined"
        color="secondary"
        size="medium"
        className={document}
      >
        <img loading="lazy" src={faktabladPreview} alt="" />
      </LinkButton>
      <LinkButton
        label="termsandconditions"
        href={villkor}
        onClick={(e: React.MouseEvent) => {
          e.preventDefault();
          setDocument({ href: villkor, title: 'termsandconditions' });
        }}
        variant="outlined"
        color="secondary"
        size="medium"
        className={document}
      >
        <img loading="lazy" src={villkorPreview} alt="" />
      </LinkButton>

      <DocumentDialog
        {...documentState}
        open={!!documentState.href}
        close={() => setDocument({ href: '', title: '' })}
      />
    </Box>
  );
};
