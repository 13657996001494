import ButtonGroup from '@material-ui/core/ButtonGroup';
import React from 'react';
import { useField } from 'react-final-form';
import { SubmitButton } from './SubmitButton';

export const PaymentAutoManual: React.FC<{ isBroker?: boolean }> = ({
  isBroker,
}) => {
  const {
    input: { value: isManual, onChange },
  } = useField<boolean | null>('manual', {
    defaultValue: null,
    allowNull: true,
  });

  return (
    <ButtonGroup color="primary" style={{ marginBottom: 16 }}>
      <SubmitButton
        label="autogiro"
        size="small"
        type="button"
        variant={isManual === false ? 'contained' : undefined}
        onClick={() => onChange({ target: { value: false } })}
      />
      <SubmitButton
        label="invoice"
        size="small"
        type="button"
        variant={isManual === true ? 'contained' : undefined}
        onClick={() => onChange({ target: { value: true } })}
      />
      {isBroker && (
        <SubmitButton
          label="omit_payment_method"
          size="small"
          type="button"
          variant={isManual === null ? 'contained' : undefined}
          onClick={() => onChange({ target: { value: null } })}
        />
      )}
    </ButtonGroup>
  );
};
