import * as React from 'react';
import { useIntl } from 'react-intl';
import MetaTags from 'react-meta-tags';
import { Meta } from '../hooks/useMeta';
import { useRouter } from '../hooks/useRouter';
import { Language } from '../hooks/useSettings';
import preview from '../public/opengraph.png';

export const MetaRenderer: React.FC = React.memo(() => {
  const { page } = Meta.useContainer();
  const {
    location: { pathname },
  } = useRouter();
  const { messages } = useIntl();
  const currentURL = React.useMemo(() => {
    return new URL(window.location.href);
  }, [pathname]);
  const canonicalURL = new URL(
    currentURL.pathname.replace(/(\/?)$/, '/'),
    currentURL.origin,
  );

  const pageTitle = page && messages[`title.${page}`];
  const title = (pageTitle ? `${pageTitle} | ` : '') + messages['unika.name'];
  const previewURL = new URL(preview, window.location.href);

  const description = page && messages[`description.${page}`];
  const currentLangMatch = currentURL.pathname.match(/^\/(\w{2})/);
  const currentLang = currentLangMatch && currentLangMatch[1];
  const langs = Object.values(Language);

  React.useEffect(() => {
    const isMain = !currentURL.pathname.replace('/', '');

    if (!isMain && currentLang && langs.includes(currentLang as Language)) {
      document.documentElement.setAttribute('lang', currentLang);
    } else {
      document.documentElement.removeAttribute('lang');
    }
  }, [currentLang]);

  return (
    <MetaTags>
      <title>{title}</title>
      {description && <meta name="description" content={`${description}`} />}
      <link
        rel="alternate"
        hrefLang="x-default"
        href={`${new URL(currentURL.origin)}`}
      />
      {currentLang &&
        langs.map((lang) => {
          const langURL = new URL(`${currentURL}`);

          langURL.pathname = langURL.pathname
            .replace(/^\/\w{2}/, `/${lang}`)
            .replace(/(\/?)$/, '/');

          return (
            <link
              key={lang}
              rel="alternate"
              hrefLang={lang}
              href={`${langURL}`}
            />
          );
        })}
      <link rel="canonical" href={`${canonicalURL}`} />

      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={`${description || ''}`} />
      <meta property="og:url" content={`${canonicalURL}`} />
      <meta property="og:image" content={`${previewURL}`} />
      <meta property="og:image:width" content="1280" />
      <meta property="og:image:height" content="628" />

      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={`${canonicalURL}`} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={`${description || ''}`} />
      <meta property="twitter:image" content={`${previewURL}`} />
    </MetaTags>
  );
});
