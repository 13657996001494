import React from 'react';
import { createContainer } from 'unstated-next';
import { Broker } from '../swagger';
import { api } from '../utils/api';
import { Account } from './useAccount';
import { Settings } from './useSettings';

const useProfileSelector = () => {
  const { account, brokers, admin, loading } = Account.useContainer();
  const { update } = Settings.useContainer();
  const companies = (account && account.companies) || [];
  const brokerOptions = (account && account.brokers) || [];
  const brokerOption: Broker | undefined = brokerOptions.length
    ? {
        id: brokerOptions.map((br) => br.id).join(','),
        name: 'broker.office',
        code: '',
        created: new Date(),
        updated: new Date(),
        commission: 0,
        utm: '',
      }
    : undefined;
  const [shouldSelectProfile, setShouldSelect] = React.useState(false);
  const [isTokenUpdated, setTokenUpdated] = React.useState(false);

  React.useEffect(() => {
    if (!brokers.length && !admin) {
      setShouldSelect(!!companies.length || !!brokerOption);
    }
  }, [account?.id, brokerOption?.id, admin]);

  React.useEffect(() => {
    if (isTokenUpdated && !loading) {
      setShouldSelect(false);
      setTokenUpdated(false);
    }
  }, [isTokenUpdated, loading]);

  const selectProfile = async (accountId: string) => {
    const response = await api.accountControllerSwitch(accountId);

    update({
      token: response.token || '',
      auth: '',
    });
    setTokenUpdated(true);
  };

  return {
    shouldSelectProfile,
    profileOptions: account ? [account, ...companies, brokerOption] : [],
    selectProfile,
    setShouldSelect,
    loading,
    brokers,
  };
};

export const ProfileSelector = createContainer(useProfileSelector);
