import * as React from 'react';
import { Redirect } from 'react-router';
import { useRoutes } from '../../hooks/useRoutes';
import { Page } from '../../types';

export const SuspenseQuiz: Page = () => {
  const { insurance } = useRoutes();
  const url = new URL(window.location.href);
  url.pathname = insurance;
  const href = url.toString().replace(url.origin, '');

  return <Redirect to={href} />;
};

SuspenseQuiz.page = 'quiz';
