import React from 'react';
import { Content } from '../components/Content';
import { theme } from '../styles/theme';
import { Page } from '../types';

const CookieBot: React.FC = React.memo(() => {
  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (ref.current) {
      const cookieBot = document.createElement('script');
      cookieBot.type = 'text/javascript';
      cookieBot.id = 'CookieDeclaration';
      cookieBot.async = true;
      cookieBot.src =
        'https://consent.cookiebot.com/ef6f8eef-307a-4cf7-a55c-391db0a9fde4/cd.js';
      ref.current.appendChild(cookieBot);

      return () => {
        if (ref.current) {
          ref.current.removeChild(cookieBot);
        }
      };
    }

    return () => undefined;
  }, [ref.current]);

  return <div ref={ref} style={theme.typography.body2} />;
});

export const Cookies: Page = () => {
  return (
    <Content>
      <CookieBot />
    </Content>
  );
};

Cookies.page = 'cookies';
