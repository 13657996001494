import * as React from 'react';

const LazyAgreement = React.lazy<typeof import('../pdf/Agreement').Agreement>(
  () =>
    import('../pdf/Agreement').then((module) => ({
      default: module.Agreement,
    })),
);

export const SuspenseAgreement: typeof import('../pdf/Agreement').Agreement = (
  props,
) => (
  <React.Suspense fallback={''}>
    <LazyAgreement {...props} />
  </React.Suspense>
);

SuspenseAgreement.page = 'agreement';
