import en from './en.json';
import sv from './sv.json';

Object.keys(en).forEach((key) => {
  if (en[key] && !sv[key]) {
    sv[key] = en[key];
  }
});

Object.keys(sv).forEach((key) => {
  if (sv[key] && !en[key]) {
    en[key] = sv[key];
  }
});

export const locales = {
  en,
  sv,
};
