import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import { Color } from '@material-ui/lab/Alert/Alert';
import React, { FC } from 'react';
import { theme } from '../styles/theme';

const styles = makeStyles({
  status: {
    '& .MuiTypography-root': {
      color: 'inherit',
      marginBottom: theme.spacing(1),
    },

    '& .MuiAlert-icon': {
      paddingTop: theme.spacing(1.5),
    },
    '& .MuiAlert-message': {
      flex: 1,
    },
    '& .MuiListItem-root': {
      display: 'inline-block',
      width: 'auto',
    },
    '& .MuiTypography-h3': {
      fontSize: '1.2rem',
    },
  },
});

export const InsuranceProductStatusWrapper: FC<{ severity: Color }> = ({
  children,
  severity,
}) => {
  const { status: statusClass } = styles();

  return (
    <Alert className={statusClass} severity={severity}>
      {children}
    </Alert>
  );
};
