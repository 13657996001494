export const parent = (path: string): string => {
  const parts = path.split('/');
  parts.pop();
  return parts.join('/') || '/';
};

export const getCurrentURL = (): string => {
  const currentURL = new URL(window.location.href);
  currentURL.pathname = currentURL.pathname.replace(/\/$/, '');
  return `${currentURL}`.replace(currentURL.origin, '');
};

export const getPath = (href: string): string => {
  const url = new URL(href, window.location.href);
  return url.pathname.replace(/\/$/, '');
};
