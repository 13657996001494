import { healthQuestions } from '../hooks/useQuiz';
import { Product as ProductModel, Quiz, Quiz as QuizModel } from '../swagger';

export interface Question {
  type: keyof QuizModel;
  answer?: number;
}

export const parseQuiz = (quiz: Quiz) => {
  const products: ProductModel.TypeEnum[] =
    (quiz.productTypes as unknown as ProductModel.TypeEnum[]) || [];
  const questions: Question[] = !quiz.id
    ? []
    : healthQuestions.map((type) => {
        return {
          type,
          answer: quiz[type] as number | undefined,
        };
      });

  const activeQuestion = questions.findIndex(
    (q) => typeof q.answer !== 'number',
  );
  const totalQuestions = questions.length;
  const hasProducts = !!products.length;
  const isDone = !!quiz.id && (activeQuestion === -1 || !hasProducts);

  return {
    isDone,
    questions,
    activeQuestion,
    totalQuestions,
    hasProducts,
    products,
  };
};
