import { useState } from 'react';
import { createContainer } from 'unstated-next';
import { PaymentMethod } from '../swagger';
import { toDateTime } from '../utils/toDateTime';
import { Account } from './useAccount';

const usePaymentMethods = () => {
  const [error, setError] = useState('');
  const { account, brokers, admin } = Account.useContainer();
  const isBroker = !!(brokers.length || admin);
  const paymentMethods = (!isBroker && account?.paymentMethods) || [];
  const sortedPaymentMethods = paymentMethods.sort((a, b) =>
    toDateTime(b.created)
      .toString()
      .localeCompare(toDateTime(a.created).toString()),
  );
  const activePaymentMethods = sortedPaymentMethods.filter((pm) =>
    [
      PaymentMethod.StatusEnum.Active,
      PaymentMethod.StatusEnum.Pending,
    ].includes(pm.status),
  );
  const hasPaymentMethod = !!activePaymentMethods.length;
  const isPayer = account?.orders?.some(
    (order) => !order.payer || order.payer.id === account.id,
  );

  return {
    error,
    setError,
    sortedPaymentMethods,
    activePaymentMethods,
    hasPaymentMethod,
    isPayer,
  };
};

export const PaymentMethods = createContainer(usePaymentMethods);
