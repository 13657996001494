import React from 'react';
import useBoolean from 'react-use/lib/useBoolean';
import { CancellationDialog } from './dialogs/CancellationDialog';
import { SubmitButton } from './forms/SubmitButton';

type Props = {
  id: string;
  label?: string;
};

export const OrderProductCancelButton: React.FC<Props> = (props) => {
  const { id, label = 'cancel.cta' } = props;
  const [dialogOpen, toggleDialog] = useBoolean(false);

  return (
    <>
      <SubmitButton
        label={label}
        onClick={toggleDialog}
        size="small"
        color="secondary"
        variant="text"
      />
      <CancellationDialog id={id} isOpen={dialogOpen} onClose={toggleDialog} />
    </>
  );
};
