import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { Account } from '../hooks/useAccount';
import { inactiveCard } from '../styles';
import { Order, Product } from '../swagger';
import { byAge } from '../utils/byAge';
import { isOrderHealthCheckup } from '../utils/isHealthCheckup';
import { shiftMainProductFirst } from '../utils/product';
import { InsureSelfButton } from './forms/InsureSelfButton';
import { InsureChild } from './InsureChild';
import { InsureSpouse } from './InsureSpouse';
import { OrderProduct } from './OrderProduct';

interface OrderPersonsProps {
  order: Order;
}

export const OrderPersons: React.FC<OrderPersonsProps> = ({ order }) => {
  const { account, pricing, validProducts, validOrders } =
    Account.useContainer();
  const type = order.type;
  const productPricing = pricing && pricing[Product.TypeEnum.HealthPlus];

  const nonCancelledProducts = !order
    ? []
    : order.products
        .filter((product) => product.status !== Product.StatusEnum.Cancelled)
        .sort(byAge);

  if (account) {
    shiftMainProductFirst(nonCancelledProducts, account);
  }

  const isHealthCheckup = isOrderHealthCheckup(order);
  const noInsurance = !validProducts.length && !validOrders.length;
  const cancelledProducts = !order
    ? []
    : order.products.filter(
        (product) =>
          product.status === Product.StatusEnum.Cancelled &&
          product.transactions.length,
      );

  return (
    <>
      {!type && noInsurance && (
        <Card {...inactiveCard}>
          <CardHeader
            title={
              <Typography variant="h5" color="textSecondary">
                {account && account.sensitive && account.sensitive.fullName}
              </Typography>
            }
          />
          <CardActions style={{ paddingTop: 0 }}>
            <InsureSelfButton />
          </CardActions>
        </Card>
      )}

      {nonCancelledProducts.map((product, index) => (
        <OrderProduct product={product} index={index} key={product.id} />
      ))}

      {!!nonCancelledProducts.length && productPricing && !isHealthCheckup && (
        <>
          <InsureSpouse order={order} />
          <InsureChild order={order} />
        </>
      )}

      {!!cancelledProducts.length && (
        <Box mb={2}>
          <Divider />
        </Box>
      )}

      {cancelledProducts.map((product, index) => (
        <OrderProduct product={product} index={index} key={product.id} />
      ))}
    </>
  );
};
