import { makeStyles } from '@material-ui/core/styles';
import { theme } from './theme';

export const insuranceStyles = makeStyles({
  card: {
    border: '1px solid rgba(0,0,0,0.1)',
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(0, -1.25, 2),
    },

    '& .insurance-section': {
      borderTop: '1px solid rgba(0,0,0,0.1)',
      flexWrap: 'wrap',

      '&:first-of-type': {
        borderTop: '0 none',
      },

      [theme.breakpoints.down('sm')]: {
        '& > *': {
          paddingBottom: '20px',
        },
        '& > *:last-child': {
          paddingBottom: 0,
        },
      },
      [theme.breakpoints.up('sm')]: {
        display: 'flex',

        '& > *': {
          width: 'auto',
          flex: 1,
        },
        '& > *:last-child': {
          width: '55%',
        },
      },
    },
  },
  actions: {
    marginTop: '-18px',
    border: `1px solid rgba(0,0,0,0.15)`,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  buttons: {},
  signature: {
    border: '1px dashed rgba(0,0,0,0.16)',
    textAlign: 'center',
    marginBottom: '24px',
  },
  after: {
    display: 'flex',
    flexWrap: 'wrap',

    '& > div': {
      flex: 1,
      padding: '0 8px',
      minWidth: '200px',
    },
  },
});
