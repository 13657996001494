import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import useBoolean from 'react-use/lib/useBoolean';
import { DocumentDialog } from '../components/dialogs/DocumentDialog';
import { AuthForm } from '../components/forms/AuthForm';
import { Markdown } from '../components/Markdown';
import policy from '../public/privacy_policy.pdf';
import { theme } from '../styles/theme';

const styles = makeStyles({
  about: {
    [theme.breakpoints.down('xs')]: {
      order: 2,
    },
  },
  form: {
    [theme.breakpoints.down('xs')]: {
      order: 1,
    },
  },
});

export const Auth: React.FC = () => {
  const intl = useIntl();
  const { about, form } = styles();
  const [isPPOpen, togglePPOpen] = useBoolean(false);
  const onDisclaimerClick = React.useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      const href = (e.target as HTMLAnchorElement).href;
      if (href) {
        e.preventDefault();
        e.stopPropagation();
        togglePPOpen();
      }
    },
    [togglePPOpen],
  );

  return (
    <>
      <Grid container alignItems="stretch">
        <Grid item xs={12} sm={6} className={about}>
          <Box p={3}>
            <Typography variant="h3">
              <FormattedMessage id="my_unika.title" />
            </Typography>
            <Typography variant="body1">
              <FormattedMessage id="my_unika.intro" />
            </Typography>
            <List component="ul">
              {intl
                .formatMessage({ id: 'my_unika.usps' })
                .split(',')
                .map((usps) => (
                  <ListItem component="li" key={usps}>
                    {usps}
                  </ListItem>
                ))}
            </List>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} className={form}>
          <Box display="flex" flexDirection="column" height="100%">
            <Box flex={1} display="flex" flexDirection="column">
              <AuthForm />
            </Box>
            <Box flex={0} p={3} pt={1} pb={2} onClick={onDisclaimerClick}>
              <Markdown
                variant="body2"
                children="sign_in_disclaimer"
                link="simple"
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <DocumentDialog
        title="privacy_policy"
        href={policy}
        open={isPPOpen}
        close={togglePPOpen}
      />
    </>
  );
};
