import React from 'react';
import { Order } from '../../hooks/useOrder';
import { InfoDialog } from './InfoDialog';

export const OrderError = React.memo(() => {
  const { error, setError } = Order.useContainer();
  const onClose = React.useCallback(() => {
    setError('');
  }, [setError]);

  return <InfoDialog isOpen={!!error} onClose={onClose} title={error} />;
});
