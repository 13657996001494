import * as React from 'react';
import useAsync from 'react-use/lib/useAsync';
import useTimeout from 'react-use/lib/useTimeout';

const GrowLottie = React.lazy(() => import('./GrowLottie'));

interface HeartProps {
  loop?: boolean;
  color?: boolean;
}

export const Heart: React.FC<HeartProps> = React.memo((props) => {
  const { loop = true, color = false } = props;
  const [showHeart] = useTimeout(100);
  const animationData = useAsync(() =>
    color
      ? import(
          '../../lordicon/icons/20-love-heart/20-love-heart-outline-unika.json'
        )
      : import(
          '../../lordicon/icons/20-love-heart/20-love-heart-outline-white.json'
        ),
  );

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        overflow: 'hidden',
      }}
    >
      {!animationData.loading && (
        <div style={{ height: '100%' }}>
          <React.Suspense fallback={<></>}>
            <GrowLottie
              show={!!showHeart}
              options={{
                animationData: animationData.value,
                loop,
              }}
              speed={0.5}
              isClickToPauseDisabled
            />
          </React.Suspense>
        </div>
      )}
    </div>
  );
});
