import { useEffect, useState } from 'react';
import useLocalStorage from 'react-use/lib/useLocalStorage';
import { createContainer } from 'unstated-next';
import { apiConfiguration } from '../utils/api';
import { detectLanguage } from '../utils/detectLanguage';

export enum Language {
  Svenska = 'sv',
  English = 'en',
}

interface Settings {
  language: Language;
  token: string;
  auth?: { id: string; qr?: string; autoStartToken?: string };
  persist: boolean;
}

export const defaultSettings: Settings = {
  language: detectLanguage(Object.values(Language)) as Language,
  token: '',
  auth: undefined,
  persist: true,
};

const updateApiKey = (settings: Settings) => {
  apiConfiguration.apiKey = settings.token
    ? `Bearer ${settings.token}`
    : undefined;
};

const useSettings = () => {
  const [persisted, persist] = useLocalStorage('settings', defaultSettings);
  const [settings, setSettings] = useState<Settings>(
    Object.assign({}, persisted),
  );

  const update = (newSettings: Partial<Settings>) => {
    Object.assign(settings, newSettings);
    updateApiKey(settings);
    setSettings({ ...settings });
  };

  updateApiKey(settings);

  useEffect(() => {
    document.documentElement.lang = settings.language;
  }, [settings.language]);

  useEffect(() => {
    if (settings.persist) {
      persist(settings);
    }
  }, [settings]);

  return {
    settings,
    update,
  };
};

export const Settings = createContainer(useSettings);
