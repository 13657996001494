import * as React from 'react';

const LazyTermsAndConditions = React.lazy<
  typeof import('../pdf/TermsAndConditions').TermsAndConditions
>(() =>
  import('../pdf/TermsAndConditions').then((module) => ({
    default: module.TermsAndConditions,
  })),
);
export const SuspenseTermsAndConditions: typeof import('../pdf/TermsAndConditions').TermsAndConditions =
  (props) => (
    <React.Suspense fallback={''}>
      <LazyTermsAndConditions {...props} />
    </React.Suspense>
  );

SuspenseTermsAndConditions.page = 'termsandconditions';
