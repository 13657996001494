import makeStyles from '@material-ui/core/styles/makeStyles';
import { DateTime, DateTimeFormatOptions, LocaleOptions } from 'luxon';
import * as React from 'react';
import { Settings } from '../hooks/useSettings';
import { dateFormat } from '../styles';

const styles = makeStyles({
  nowrap: {
    whiteSpace: 'nowrap',
  },
});

interface FormattedDurationProps {
  from: DateTime;
  to: DateTime;
}

export const FormattedDuration: React.FC<FormattedDurationProps> = (props) => {
  const { nowrap } = styles();
  const { settings } = Settings.useContainer();
  const format: LocaleOptions & DateTimeFormatOptions = {
    ...dateFormat,
    locale: settings.language,
  };
  const from = props.from.toLocaleString(format);
  const to = props.to.toLocaleString(format);

  return (
    <>
      <span className={nowrap}>{from}</span>
      {to !== from && (
        <>
          {' – '}
          <span className={nowrap}>{to}</span>
        </>
      )}
    </>
  );
};
