import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FormattedName } from '../components/FormattedName';
import { Sheet } from '../components/Sheet';
import { Account } from '../hooks/useAccount';
import { Auth as AuthContainer } from '../hooks/useAuth';
import { ProfileSelector } from '../hooks/useProfileSelector';
import { Settings } from '../hooks/useSettings';
import '../public/bankgirot.svg';
import '../public/trustly.svg';
import { AuthRequest } from '../swagger';
import { Page, SheetProps } from '../types';
import { Auth } from './Auth';
import { LazyAdmin } from './lazy/Admin';
import { LazyProfile } from './lazy/Profile';
import { MinaRoutes } from './MinaRoutes';
import { ProfileSelector as ProfileSelectorComponent } from './ProfileSelector';

export const Mina: Page = ({ match }) => {
  const { settings } = Settings.useContainer();
  const { account, brokers, admin } = Account.useContainer();
  const { status } = AuthContainer.useContainer();
  const { shouldSelectProfile } = ProfileSelector.useContainer();

  const getSheetProps = (): React.PropsWithChildren<SheetProps> => {
    const props: SheetProps = {
      match,
    };

    if (!settings.token) {
      return {
        ...props,
        active: true,
        button: false,
        p: 0,
        loading: status === AuthRequest.StatusEnum.Pending,
        children: <Auth />,
      };
    }

    if (!account) {
      return {
        ...props,
        loading: true,
        title: <FormattedMessage id="loading" />,
        button: false,
        children: <Box p={3} textAlign={'center'} />,
      };
    }

    const fallback = (
      <CircularProgress variant="indeterminate" color="inherit" size={24} />
    );

    if (shouldSelectProfile) {
      return {
        ...props,
        title: <FormattedName account={account} />,
        button: false,
        children: <ProfileSelectorComponent />,
      };
    }

    if (brokers.length || admin) {
      return {
        ...props,
        maxWidth: 'xl',
        button: false,
        children: (
          <React.Suspense fallback={fallback}>
            <LazyAdmin />
          </React.Suspense>
        ),
      };
    }

    return {
      ...props,
      title: <FormattedName account={account} />,
      button: false,
      children: (
        <React.Suspense fallback={fallback}>
          <LazyProfile match={match} />
        </React.Suspense>
      ),
    };
  };

  return (
    <>
      <Sheet {...getSheetProps()} />
      {!!settings.token && !!account && <MinaRoutes />}
    </>
  );
};

Mina.page = 'mina';
