import Box from '@material-ui/core/Box';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useQuiz } from '../../hooks/useQuiz';
import { Person, Quiz } from '../../swagger';
import { personsTranslations } from '../../utils/persons';
import { SubmitButton } from '../forms/SubmitButton';
import { InsuranceQuizTable } from '../InsuranceQuizTable';
import { Markdown } from '../Markdown';
import { InfoDialog, InfoDialogProps } from './InfoDialog';
import TypeEnum = Person.TypeEnum;

export const defaultQuizAnswers = {
  needSecondOpinion: 1,
  needInsurance: 1,
  needInternational: 1,
  needLeadingDoctors: 1,
  needResult: 1,
} as Quiz;

type Props = Omit<InfoDialogProps, 'isOpen'> & {
  person?: Person;
  onSave: (person: Person) => void;
  isBroker?: boolean;
  label?: string;
};

export const InsuranceQuizDialog: React.FC<Props> = (props) => {
  const { person, onSave, isBroker, label = 'save_quiz', ...rest } = props;
  const { quiz, answerQuestion, reset, saveOrCreateQuiz } = useQuiz();
  const isForChild = person?.type === Person.TypeEnum.Child;
  const [ready, setReady] = React.useState(false);

  React.useEffect(() => {
    setReady(false);

    if (!person) {
      return;
    }

    if (person?.quiz) {
      reset(person.quiz);
    } else if (isForChild) {
      reset(defaultQuizAnswers);
    } else {
      reset({} as Quiz);
    }
    setReady(true);
  }, [person]);

  const savePerson = async () => {
    if (person) {
      person.quiz = isBroker ? await saveOrCreateQuiz(quiz) : quiz;
      onSave(person);
    }
  };

  return (
    <InfoDialog
      {...rest}
      isOpen={!!person}
      maxWidth="md"
      title={
        person && <FormattedMessage id={personsTranslations[person.type]} />
      }
    >
      {ready && person && (
        <InsuranceQuizTable
          person={person}
          onAnswer={answerQuestion}
          isBroker={isBroker}
          disclaimer={
            person &&
            person?.type !== TypeEnum.Spouse && (
              <TableRow key="disclaimer">
                <TableCell colSpan={2}>
                  <Markdown variant="body2" color="textPrimary">
                    {isForChild
                      ? 'health_declaration.child.intro'
                      : 'health_declaration.intro'}
                  </Markdown>
                </TableCell>
              </TableRow>
            )
          }
        />
      )}
      <Box display="flex" justifyContent="center" py={1}>
        <SubmitButton label={label} onClick={savePerson} />
      </Box>
    </InfoDialog>
  );
};
