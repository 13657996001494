import { green, red } from '@material-ui/core/colors';
import { createTheme } from '@material-ui/core/styles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { CSSProperties } from '@material-ui/styles';
import { blue, Domine, easing, Lato, yellow } from '.';

export const uppercased: CSSProperties = {
  letterSpacing: '0.05em',
  fontWeight: 'bold',
};

const breakpoints = createBreakpoints({});

export const theme = createTheme({
  palette: {
    background: {
      default: '#fff',
    },
    primary: {
      main: blue,
    },
    secondary: {
      main: yellow,
    },
  },
  typography: {
    fontFamily: Lato,
    h1: {
      fontSize: '2.5rem',
      fontFamily: Domine,
      fontWeight: 'normal',
      lineHeight: '140%',
    },
    h2: {
      fontSize: '2rem',
      fontFamily: Domine,
      fontWeight: 'bold',
    },
    h3: {
      fontSize: '1.8rem',
      fontFamily: Domine,
    },
    h4: {
      fontSize: '1.4rem',
      fontFamily: Domine,
    },
    h5: {
      fontSize: '1.2rem',
      fontFamily: Domine,
    },
    h6: {
      letterSpacing: '0.03em',
      textTransform: 'uppercase',
      fontSize: '0.9rem',
      fontFamily: Lato,
      fontWeight: 'bold',
    },
    subtitle1: {
      letterSpacing: '0.03em',
      textTransform: 'uppercase',
      fontSize: '0.8rem',
      color: 'rgba(0, 0, 0, 0.54)',
    },
    body1: {
      lineHeight: '175%',
    },
    body2: {
      color: 'rgba(0, 0, 0, 0.54)',
      lineHeight: '175%',
    },
  },
  overrides: {
    MuiTypography: {
      body1: {
        marginBottom: '16px',
      },
      body2: {
        marginBottom: '12px',
      },
      h3: {
        marginBottom: '16px',
      },
      h4: {
        marginBottom: '12px',
      },
      h5: {
        marginBottom: '12px',
      },
    },
    MuiList: {
      padding: {
        paddingTop: 0,
        paddingBottom: 0,
      },
      root: {
        fontFamily: Lato,
      },
    },
    MuiInputBase: {
      input: {
        fontSize: '1.2rem',
      },
    },
    MuiButton: {
      root: {
        borderRadius: '64px',

        '&[disabled] .invert-disabled': {
          filter: 'invert(1)',
          opacity: 0.2,
        },
      },
      label: {
        ...uppercased,
      },
    },
    MuiLink: {
      root: {
        cursor: 'pointer',
        display: 'inline-block',
        borderBottom: `1px solid ${yellow}`,
        lineHeight: '120%',
        transition: `0.2s all ${easing}`,
      },
      underlineHover: {
        '&:hover': {
          textDecoration: 'none',
          borderBottomColor: blue,
        },
      },
    },
    MuiDialog: {
      paper: {
        margin: '24px',

        [breakpoints.down('sm')]: {
          margin: '8px',
        },
      },
    },
    MuiTabs: {
      flexContainer: {
        justifyContent: 'center',
      },
    },
    MuiTab: {
      root: {
        paddingLeft: '8px',
        paddingBottom: '0',
        paddingRight: '8px',
        minWidth: '0!important',
        ...uppercased,
      },
    },
    MuiLinearProgress: {
      root: {
        height: '6px',
      },
    },
    MuiTable: {
      root: {
        marginBottom: '24px',
        contain: 'strict',
        contentVisibility: 'auto',
      },
    },
    MuiTableRow: {
      root: {
        pageBreakInside: 'avoid',
      },
    },
    MuiTableCell: {
      root: {
        fontWeight: 'inherit',
        verticalAlign: 'top',
        padding: '14px 12px',
        '&:first-of-type': {
          paddingLeft: '0px',
        },
        '&:last-of-type': {
          paddingRight: '0px',
        },
      },
    },
    MuiListItem: {
      gutters: {
        padding: '4px 20px',
        lineHeight: 1.5,

        '&:before': {
          content: "'✓'",
          display: 'inline-block',
          width: '20px',
          color: yellow,
          fontWeight: 'bold',
          marginLeft: '-20px',
          alignSelf: 'flex-start',
          flex: '0 0 20px',
        },

        '&.yes:before': {
          color: green.A700,
        },
        '&.no:before': {
          color: red.A700,
          content: '"✗"',
        },
      },
    },
    MuiCardHeader: {
      action: {
        marginTop: 0,
        marginRight: '-4px',
      },
      subheader: {
        marginBottom: 0,
      },
    },
    MuiChip: {
      root: {
        ...uppercased,
        textTransform: 'uppercase',
        fontSize: '0.65em',
        height: '24px',
      },
    },
    MuiCard: {
      root: {
        margin: '2px',
        marginBottom: '16px',
      },
    },
    MuiStepLabel: {
      label: {
        marginBottom: 0,
      },
    },
    MuiStepConnector: {
      lineVertical: {
        minHeight: '12px',
      },
    },
    MuiDialogActions: {
      root: {
        justifyContent: 'space-evenly',
        paddingBottom: '24px',
      },
    },
    MuiStepContent: {
      root: {
        marginTop: '4px',
        marginBottom: '4px',
        marginLeft: '10px',
        paddingLeft: '16px',
        paddingRight: 0,
        paddingBottom: '8px',

        '& .MuiTypography-body2': {
          lineHeight: '140%',
        },
      },
    },
    MuiStepIcon: {
      root: {
        fontSize: '1.5em',
      },
    },
    MuiBadge: {
      badge: {
        fontWeight: 'bold',
        fontSize: '12px',
        fontFamily: Domine,

        '&:empty': {
          minWidth: '0',
          width: '8px',
          height: '8px',
          lineHeight: '8px',
          padding: '0',
        },
      },
    },
    MuiExpansionPanel: {
      root: {
        '&$expanded': {
          marginBottom: '12px',
        },
      },
    },
    MuiExpansionPanelSummary: {
      root: {
        paddingLeft: '16px',
        paddingRight: '16px',
        '&$expanded': {
          minHeight: undefined,
        },
      },
      content: {
        color: 'rgba(0, 0, 0, 0.54)',
        marginTop: '16px',
        marginBottom: '16px',
        '&$expanded': {
          color: blue,
          margin: undefined,
        },
      },
    },
    MuiExpansionPanelDetails: {
      root: {
        paddingTop: 0,
        paddingLeft: '16px',
        paddingRight: '16px',
      },
    },
    MuiExpansionPanelActions: {
      root: {
        justifyContent: 'center',
        paddingBottom: '24px',
      },
    },
    MuiAppBar: {
      colorDefault: {
        backgroundColor: 'transparent',
      },
    },
    MuiToolbar: {
      root: {
        paddingTop: '16px',
        paddingBottom: '16px',
      },
    },
    MuiFormLabel: {
      filled: {
        color: blue,
      },
    },
  },
});
