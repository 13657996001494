import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Variant } from '@material-ui/core/styles/createTypography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography, { TypographyClassKey } from '@material-ui/core/Typography';
import { PrimitiveType } from 'intl-messageformat';
import * as React from 'react';
import { useIntl } from 'react-intl';
import ReactMarkdown from 'react-markdown';
import { HeadingComponent } from 'react-markdown/src/ast-to-react';
import { Highlight } from './Highlight';
import { Link } from './Link';

const styles = makeStyles({
  li: {
    display: 'list-item',
  },
});

interface MarkdownProps {
  children: string;
  variant?: TypographyClassKey | false;
  link?: React.ComponentProps<typeof Link>['link'];
  color?: React.ComponentProps<typeof Typography>['color'];
  style?: React.CSSProperties;
}

type PrimitiveRecord = Record<string, PrimitiveType>;

const Heading: HeadingComponent = ({ level, ...props }) => {
  const variant = `h${Math.max(3, level)}` as Variant;
  return <Typography variant={variant} {...props} />;
};

export const Markdown: React.FC<MarkdownProps> = React.memo((props) => {
  const intl = useIntl();
  const { children: intlKey, variant, link, style, color, ...rest } = props;
  const { li } = styles();

  return (
    <ReactMarkdown
      components={{
        h1: Heading,
        h2: Heading,
        h3: Heading,
        h4: Heading,
        h5: Heading,
        h6: Heading,
        p: (p) =>
          variant === false ? (
            p.children
          ) : (
            <Typography
              {...p}
              variant={(variant || 'body1') as Variant}
              style={style}
              color={color}
            />
          ),
        a: ({ children, ...p }) => (
          <Link {...p} link={link} children={children} />
        ),
        ul: ({ children, className }) => (
          <List component="ul" className={className} children={children} />
        ),
        ol: ({ children, className }) => (
          <List component="ol" className={className} children={children} />
        ),
        li: ({ children }) => <ListItem className={li} children={children} />,
        em: ({ children, className }) => (
          <Highlight className={className} children={children} />
        ),
      }}
    >
      {intl.formatMessage({ id: intlKey.trim() }, rest as PrimitiveRecord)}
    </ReactMarkdown>
  );
});
