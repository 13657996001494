import TextField from '@material-ui/core/TextField';
import React, { useEffect } from 'react';

type Props = {
  defaultValue?: string;
  loading: boolean;
  onChange: (invoiceReference?: string) => void;
};

export const InvoiceReference: React.FC<Props> = ({
  loading,
  defaultValue,
  onChange,
}) => {
  const [val, setVal] = React.useState(defaultValue);

  useEffect(() => {
    if (val !== defaultValue) {
      const timer = setTimeout(() => {
        onChange(val);
      }, 1000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [val, defaultValue]);

  useEffect(() => {
    setVal(defaultValue);
  }, [defaultValue]);

  return (
    <TextField
      value={val}
      disabled={loading}
      variant="outlined"
      size="small"
      inputProps={{
        maxlength: 64,
        style: { fontSize: '0.8rem', width: '100%' },
      }}
      fullWidth
      onInput={(event) => setVal(event.target.value)}
    />
  );
};
