import React from 'react';
import { createContainer } from 'unstated-next';

const useCTA = () => {
  const [ctaVisible, viewCTA] = React.useState(true);
  const [quizFooterInViewport] = React.useState(false);
  const [landingShown, toggleLanding] = React.useState(false);
  const [checkupShown, toggleCheckup] = React.useState(false);
  const showCTA =
    !ctaVisible && !quizFooterInViewport && (landingShown || checkupShown);

  return {
    ctaVisible,
    quizFooterInViewport,
    viewCTA,
    showCTA,
    toggleLanding,
    checkupShown,
    toggleCheckup,
  };
};

export const CTA = createContainer(useCTA);
