import Bugsnag from '@bugsnag/js';
import useAsync from 'react-use/lib/useAsync';
import { api } from '../utils/api';

export const useBrokers = (shouldFetch: boolean) => {
  const { value } = useAsync(async () => {
    if (!shouldFetch) {
      return [];
    }

    try {
      const brs = await api.adminControllerGetBrokers();
      return brs
        .filter((br) => br.code)
        .sort((a, b) => {
          const codeA: string = a.code;
          const codeB: string = b.code;
          return codeA.localeCompare(codeB);
        });
    } catch (e) {
      console.error(e);
      Bugsnag.notify(e);
    }
  }, [shouldFetch]);

  return value;
};
