import * as React from 'react';

const LazyGroup = React.lazy<typeof import('../pdf/Group').Group>(() =>
  import('../pdf/Group').then((module) => ({ default: module.Group })),
);
export const SuspenseGroup: typeof import('../pdf/Group').Group = (props) => (
  <React.Suspense fallback={''}>
    <LazyGroup {...props} />
  </React.Suspense>
);

SuspenseGroup.page = 'group';
