import Chip from '@material-ui/core/Chip';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Account, Account as AccountModel } from '../swagger';

type Props = { account: Account };

export const FormattedName: React.FC<Props> = React.memo((props) => {
  const { account } = props;
  const name =
    account.sensitive &&
    ((account.type === AccountModel.TypeEnum.Company &&
      account.sensitive.fullName) ||
      account.sensitive.firstName ||
      account.sensitive.fullName);

  return (
    <>
      <FormattedMessage
        id="profile.title"
        values={{
          name,
        }}
      />
      {account.demo && (
        <Chip label="Demo" style={{ marginLeft: 8, fontSize: 12 }} />
      )}
    </>
  );
});
