import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Person, Sensitive } from '../swagger';
import { clearPersonalNumber } from '../utils/clearPersonalNumber';
import { formatPersonalNumber } from '../utils/formatPersonalNumber';
import { personsTranslations } from '../utils/persons';
import { SubmitButton } from './forms/SubmitButton';
import { getStatus, Status } from './InsuranceProductStatus';

type Props = {
  sensitive?: Sensitive;
  person?: Person;
  isBroker?: boolean;
  onEdit?: (person: Person) => void;
  onRemove?: (person: Person) => void;
};

export const InsuranceSensitive: React.FC<Props> = ({
  sensitive,
  person,
  onEdit,
  onRemove,
  children,
}) => {
  const status = person && getStatus(person);
  const required = status !== Status.SUCCESS && status !== Status.PENDING;
  const personnummer =
    clearPersonalNumber(sensitive?.personnummer) ||
    clearPersonalNumber(person?.personnummer) ||
    '';
  const sensitiveTitle = [
    sensitive?.fullName,
    formatPersonalNumber(personnummer),
  ]
    .filter(Boolean)
    .join(', ');

  const contacts = [
    sensitive?.email || person?.email,
    sensitive?.phone || person?.phone,
  ]
    .filter(Boolean)
    .join(', ');
  const shouldAdd =
    !personnummer ||
    (!contacts.length && person?.type !== Person.TypeEnum.Child);
  const label = required ? (shouldAdd ? 'add' : 'quiz.health') : 'edit';

  return (
    <Box display="flex" flexDirection="column" pr={2}>
      <Box flex={1}>
        {person?.type && (
          <Typography
            variant="h6"
            color="textSecondary"
            style={{ margin: '8px 0' }}
          >
            <FormattedMessage id={personsTranslations[person.type]} />
          </Typography>
        )}
        <Typography variant="h5">{sensitiveTitle}</Typography>

        <Typography variant="body2" color="textPrimary">
          {contacts && (
            <>
              {contacts}
              <br />
            </>
          )}
          {sensitive && (
            <>
              {sensitive.streetAddress}
              <br />
              {sensitive.postalCode} {sensitive.locality}
            </>
          )}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <Box flex={1}>
          {onEdit && person && (
            <Box className={required ? 'yellow-marker' : undefined}>
              <SubmitButton
                label={label}
                variant={required ? 'contained' : 'outlined'}
                size="medium"
                color={required ? 'primary' : 'secondary'}
                onClick={() => onEdit(person)}
                endIcon={required ? <ArrowForwardIcon /> : undefined}
              />
            </Box>
          )}
          {children}
        </Box>

        {onRemove && person?.personnummer && (
          <SubmitButton
            key="remove"
            label="remove"
            size="small"
            variant="text"
            color="secondary"
            onClick={() => person && onRemove(person)}
            style={{ alignSelf: 'flex-end' }}
          />
        )}
      </Box>
    </Box>
  );
};
