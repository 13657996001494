import CardContent from '@material-ui/core/CardContent';
import Grow from '@material-ui/core/Grow';
import React from 'react';
import { duration } from '../styles';
import { Person, Product } from '../swagger';
import { breakdownPersons } from '../utils/breakdownPersons';
import { clearPersonalNumber } from '../utils/clearPersonalNumber';
import { InsurancePersonStatus } from './InsurancePersonStatus';
import { InsuranceSensitive } from './InsuranceSensitive';

type Props = {
  persons: Person[];
  products?: Product[];
  setCurrentPerson?: (person: Person) => void;
  removePerson?: (person: Person) => void;
  updatePerson: (person: Person) => void;
  isBroker?: boolean;
};

export const getInsurancePersonSensitive = (
  person: Person,
  products?: Product[],
) =>
  products?.find(
    (pr) =>
      pr.account?.sensitive?.personnummer &&
      person.personnummer &&
      clearPersonalNumber(pr.account.sensitive.personnummer) ===
        clearPersonalNumber(person.personnummer),
  )?.account?.sensitive;

export const InsurancePersons: React.FC<Props> = (props) => {
  const {
    persons,
    products,
    setCurrentPerson,
    removePerson,
    updatePerson,
    isBroker,
  } = props;
  const { main, spouse, children } = breakdownPersons(persons);

  const InsuranceSection: React.FC<{ person: Person }> = React.memo(
    ({ person }) => (
      <Grow in timeout={duration}>
        <CardContent className="insurance-section">
          <InsuranceSensitive
            isBroker={isBroker}
            person={person}
            sensitive={getInsurancePersonSensitive(person, products)}
            onEdit={setCurrentPerson}
            onRemove={removePerson}
          />
          <InsurancePersonStatus
            isBroker={isBroker}
            person={person}
            onUpdate={updatePerson}
          />
        </CardContent>
      </Grow>
    ),
  );

  return (
    <>
      {main && <InsuranceSection person={main} />}

      {spouse && <InsuranceSection person={spouse} />}

      {children.map((child, i) => (
        <InsuranceSection person={child} key={child.quiz?.id || i} />
      ))}
    </>
  );
};
