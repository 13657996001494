import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import useBoolean from 'react-use/lib/useBoolean';
import { useRouter } from '../hooks/useRouter';
import { useRoutes } from '../hooks/useRoutes';
import { Language, Settings } from '../hooks/useSettings';
import amtrust from '../public/amtrust.pdf';
import company from '../public/company.pdf';
import { duration, easing } from '../styles';
import { darkTheme } from '../styles/dark';
import { DocumentDialog } from './dialogs/DocumentDialog';
import { Link } from './Link';
import { Markdown } from './Markdown';

const SmallSelect = withStyles({
  root: {
    fontSize: darkTheme.typography.body2.fontSize,
    color: '#fff',
  },
  icon: {
    color: '#fff',
  },
})(Select);

const styles = makeStyles({
  footer: {
    transition: `all ${duration}ms ${easing}`,

    '.non-matched &': {
      opacity: 0.3,
      pointerEvents: 'none',
    },
  },
});

export const Footer = React.memo(() => {
  const { footer } = styles();
  const { settings, update } = Settings.useContainer();
  const { root, privacy } = useRoutes();
  const { history } = useRouter();
  const upToXS = useMediaQuery(darkTheme.breakpoints.up('sm'));
  const [isPPOpen, togglePPOpen] = useBoolean(false);
  const [isCIOpen, toggleCIOpen] = useBoolean(false);
  const [isDPOpen, toggleDPOpen] = useBoolean(false);

  const changeLanguage = React.useCallback(
    (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
      const newLanguage = event.target.value as Language;
      const newURL = window.location.href.replace(
        `${window.location.protocol}//${window.location.host}/${settings.language}`,
        `/${newLanguage}`,
      );

      update({
        language: newLanguage,
      });
      history.replace(newURL);
    },
    [update, settings.language],
  );

  return (
    <ThemeProvider theme={darkTheme}>
      <Box
        p={!upToXS ? 2 : 3}
        component="footer"
        flex={1}
        display="flex"
        alignItems="flex-end"
        className={footer}
      >
        <Box flex={1} display="flex" alignItems="flex-end" flexWrap="wrap">
          <Box style={{ width: '100%' }}>
            <Markdown variant="body2" children="copyright" />
          </Box>
          <Box flex={1} pr={1}>
            <Typography variant="body2">
              <Link href={company} onClick={toggleCIOpen}>
                <FormattedMessage id="company_info" />
              </Link>
              {' • '}
              <Link href={privacy} onClick={togglePPOpen}>
                <FormattedMessage id="privacy_policy" />
              </Link>
              {' • '}
              <Link href={`${root}/cookies`}>
                <FormattedMessage id="cookies" />
              </Link>
              {' • '}
              <Link href={amtrust} onClick={toggleDPOpen}>
                <FormattedMessage id="data_policy" />
              </Link>
            </Typography>
          </Box>
          <Box>
            <SmallSelect value={settings.language} onChange={changeLanguage}>
              <MenuItem value={Language.English}>English</MenuItem>
              <MenuItem value={Language.Svenska}>Svenska</MenuItem>
            </SmallSelect>
          </Box>
        </Box>
      </Box>
      <DocumentDialog
        title="company_info"
        href={company}
        open={isCIOpen}
        close={toggleCIOpen}
      />
      <DocumentDialog
        title="privacy_policy"
        href={privacy}
        open={isPPOpen}
        close={togglePPOpen}
      />
      <DocumentDialog
        title="data_policy"
        href={amtrust}
        open={isDPOpen}
        close={toggleDPOpen}
      />
    </ThemeProvider>
  );
});
