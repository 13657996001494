import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { DEFAULT_HEALTH_CHECKUP_PAYMENT_PERIOD } from '../constant';
import { Account } from '../hooks/useAccount';
import { currencyFormat } from '../styles';
import { Order, ProductPricing } from '../swagger';
import { getIntervalPricingIntlId } from '../utils/paymentMethod';
import { PricingDetails } from './PricingDetails';

const styles = makeStyles({
  header: { textAlign: 'center', paddingBottom: '16px' },
});

interface PricingFromProps {
  color?: React.ComponentProps<typeof Typography>['color'];
  type?: Order.TypeEnum;
  age?: number;
  paymentPeriod: number;
  pricing: ProductPricing;
  style?: React.CSSProperties;
  skipType?: boolean;
}

const getLowestPrice = ({ pricing, age, type }: PricingFromProps) => {
  const allPrices = Object.entries(pricing)
    .filter(([key]) => !type || key === type)
    .map(([, value]) => value)
    .filter(
      (value): value is Record<string, number> => typeof value === 'object',
    )
    .reduce((acc, priceGroups) => {
      const prices = Object.entries(priceGroups)
        .filter(([group]) => {
          const [, max] = group.split(/\D/).map((v) => parseInt(v, 10));

          return type !== Order.TypeEnum.Single || !age || age <= max;
        })
        .map(([, price]) => price);

      return [...acc, ...prices];
    }, [] as number[]);

  return Math.min(...allPrices);
};

export const PricingFrom: React.FC<PricingFromProps> = (props) => {
  const { basePricing } = Account.useContainer();
  const { paymentPeriod, pricing, style, type, color = 'primary' } = props;
  const intl = useIntl();
  const { header } = styles();
  const lowestPrice = getLowestPrice(props);
  const baseLowestPrice =
    basePricing &&
    getLowestPrice({ ...props, pricing: basePricing.health_plus });

  return (
    <PricingDetails pricing={pricing}>
      <Typography
        variant="body1"
        className={header}
        color={color}
        style={style}
      >
        {props.children}
        {!props.skipType && type && (
          <b>
            <FormattedMessage id={`pricing.${type}`} />
            ,&nbsp;
          </b>
        )}
        <b style={{ whiteSpace: 'nowrap' }}>
          <FormattedMessage
            id={getIntervalPricingIntlId(type, props.paymentPeriod)}
            values={{
              price: intl.formatNumber(
                lowestPrice * props.paymentPeriod,
                currencyFormat,
              ),
            }}
          />
        </b>
        {baseLowestPrice && baseLowestPrice !== lowestPrice && (
          <>
            {' '}
            <span
              style={{
                whiteSpace: 'nowrap',
                opacity: 0.4,
                textDecoration: 'line-through',
              }}
            >
              <FormattedMessage
                id={getIntervalPricingIntlId(type, props.paymentPeriod)}
                values={{
                  price: intl.formatNumber(
                    baseLowestPrice * props.paymentPeriod,
                    currencyFormat,
                  ),
                }}
              />
            </span>
          </>
        )}
      </Typography>
    </PricingDetails>
  );
};

export const HealthCheckupPricingForm: React.FC<{
  type?: Order.TypeEnum;
  paymentPeriod?: number;
  pricing: ProductPricing;
}> = ({
  pricing,
  type,
  paymentPeriod = DEFAULT_HEALTH_CHECKUP_PAYMENT_PERIOD,
}) => {
  const intl = useIntl();
  const priceNumber =
    type && isFinite(paymentPeriod) ? pricing[type][paymentPeriod] : 0;
  const price = intl.formatNumber(priceNumber, currencyFormat);

  return (
    <>
      <p style={{ fontSize: '1.5rem' }}>
        <FormattedMessage
          id={
            paymentPeriod
              ? 'product.health_checkup.annually'
              : 'product.health_checkup.oneTimeOff'
          }
          values={{
            price,
          }}
        />{' '}
        {price}
      </p>
    </>
  );
};
