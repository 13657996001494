import Step from '@material-ui/core/Step';
import StepContent from '@material-ui/core/StepContent';
import { StepIconProps } from '@material-ui/core/StepIcon';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import CheckCircle from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import UpdateIcon from '@material-ui/icons/Update';
import { DateTimeFormatOptions, LocaleOptions } from 'luxon';
import React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { Settings } from '../hooks/useSettings';
import { currencyFormat, dateFormat } from '../styles';
import { theme } from '../styles/theme';
import { PaymentTransaction } from '../swagger';
import { toDateTime } from '../utils/toDateTime';
import { detailsStyles } from './dialogs/Details';
import { FormattedDuration } from './FormattedDuration';

interface TransactionProps {
  transaction: PaymentTransaction;
  onClick?: false | (() => void);
  detailed?: boolean;
  index: number;
  last: boolean;
}

export const Transaction: React.FC<TransactionProps> = ({
  transaction,
  onClick,
  detailed = false,
  index,
  last,
  children,
}) => {
  const { underscoreError, underscore } = detailsStyles();
  const { settings } = Settings.useContainer();
  const paidFrom = toDateTime(transaction.paidFrom);
  const paidUntil = toDateTime(transaction.paidUntil);
  const isFailed = transaction.status === PaymentTransaction.StatusEnum.Failure;
  const isPending =
    transaction.status === PaymentTransaction.StatusEnum.Pending;
  const format: LocaleOptions & DateTimeFormatOptions = {
    ...dateFormat,
    locale: settings.language,
  };
  if (detailed) {
    format.hour = 'numeric';
    format.minute = 'numeric';
  }

  const date = toDateTime(transaction.paid).toLocaleString(format);

  const getIcon = (): Partial<StepIconProps> | undefined => {
    if (isPending) {
      return {
        error: isFailed,
        icon: (
          <UpdateIcon fontSize="small" htmlColor={theme.palette.warning.main} />
        ),
      };
    }

    if (isFailed) {
      return {
        error: isFailed,
        icon: (
          <ErrorIcon fontSize="small" htmlColor={theme.palette.error.main} />
        ),
      };
    }

    if (transaction.id) {
      return {
        error: isFailed,
        icon: (
          <CheckCircle
            fontSize="small"
            htmlColor={theme.palette.success.main}
          />
        ),
      };
    }
  };

  return (
    <Step index={index} active last={last} completed={!isFailed && detailed}>
      <StepLabel error={isFailed} StepIconProps={getIcon()}>
        {!onClick ? (
          date
        ) : (
          <span
            onClick={onClick}
            className={isFailed ? underscoreError : underscore}
          >
            {date}
          </span>
        )}
      </StepLabel>
      <StepContent>
        <Typography variant="body2" style={{ marginBottom: 0 }}>
          <FormattedNumber value={transaction.amount} {...currencyFormat} />{' '}
          <FormattedMessage id="amount_for_duration" />{' '}
          <FormattedDuration from={paidFrom} to={paidUntil} />
        </Typography>
        {children}
      </StepContent>
    </Step>
  );
};
