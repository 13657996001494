import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import useTimeout from 'react-use/lib/useTimeout';
import { Account } from '../hooks/useAccount';
import { Globe } from '../icons/Globe';
import { Heart } from '../icons/Heart';
import { currencyFormat, duration, gradient } from '../styles';
import { Order, Product } from '../swagger';
import { isOrderHealthCheckup } from '../utils/isHealthCheckup';
import { AddPaymentMethodButton } from './forms/AddPaymentMethodButton';

const styles = makeStyles({
  active: {
    borderRadius: 0,
    background: gradient,
    color: 'white',
  },
  inactive: {
    borderRadius: 0,
    backgroundColor: 'rgba(0,46,92,0.10)',
    filter: 'saturate(0)',
  },
});

export const OrderActions: React.FC<{ order: Order }> = ({ order }) => {
  const intl = useIntl();
  const { active, inactive } = styles();
  const isHealthCheckup = isOrderHealthCheckup(order);
  const AnimatedIcon = isHealthCheckup ? Heart : Globe;
  const showAnimatedIcon = useTimeout(duration);
  const { account } = Account.useContainer();
  const payer = account?.id !== order.payer?.id ? order.payer : undefined;
  const hasProductsToPay = !order.products.every(
    (pr) => pr.status === Product.StatusEnum.Cancelled,
  );
  const isActive = order.type && hasProductsToPay;

  return (
    <Box
      className={isActive ? active : inactive}
      p={2}
      borderRadius={4}
      position="relative"
      overflow="hidden"
      display="flex"
      alignItems="center"
    >
      <Box position="absolute" right="-10%" top="30%" height="70%" width="40%">
        {showAnimatedIcon && <AnimatedIcon loop={false} />}
      </Box>
      <Box flex={1} pr={2}>
        <Typography variant="h4" style={{ marginBottom: '4px' }}>
          {!isHealthCheckup && order.type && (
            <FormattedMessage id={`pricing.${order.type}`} />
          )}
          {!isHealthCheckup && !order.type && (
            <FormattedMessage id="order.title" />
          )}
          {isHealthCheckup && <FormattedMessage id="checkup.heading" />}
        </Typography>
        {!!order.price && isActive && (
          <Typography
            variant="body2"
            style={{
              marginBottom: 0,
              whiteSpace: 'nowrap',
              color: 'inherit',
            }}
          >
            {isHealthCheckup && !order.paymentPeriod && (
              <p>{intl.formatNumber(order.price, currencyFormat)} </p>
            )}
            {!isHealthCheckup && (
              <FormattedMessage
                id="per_month"
                values={{
                  price: intl.formatNumber(order.price, currencyFormat),
                }}
              />
            )}
          </Typography>
        )}
        {payer && (
          <Typography
            variant="body2"
            style={{ marginBottom: 0, whiteSpace: 'nowrap', color: 'inherit' }}
          >
            <FormattedMessage id="paid_by" />{' '}
            {payer.sensitive?.fullName ||
              payer.sensitive?.personnummer ||
              payer.id}
          </Typography>
        )}
      </Box>
      <Box>{order.type && hasProductsToPay && <AddPaymentMethodButton />}</Box>
    </Box>
  );
};
