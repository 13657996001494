import { DateTime } from 'luxon';
import { Account, PaymentTransaction, Product } from '../swagger';
import { toDateTime } from './toDateTime';

type Period = [DateTime | undefined, DateTime | undefined];

const getValidTransactions = (
  transactions: PaymentTransaction[],
): PaymentTransaction[] =>
  transactions.filter(
    (tr) => !!tr.id && tr.status !== PaymentTransaction.StatusEnum.Failure,
  );

export const getCurrentPeriod = (product: Product): Period => {
  const transactions = getValidTransactions(product.transactions);

  if (!transactions.length && product.transactions[0]) {
    transactions.push(product.transactions[0]);
  }

  const minPaidFrom = [...transactions]
    .map((tr) => toDateTime(tr.paidFrom))
    .sort((a, b) => a.toISO().localeCompare(b.toISO()))
    .shift();

  const maxPaidUntil = [...transactions]
    .map((tr) => toDateTime(tr.paidUntil))
    .sort((a, b) => a.toISO().localeCompare(b.toISO()))
    .pop();

  return [minPaidFrom, maxPaidUntil];
};

export const getNextPeriod = (product: Product): Period => {
  const hasTransactions = !!getValidTransactions(product.transactions).length;
  const nextTransactions = product.transactions.filter((tr) => !tr.id);
  const transaction = nextTransactions[hasTransactions ? 0 : 1];

  return [
    transaction && toDateTime(transaction.paidFrom),
    transaction && toDateTime(transaction.paidUntil),
  ];
};

export const byPaidUntil = (a: Product, b: Product): number => {
  const [, aPaidUntil] = getCurrentPeriod(a);
  const [, bPaidUntil] = getCurrentPeriod(b);

  if (!aPaidUntil) {
    return -1;
  }
  if (!bPaidUntil) {
    return 1;
  }
  return bPaidUntil.toMillis() - aPaidUntil.toMillis();
};

export const shiftMainProductFirst = (
  products: Product[],
  account: Account,
): Product[] => {
  const accountProduct = products.find(
    (pr) => pr.account && pr.account.id === account.id,
  );

  if (accountProduct) {
    products.splice(products.indexOf(accountProduct), 1);
    products.unshift(accountProduct);
  }

  return products;
};
