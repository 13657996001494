import React from 'react';
import { Login } from '../../hooks/useLogin';
import { useRoutes } from '../../hooks/useRoutes';
import bankidLogo from '../../public/bankid.svg';
import { LinkButton } from './SubmitButton';

export const LoginButton: React.FC<{ loading: boolean }> = ({ loading }) => {
  const { toggleAuth } = Login.useContainer();
  const { mina } = useRoutes();

  return (
    <LinkButton
      loading={loading}
      label="sign_in"
      to={mina}
      endIcon={
        !loading ? (
          <img
            src={bankidLogo}
            alt="BankID"
            width={24}
            height={24}
            style={{ marginLeft: '8px' }}
          />
        ) : undefined
      }
      variant={!loading ? 'outlined' : 'text'}
      color="primary"
      onClick={(e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        toggleAuth(true);
      }}
      style={{ marginRight: 0 }}
    />
  );
};
