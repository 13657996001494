import React from 'react';
import { useIntl } from 'react-intl';
import unikaLogo from '../public/unika_logo_white.svg';

export const Logo: React.FC = () => {
  const { formatMessage } = useIntl();

  return (
    <img
      src={unikaLogo}
      alt={formatMessage({ id: 'unika.name' })}
      style={{
        display: 'block',
        height: '48px',
        border: '0 none',
      }}
    />
  );
};
