import Box from '@material-ui/core/Box';
import * as React from 'react';
import { Account } from '../hooks/useAccount';
import { Quiz } from '../hooks/useQuiz';
import { Order, Person, Product, Quiz as QuizModel } from '../swagger';
import { api } from '../utils/api';
import { clearPersonalNumber } from '../utils/clearPersonalNumber';
import { AddPerson } from './AddPerson';
import { InsuranceQuizDialog } from './dialogs/InsuranceQuizDialog';

export const InsureSpouse: React.FC<{
  order: Order;
}> = ({ order }) => {
  const [open, setOpen] = React.useState(false);
  const [person, setPerson] = React.useState<Person>();
  const { refreshAccount } = Account.useContainer();
  const { saveOrCreateQuiz } = Quiz.useContainer();
  const [loading, setLoading] = React.useState(false);

  const createSpouse = React.useCallback(async () => {
    if (!person) {
      setLoading(true);
      setPerson({
        type: Person.TypeEnum.Spouse,
      });
      setLoading(false);
    }
    setOpen(true);
  }, [saveOrCreateQuiz, person]);

  const removeSpouse = React.useCallback(() => {
    setPerson(undefined);
    setOpen(false);
  }, []);

  const addSpouse = React.useCallback(
    async (prs: Person) => {
      setLoading(true);
      if (clearPersonalNumber(prs.personnummer) && prs.email) {
        api
          .orderControllerAddPerson(order.id, {
            ...prs,
            quiz: await saveOrCreateQuiz({} as QuizModel),
          })
          .then(refreshAccount)
          .finally(() => {
            removeSpouse();
            setLoading(false);
          });
      }
    },
    [order.id, refreshAccount, saveOrCreateQuiz],
  );

  if (
    order.products.some(
      (product) => product.status === Product.StatusEnum.Pending,
    )
  ) {
    return null;
  }

  return (
    <Box pt={2} px={4}>
      <AddPerson
        type={Person.TypeEnum.Spouse}
        order={order}
        onClick={createSpouse}
        loading={loading}
      />
      <InsuranceQuizDialog
        label="submit"
        person={open ? person : undefined}
        onClose={removeSpouse}
        onSave={addSpouse}
      />
    </Box>
  );
};
