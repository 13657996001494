import * as React from 'react';

const LazyBrokerAgreement = React.lazy<
  typeof import('../pdf/BrokerAgreement').BrokerAgreement
>(() =>
  import('../pdf/BrokerAgreement').then((module) => ({
    default: module.BrokerAgreement,
  })),
);
export const SuspenseBrokerAgreement: typeof import('../pdf/BrokerAgreement').BrokerAgreement =
  (props) => (
    <React.Suspense fallback={''}>
      <LazyBrokerAgreement {...props} />
    </React.Suspense>
  );

SuspenseBrokerAgreement.page = 'brokerAgreement';
