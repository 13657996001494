import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Alert from '@material-ui/lab/Alert';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { PaymentMethods } from '../hooks/usePaymentMethods';
import { Order, PaymentMethod, PaymentPayload, Person } from '../swagger';
import { InfoDialog } from './dialogs/InfoDialog';
import { AddPaymentMethodButton } from './forms/AddPaymentMethodButton';
import { InsuranceSensitive } from './InsuranceSensitive';
import { Markdown } from './Markdown';

type Props = {
  order?: Order;
  pendingMethod?: PaymentPayload;
  setPendingMethod: (pm: PaymentPayload) => void;
  isBroker?: boolean;
  mainPerson?: Person;
};

export const InsurancePayment: React.FC<Props> = React.memo((props) => {
  const { order, pendingMethod, setPendingMethod, isBroker } = props;
  const { hasPaymentMethod } = PaymentMethods.useContainer();
  const pendingPaymentMethod: PaymentMethod | undefined =
    typeof pendingMethod?.manual === 'boolean'
      ? {
          id: '',
          created: new Date(),
          updated: new Date(),
          gateway: PaymentMethod.GatewayEnum.Billecta,
          method: pendingMethod.bank || '',
          methodId: [pendingMethod.clearing, pendingMethod.account]
            .filter(Boolean)
            .join(' '),
          status: PaymentMethod.StatusEnum.Active,
          transactions: [],
        }
      : undefined;
  const [error, setError] = React.useState('');
  const canPayInsurance =
    hasPaymentMethod ||
    !!pendingPaymentMethod ||
    (isBroker && !!pendingMethod?.personnummer);
  const paymentMethodPrefix = (() => {
    if (pendingMethod?.manual === true) return 'invoice';
    if (pendingMethod?.manual === false) return 'autogiro';
    if (isBroker) return 'omit_payment_method';
  })();

  return (
    <CardContent className="insurance-section">
      <Box>
        <InsuranceSensitive
          isBroker={isBroker}
          sensitive={canPayInsurance ? order?.payer?.sensitive : undefined}
        >
          <Box className={!canPayInsurance ? 'yellow-marker' : undefined}>
            <AddPaymentMethodButton
              label={canPayInsurance ? 'edit_payment' : 'add_payment'}
              signup
              force
              hideBadge
              size="medium"
              isBroker={isBroker}
              disabled={!order?.type}
              onSubmit={setPendingMethod}
              value={pendingMethod}
              variant={canPayInsurance ? 'outlined' : 'contained'}
              color={canPayInsurance ? 'secondary' : 'primary'}
              endIcon={canPayInsurance ? undefined : <ArrowForwardIcon />}
              mainPerson={props.mainPerson}
            />
          </Box>
        </InsuranceSensitive>
      </Box>

      {paymentMethodPrefix && (
        <Box display="flex">
          <Alert severity="success" style={{ flex: 1 }}>
            <Typography variant="h6">
              <FormattedMessage id={paymentMethodPrefix} />
            </Typography>
            <Markdown
              children={`${paymentMethodPrefix}.details`}
              variant="body2"
              color="inherit"
              {...pendingMethod}
            />
          </Alert>
        </Box>
      )}

      <InfoDialog isOpen={!!error} onClose={() => setError('')}>
        <Markdown
          children="payment_method_failure"
          variant="body1"
          color="textPrimary"
          style={{ textAlign: 'center' }}
        />
      </InfoDialog>
    </CardContent>
  );
});
