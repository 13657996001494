import Typography from '@material-ui/core/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Product } from '../swagger';

export const ProductID: React.FC<{ product: Product }> = ({ product }) => (
  <Typography
    variant="body2"
    style={{ textTransform: 'none', marginBottom: 0 }}
  >
    <FormattedMessage id="agreement" /> {product.id}
  </Typography>
);
