import * as React from 'react';
import { useRouter } from '../../hooks/useRouter';
import { api } from '../../utils/api';
import { LinkButton } from './SubmitButton';

interface OpenAgreementButtonProps {
  accountId: string;
  productId: string;
}

export const OpenAgreementButton: React.FC<OpenAgreementButtonProps> =
  React.memo(({ accountId, productId }) => {
    const {
      match: { url },
    } = useRouter();
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
      let timer: number | undefined;

      const checkIfExists = async () => {
        try {
          await api.documentControllerCheckAgreement(accountId, productId);
          setLoading(false);
        } catch (e) {
          if (e && e.status === 404) {
            timer = setTimeout(checkIfExists, 1000) as unknown as number;
          } else {
            setLoading(false);
          }
        }
      };

      checkIfExists();

      return () => {
        clearTimeout(timer);
      };
    }, [productId, accountId]);

    return (
      <LinkButton
        to={`${url}/docs-${productId}`.replace('//', '/')}
        size="small"
        color="primary"
        variant="outlined"
        loading={loading}
        label="documents.cta"
      />
    );
  });
