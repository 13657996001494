import * as React from 'react';
import useAsync from 'react-use/lib/useAsync';
import useTimeout from 'react-use/lib/useTimeout';

const GrowLottie = React.lazy(() => import('./GrowLottie'));

interface GlobeProps {
  loop?: boolean;
  color?: boolean;
}

export const Globe: React.FC<GlobeProps> = React.memo((props) => {
  const { loop = true, color = false } = props;
  const [showGlobe] = useTimeout(100);
  const animationData = useAsync(() =>
    color
      ? import('../../lordicon/icons/27-globe/27-globe-outline-unika.json')
      : import('../../lordicon/icons/27-globe/27-globe-outline-white.json'),
  );

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        overflow: 'hidden',
      }}
    >
      {!animationData.loading && (
        <div style={{ height: '160%', transform: 'rotate(10deg)' }}>
          <React.Suspense fallback={<></>}>
            <GrowLottie
              show={!!showGlobe}
              options={{
                animationData: animationData.value,
                loop,
              }}
              speed={0.5}
              isClickToPauseDisabled
            />
          </React.Suspense>
        </div>
      )}
    </div>
  );
});
