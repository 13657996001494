import Box from '@material-ui/core/Box';
import Button, { ButtonProps } from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fade from '@material-ui/core/Fade';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';

interface BaseProps {
  loading?: boolean;
  label: string;
}

type SubmitButtonProps = ButtonProps<'button', BaseProps>;

type LinkButtonProps = ButtonProps<any, BaseProps> & { to?: string };

const defaultProps: Pick<
  SubmitButtonProps,
  'type' | 'color' | 'variant' | 'size'
> = {
  type: 'submit',
  color: 'primary',
  variant: 'contained',
  size: 'large',
};

const ButtonContent: React.FC<BaseProps & { left?: boolean }> = ({
  loading,
  label,
  left,
  children,
}) => (
  <>
    <Fade in={!loading}>
      <Box style={{ textAlign: 'center' }}>
        {children}
        {label && <FormattedMessage id={label} />}
      </Box>
    </Fade>
    {loading && (
      <Fade in={loading}>
        <Box
          position={'absolute'}
          height={'100%'}
          display={'flex'}
          alignItems={'center'}
          left={left && 4}
        >
          <CircularProgress color="inherit" size={24} />
        </Box>
      </Fade>
    )}
  </>
);

export const SubmitButton: React.FC<SubmitButtonProps> = React.memo(
  ({ label, loading, ...rest }) => (
    <Button {...defaultProps} disabled={loading || rest.disabled} {...rest}>
      <ButtonContent label={label} loading={loading} />
    </Button>
  ),
);

export const LinkButton: React.FC<LinkButtonProps> = React.memo(
  ({ label, loading, to, children, ...rest }) => {
    const props = {
      ...defaultProps,
      disabled: loading || rest.disabled,
      ...rest,
    };
    const content = (
      <ButtonContent label={label} loading={loading} left>
        {children}
      </ButtonContent>
    );

    if (rest.target || !to) {
      return (
        <Button href={to} {...props} style={{ position: 'relative' }}>
          {content}
        </Button>
      );
    }

    return (
      <Button
        to={to}
        component={RouterLink}
        {...props}
        style={{ position: 'relative' }}
      >
        {content}
      </Button>
    );
  },
);
