import Dialog from '@material-ui/core/Dialog';
import { DialogProps } from '@material-ui/core/Dialog/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { WithStyles } from '@material-ui/styles/withStyles';
import * as React from 'react';
import { yellow } from '../../styles';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(3, 3, 1, 3),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    },
    closeButton: {
      marginRight: '-16px',
      marginTop: '-12px',
      color: yellow,
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h4">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon color="secondary" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export interface InfoDialogProps {
  title?: string | JSX.Element;
  isOpen: boolean;
  onClose: () => void;
  maxWidth?: DialogProps['maxWidth'];
  actions?: JSX.Element | boolean;
  noMargins?: boolean;
  rich?: boolean;
}

export const InfoDialog: React.FC<InfoDialogProps> = ({
  title,
  children,
  isOpen,
  onClose,
  maxWidth,
  actions,
  noMargins = false,
  rich = false,
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth={maxWidth}
      aria-labelledby="info-dialog-title"
      aria-describedby="info-dialog-description"
    >
      {title && (
        <DialogTitle id="info-dialog-title" onClose={onClose}>
          {title}
        </DialogTitle>
      )}
      {noMargins ? (
        children
      ) : (
        <DialogContent>
          {rich && children}
          {!rich && (
            <DialogContentText id="info-dialog-description" component="div">
              {children}
            </DialogContentText>
          )}
        </DialogContent>
      )}
      {actions && <DialogActions>{actions}</DialogActions>}
    </Dialog>
  );
};
