import makeStyles from '@material-ui/core/styles/makeStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Account } from '../hooks/useAccount';
import { currencyFormat } from '../styles';
import { theme } from '../styles/theme';
import { Order } from '../swagger';
import { isOrderHealthCheckup } from '../utils/isHealthCheckup';
import { getIntervalPricingIntlId } from '../utils/paymentMethod';
import { OrderPaymentMethod } from './OrderPaymentMethod';
import { PricingDetails } from './PricingDetails';
import { Transactions } from './Transactions';

const styles = makeStyles({
  header: {
    backgroundColor: theme.palette.secondary.light,
  },
  nowrap: {
    whiteSpace: 'nowrap',
  },
});

export const OrderPayment: React.FC<{ order: Order }> = ({ order }) => {
  const intl = useIntl();
  const { nowrap } = styles();

  const pastTransactions = order.transactions.filter((tr) => !!tr.id);
  const futureTransactions = order.transactions.filter((tr) => !tr.id);
  const { account } = Account.useContainer();
  const payer = account?.id !== order.payer?.id ? order.payer : undefined;
  const isHealthCheckup = isOrderHealthCheckup(order);

  return (
    <Table style={{ marginBottom: 0 }}>
      <TableBody>
        {!isHealthCheckup && order.type && order.pricing && (
          <TableRow>
            <TableCell className={nowrap}>
              <FormattedMessage id="plan" />
            </TableCell>
            <TableCell>
              <PricingDetails pricing={order.pricing}>
                <Typography variant="h6">
                  <FormattedMessage id={`pricing.${order.type}`} />
                </Typography>
              </PricingDetails>
            </TableCell>
          </TableRow>
        )}
        {order.price && (
          <>
            <TableRow>
              <TableCell className={nowrap}>
                <FormattedMessage id="price" />
              </TableCell>
              <TableCell>
                {isHealthCheckup && order.paymentPeriod === 0 ? (
                  intl.formatNumber(order.price, currencyFormat)
                ) : (
                  <FormattedMessage
                    id={getIntervalPricingIntlId(
                      order.type,
                      order.paymentPeriod || 1,
                    )}
                    values={{
                      price: intl.formatNumber(
                        order.price *
                          (isHealthCheckup ? 1 : order.paymentPeriod || 1),
                        currencyFormat,
                      ),
                    }}
                  />
                )}
              </TableCell>
            </TableRow>
            <OrderPaymentMethod order={order} className={nowrap} />
          </>
        )}
        {payer && (
          <>
            <TableRow>
              <TableCell className={nowrap}>
                <FormattedMessage id="paid_by" />
              </TableCell>
              <TableCell>
                {payer.sensitive?.fullName ||
                  payer.sensitive?.personnummer ||
                  payer.id}
              </TableCell>
            </TableRow>
            <OrderPaymentMethod order={order} className={nowrap} />
          </>
        )}
        {!!pastTransactions.length && (
          <TableRow>
            <TableCell>
              <FormattedMessage id="previous_payments" />
            </TableCell>
            <TableCell>
              <Transactions transactions={pastTransactions} collapse detailed />
            </TableCell>
          </TableRow>
        )}
        {order.price && !!futureTransactions.length && (
          <TableRow>
            <TableCell>
              <FormattedMessage id="upcoming_payments" />
            </TableCell>
            <TableCell>
              <Typography variant="body2">
                <FormattedMessage id="upcoming_payments.text" />
              </Typography>
              <Transactions transactions={futureTransactions} />
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};
