import * as React from 'react';

const LazyBrokerCoinsured = React.lazy<
  typeof import('../pdf/BrokerCoinsured').BrokerCoinsured
>(() =>
  import('../pdf/BrokerCoinsured').then((module) => ({
    default: module.BrokerCoinsured,
  })),
);
export const SuspenseBrokerCoinsured: typeof import('../pdf/BrokerCoinsured').BrokerCoinsured =
  (props) => (
    <React.Suspense fallback={''}>
      <LazyBrokerCoinsured {...props} />
    </React.Suspense>
  );

SuspenseBrokerCoinsured.page = 'brokercoinsured';
