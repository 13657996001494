import React from 'react';
import { Account } from '../hooks/useAccount';
import { useRoutes } from '../hooks/useRoutes';
import { Person } from '../swagger';
import { api } from '../utils/api';
import { LinkButton } from './forms/SubmitButton';
import { InsuranceProductStatus } from './InsuranceProductStatus';
import { OrderProductCancelButton } from './OrderProductCancelButton';
import { OrderProductCopyButton } from './OrderProductCopyButton';
import { OrderProductInviteButton } from './OrderProductInviteButton';

type Props = {
  person: Person;
  isBroker?: boolean;
  isOrder?: boolean;
  shouldRefreshQuiz?: boolean;
  productId?: string;
  onUpdate: (person: Person) => void;
};

export const InsurancePersonStatus: React.FC<Props> = (props) => {
  const {
    person,
    isBroker,
    isOrder,
    shouldRefreshQuiz,
    onUpdate,
    children,
    productId,
  } = props;
  const { account, by } = Account.useContainer();
  const { quiz: quizRoute } = useRoutes();
  const isOwnProduct = account?.sensitive?.personnummer === person.personnummer;
  const quizLink = person.quiz?.id && `${quizRoute}/${person.quiz.id}`;

  React.useEffect(() => {
    let quizId = person?.quiz?.id;

    if (
      !shouldRefreshQuiz &&
      (isBroker ||
        person.type !== Person.TypeEnum.Spouse ||
        typeof person?.quiz?.age === 'number')
    ) {
      return;
    }

    const timer = setInterval(async () => {
      if (quizId) {
        const quiz = await api.quizControllerGet(quizId);

        if (person && JSON.stringify(person.quiz) !== JSON.stringify(quiz)) {
          person.quiz = quiz;
          onUpdate(person);
        }
      }
    }, 5000);

    return () => {
      clearInterval(timer);
      quizId = undefined;
    };
  }, [
    person,
    person?.quiz?.id,
    person?.quiz?.age,
    isBroker,
    shouldRefreshQuiz,
    onUpdate,
  ]);

  return (
    <InsuranceProductStatus
      person={person}
      isBroker={isBroker}
      isOrder={isOrder}
    >
      {() => (
        <>
          {children}
          {isOrder && (
            <>
              {isOwnProduct && quizLink && (
                <LinkButton
                  size="small"
                  variant="contained"
                  label="quiz.health"
                  to={quizLink}
                />
              )}
              {!isOwnProduct && <OrderProductInviteButton person={person} />}
              {!!by && quizLink && (
                <OrderProductCopyButton
                  link={`${location.origin}${quizLink}`}
                />
              )}
              {productId && (
                <OrderProductCancelButton label="remove" id={productId} />
              )}
            </>
          )}
        </>
      )}
    </InsuranceProductStatus>
  );
};
