import { Order, PaymentMethod } from '../swagger';
import GatewayEnum = PaymentMethod.GatewayEnum;

export const formatName = (paymentMethod: PaymentMethod): string => {
  const parts: string[] = [];

  parts.push(paymentMethod.method || '');
  parts.push(' ');

  if (paymentMethod.gateway === GatewayEnum.Trustly && paymentMethod.methodId) {
    parts.push('*');
  }

  parts.push(paymentMethod.methodId || '');

  return parts.join('');
};

export const getIntervalPricingIntlId = (
  type: Order.TypeEnum | undefined,
  paymentPeriod: number,
) => {
  if (type) {
    switch (paymentPeriod) {
      case 1:
        return 'per_month';
      case 3:
        return 'per_quarter';
      case 12:
        return 'per_year';
      default:
        return 'price_from';
    }
  }

  return 'price_from';
};
