import { DateTime } from 'luxon';
import { personalNumberToDate } from './personalNumberToDate';

export const personalNumberToAge = (
  input: string,
  now = DateTime.utc(),
): number | undefined => {
  const birthdate = personalNumberToDate(input);

  if (birthdate && birthdate.isValid) {
    return Math.abs(birthdate.diff(now, 'year').years);
  }
};
