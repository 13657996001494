import Container from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';
import { theme } from '../styles/theme';

const styles = makeStyles({
  container: {
    padding: '32px 16px',

    [theme.breakpoints.down('xs')]: {
      padding: '8px 4px',
    },

    '&.text h5': {
      color: theme.palette.primary.main,
      lineHeight: '150%',
      marginTop: '24px',
    },
  },
  paper: {
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    padding: '24px',

    [theme.breakpoints.down('xs')]: {
      padding: '16px',
    },
  },
});

export const Content: React.FC<{
  text?: boolean;
  className?: string;
}> = React.memo(({ text, children, className = '' }) => {
  const { container, paper } = styles();

  return (
    <Container
      className={`${container} ${text ? 'text' : ''} ${className}`}
      maxWidth="md"
    >
      <LinearProgress color="secondary" value={100} variant="determinate" />
      <Paper className={paper}>{children}</Paper>
    </Container>
  );
});
