import Button from '@material-ui/core/Button';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useRouter } from '../../hooks/useRouter';
import { useRoutes } from '../../hooks/useRoutes';
import { Settings } from '../../hooks/useSettings';

export const LogoutButton: React.FC<
  Partial<React.ComponentProps<typeof Button>>
> = ({ ...props }) => {
  const { update } = Settings.useContainer();
  const { history } = useRouter();
  const { root, mina } = useRoutes();
  const logout = React.useCallback(() => {
    if (history.location.pathname.startsWith(mina)) {
      history.replace(root);
    }
    update({ token: '' });
  }, [update, mina, history.location.pathname]);

  return (
    <Button
      color="secondary"
      variant="outlined"
      size="small"
      onClick={logout}
      {...props}
    >
      <FormattedMessage id="sign_out" />
    </Button>
  );
};
