import { DateTime } from 'luxon';

export const toDateTime = (input: Date | string, local = false): DateTime => {
  const options = local ? undefined : { zone: 'utc' };

  if (typeof input === 'string') {
    return DateTime.fromISO(input, options);
  }

  return DateTime.fromJSDate(input, options);
};
