import { CardProps } from '@material-ui/core/Card';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { FormatDateOptions, FormatNumberOptions } from 'react-intl';
import 'typeface-domine';
import 'typeface-lato';

export const Lato = [
  'Lato',
  '-apple-system',
  'BlinkMacSystemFont',
  'Roboto',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
].join(', ');

export const Domine = [
  'Domine',
  '"Palatino Linotype"',
  '"Book Antiqua"',
  'Palatino',
  'serif',
].join(', ');

export const blue = '#002E5C';
export const yellow = '#FEC20F';

export const easing = 'ease-out';
export const duration = 400;

export const shadow =
  'rgba(0, 0, 0, 0.1) 0px 0px 2px, rgba(0, 0, 0, 0.1) 0px 2px 4px, rgba(0, 0, 0, 0.1) 0px 4px 8px';
export const gradient = 'linear-gradient(90deg, #002E5C, #006cd8)';

export const useStyles = makeStyles(() =>
  createStyles({
    '@keyframes blinking': {
      '0%': {
        opacity: 0,
        transform: 'scaleX(0)',
      },
      '50%': {
        opacity: 1,
        transform: 'scaleX(1)',
      },
      '75%': {
        opacity: 0.5,
      },
      '100%': {
        opacity: 1,
      },
    },
    '@keyframes pulsing': {
      '0%': {
        filter: 'brightness(0.5)',
      },
      '50%': {
        filter: 'brightness(1)',
      },
      '75%': {
        filter: 'brightness(0.75)',
      },
      '100%': {
        filter: 'brightness(2) saturation(1.5)',
      },
    },
    '@global': {
      html: {
        height: '100%',
        padding: 0,
        margin: 0,
      },
      body: {
        fontFamily: Lato,
        background: '#fff',
        height: '100%',
        padding: 0,
        margin: 0,
        WebkitFontSmoothing: 'antialiased',
        MozOsxFontSmoothing: 'grayscale',
      },
      input: {
        '&[type="number"]': {
          MozAppearance: 'textfield',
        },
        '&[type="number"]::-webkit-inner-spin-button, &[type="number"]::-webkit-outer-spin-button':
          {
            WebkitAppearance: 'none',
            margin: 0,
          },
      },
      main: {
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'row',
      },
      '::-webkit-scrollbar': {
        WebkitAppearance: 'none',
        width: '12px',
        borderRadius: '0px',
        background: '#002e5c',
      },
      '::-webkit-scrollbar-thumb': {
        borderRadius: '0px',
        backgroundColor: yellow,
      },
      '.yellow-marker': {
        position: 'relative',

        '.insurance-section &': {
          marginLeft: '-16px',
          paddingLeft: '16px',
        },

        '&::before': {
          content: '""',
          position: 'absolute',
          left: 0,
          top: '50%',
          width: '14px',
          height: '16px',
          marginTop: '-8px',
          background: '#FEC20F',
          opacity: 0.5,
          clipPath: 'polygon(0% 0%, 66% 0%, 100% 50%, 66% 100%, 0% 100%);',
          transformOrigin: 'left center',
          animation: '$blinking 1500ms alternate infinite',
        },

        '&.marker-top::before': {
          top: '1em',
        },
      },
      '.MuiBadge-colorError': {
        animation: '$pulsing 1500ms alternate infinite',
      },
    },
  }),
);

export const currencyFormat: FormatNumberOptions = {
  style: 'currency',
  currency: 'SEK',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
};

export const dateFormat: FormatDateOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};

export const inactiveCard: Partial<CardProps> = {
  elevation: 0,
  style: { background: 'rgba(0,46,92,0.10)' },
};
