import React from 'react';
import { createContainer } from 'unstated-next';

const useDrawer = () => {
  const [drawerOpen, toggleDrawer] = React.useState(false);

  return {
    drawerOpen,
    toggleDrawer,
  };
};

export const DrawerContainer = createContainer(useDrawer);
