import MaterialLink from '@material-ui/core/Link';
import * as React from 'react';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useRouter } from '../hooks/useRouter';
import { useRoutes } from '../hooks/useRoutes';
import { getPath } from '../utils/url';
import { DocumentDialog } from './dialogs/DocumentDialog';

interface LinkProps {
  href?: string;
  link?: 'auto' | 'simple';
  onClick?: () => void;
  style?: React.AnchorHTMLAttributes<HTMLAnchorElement>['style'];
}

const SmartLink: React.FC<LinkProps> = (props) => {
  const { children, href = '', link = 'auto', style, onClick, ...rest } = props;
  const { location } = useRouter();
  const { root, ...otherRoutes } = useRoutes();
  const [openDocument, toggleDocument] = useState<boolean | undefined>(
    typeof onClick === 'undefined' ? undefined : false,
  );

  const currentURL = getPath(location.pathname);
  const linkURL =
    otherRoutes[href as keyof typeof otherRoutes] || getPath(href || '.');
  const isActive =
    href === root ? currentURL === linkURL : currentURL.startsWith(linkURL);
  const isPDF = linkURL.endsWith('.pdf');

  const smartOnClick = React.useCallback(
    (e: React.MouseEvent) => {
      if (!href || isPDF) {
        e.preventDefault();
      }

      if (onClick) {
        onClick();
      } else if (isPDF) {
        toggleDocument(true);
      }
    },
    [href, isPDF],
  );

  const closeDocument = React.useCallback(() => toggleDocument(false), []);

  return (
    <>
      <MaterialLink
        to={linkURL}
        onClick={smartOnClick}
        component={RouterLink}
        className={isActive ? 'active' : undefined}
        style={style}
        {...rest}
      >
        {children}
      </MaterialLink>
      {isPDF && openDocument !== undefined && (
        <DocumentDialog
          href={linkURL}
          open={openDocument}
          close={closeDocument}
        />
      )}
    </>
  );
};

export const Link: React.FC<LinkProps> = React.memo((props) => {
  const { children, href = '', link = 'auto' } = props;

  if (
    !href.endsWith('.pdf') &&
    (href.startsWith('http') ||
      href.startsWith('mailto:') ||
      href.startsWith('tel:') ||
      link === 'simple')
  ) {
    return <MaterialLink href={href}>{children}</MaterialLink>;
  }

  return <SmartLink {...props} />;
});
