import Box from '@material-ui/core/Box';
import Fade from '@material-ui/core/Fade';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Account } from '../hooks/useAccount';
import { DrawerContainer } from '../hooks/useDrawer';
import { Login } from '../hooks/useLogin';
import { useRoutes } from '../hooks/useRoutes';
import { Settings } from '../hooks/useSettings';
import { getAccountIcon } from '../icons/Profile';
import { Auth } from '../pages/Auth';
import { blue, duration } from '../styles';
import { uppercased } from '../styles/theme';
import { LoginButton } from './forms/LoginButton';
import { LogoutButton } from './forms/LogoutButton';
import { Link } from './Link';
import { ProfileSelectorTrigger } from './ProfileSelectorTrigger';

const styles = makeStyles({
  menu: {
    display: 'flex',
    flex: '1',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: '26px',

    '& a': {
      ...uppercased,
      textTransform: 'uppercase',
      margin: '0 2%',

      '&.active': {
        borderBottom: `2px solid ${blue}`,
        paddingBottom: '4px',
        marginBottom: '-4px',
      },
    },
  },
  menuInDrawer: {
    flex: 'initial',
    flexDirection: 'column',
    alignItems: 'flex-start',

    '& a': {
      margin: '12px 16px',

      '&.active': {
        marginBottom: '10px',
      },
    },

    '& .MuiButton-root': {
      marginTop: '12px',
      marginLeft: '6px',
    },
  },
  auth: {
    flex: '1',
    position: 'relative',
    maxWidth: '640px',
    margin: '0 auto',
  },
  authInDrawer: {
    flex: 'initial',
  },
  back: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  links: {
    display: 'flex',
    flex: '1',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    paddingRight: '12px',
  },
  linksInDrawer: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
});

interface Props {
  insideDrawer?: boolean;
}

export const HeaderMenu: React.FC<Props> = React.memo(({ insideDrawer }) => {
  const {
    settings: { auth: token },
  } = Settings.useContainer();
  const { root, mina } = useRoutes();
  const { locked, authShown, toggleAuth } = Login.useContainer();
  const { account, loading, brokers, admin } = Account.useContainer();
  const { toggleDrawer } = DrawerContainer.useContainer();
  const { menu, links, auth, back, menuInDrawer, linksInDrawer, authInDrawer } =
    styles();
  const [menuShown, toggleMenu] = React.useState(!authShown);
  const [formShown, toggleForm] = React.useState(authShown);

  React.useEffect(() => {
    if (!locked) {
      if (authShown && menuShown) {
        toggleMenu(false);
      } else if (!authShown && formShown) {
        toggleForm(false);
      }
    }
  }, [authShown, menuShown, formShown, locked]);

  const closeDrawerIfLinkClicked = React.useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      const el = e.target as HTMLElement;
      if (el.closest('a') && insideDrawer) {
        toggleDrawer(false);
      }
    },
    [toggleDrawer, insideDrawer],
  );

  const isPrerender = navigator.userAgent === 'ReactSnap';

  return (
    <>
      <Fade
        in={menuShown || isPrerender}
        mountOnEnter={false}
        unmountOnExit={true}
        onExited={() => toggleForm(true)}
        timeout={duration}
      >
        <Box
          className={`${menu} ${insideDrawer ? menuInDrawer : ''}`}
          onClick={closeDrawerIfLinkClicked}
        >
          <Box className={`${links} ${insideDrawer ? linksInDrawer : ''}`}>
            <Link href={`${root}`}>
              <FormattedMessage id="menu.health" />
            </Link>
            <Link href={`${root}/checkup`}>
              <FormattedMessage id="menu.health_checkup" />
            </Link>
            <Link href={`${root}/faq`}>
              <FormattedMessage id="menu.faq" />
            </Link>
            <Link href={`${root}/about`}>
              <FormattedMessage id="menu.about" />
            </Link>
            <Link href={`${root}/contacts`}>
              <FormattedMessage id="menu.contacts" />
            </Link>
          </Box>

          {!account && <LoginButton loading={loading || isPrerender} />}
          {!!account && (
            <>
              <Link href={mina} style={{ position: 'relative' }}>
                {getAccountIcon(account, admin, !!brokers.length, {
                  color: 'secondary',
                  style: {
                    position: 'absolute',
                    right: '100%',
                    top: -4,
                    marginRight: 4,
                  },
                })}
                <FormattedMessage
                  id={
                    admin
                      ? 'admin'
                      : brokers.length
                      ? 'broker.office'
                      : 'menu.mina'
                  }
                />
              </Link>

              <ProfileSelectorTrigger />

              <LogoutButton />
            </>
          )}
        </Box>
      </Fade>
      <Fade
        in={formShown && !isPrerender}
        mountOnEnter={false}
        unmountOnExit={true}
        onExited={() => toggleMenu(true)}
        timeout={duration}
      >
        <Box className={`${auth} ${insideDrawer ? authInDrawer : ''}`}>
          {!token && (
            <IconButton
              onClick={() => toggleAuth(false)}
              color="secondary"
              aria-label="Back"
              className={back}
            >
              <CloseIcon />
            </IconButton>
          )}
          <Auth />
        </Box>
      </Fade>
    </>
  );
});
