import { alpha } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import IntersectionObserver from '@researchgate/react-intersection-observer';
import React from 'react';
import { duration, yellow } from '../styles';
import { theme } from '../styles/theme';

const transparent = alpha(yellow, 0);
const yellow20 = alpha(yellow, 0.2);
const styles = makeStyles<typeof theme, { visible: boolean }>({
  highlight: {
    display: 'inline-block',
    position: 'relative',
    whiteSpace: 'nowrap',
    fontStyle: 'normal',

    '&::before': {
      content: '" "',
      position: 'absolute',
      left: '-3px',
      top: '0px',
      width: '100%',
      height: '100%',
      padding: '2px 4px',
      background: `linear-gradient(65deg, ${transparent}, ${yellow20} 10%, ${yellow20} 85%, ${transparent})`,
      transform: ({ visible }) => `rotate(-1deg) scaleX(${visible ? 1 : 0})`,
      transformOrigin: 'left center',
      transition: `all ${duration * 2}ms`,
      borderRadius: '6px',
      pointerEvents: 'none',
      mixBlendMode: 'multiply',
    },
  },
});

export const Highlight = React.memo(
  (
    props: React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLElement>,
      HTMLElement
    >,
  ) => {
    const [visible, setVisible] = React.useState(false);
    const { highlight } = styles({ visible });
    const onIntersection = React.useCallback(
      (entry: IntersectionObserverEntry, unobserve: () => void) => {
        if (entry.isIntersecting && !visible) {
          unobserve();
          setTimeout(() => {
            setVisible(true);
          }, duration * 3);
        }
      },
      [visible],
    );

    return (
      <IntersectionObserver disabled={visible} onChange={onIntersection}>
        <em {...props} className={highlight} />
      </IntersectionObserver>
    );
  },
);
