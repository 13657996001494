import React from 'react';
import { useIntl } from 'react-intl';
import productImage from '../public/product.png';

export const InsuranceProductSchema: React.FC = () => {
  const { messages } = useIntl();
  const productImageURL = new URL(productImage, window.location.href);
  const productURL = new URL(window.location.href);
  const minPrice = 162;
  const maxPrice = 535;

  const schema = {
    '@context': 'https://schema.org/',
    '@type': 'Product',
    name: messages['product.health_plus.title'],
    image: [`${productImageURL}`],
    description: messages['product.health_plus.description'],
    sku: 'health_plus',
    brand: {
      '@type': 'Thing',
      name: messages['unika.name'],
    },
    offers: {
      '@type': 'AggregateOffer',
      url: `${productURL}`,
      priceCurrency: 'SEK',
      lowPrice: minPrice.toFixed(2),
      highPrice: maxPrice.toFixed(2),
      itemCondition: 'https://schema.org/NewCondition',
      availability: 'https://schema.org/InStock',
      seller: {
        '@type': 'Organization',
        name: messages['unika.name'],
      },
    },
  };

  return (
    <script type="application/ld+json">
      {JSON.stringify(schema, undefined, 2)}
    </script>
  );
};
