import React from 'react';
import { Redirect } from 'react-router';
import { ProfileSelector as ProfileSelectorContainer } from '../hooks/useProfileSelector';
import { useRoutes } from '../hooks/useRoutes';
import { Broker } from '../swagger';

export const InsuranceBrokerRedirect: React.FC = () => {
  const {
    selectProfile,
    shouldSelectProfile,
    profileOptions,
    loading,
    brokers,
  } = ProfileSelectorContainer.useContainer();
  const { insurance } = useRoutes();
  const brokerOption = profileOptions.find(
    (profile): profile is Broker => !!profile && 'name' in profile,
  );
  const [ready, setReady] = React.useState(false);

  React.useEffect(() => {
    if (loading) {
      return;
    }

    (async () => {
      if (brokerOption && !brokers.length) {
        await selectProfile(brokerOption.id);
      }

      setReady(true);
    })();
  }, [shouldSelectProfile, brokerOption, loading, brokers]);

  if (!ready) {
    return null;
  }

  return <Redirect to={insurance} />;
};
