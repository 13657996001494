import Bugsnag from '@bugsnag/js';
import BugsnagPluginConsoleBreadcrumbs from '@bugsnag/plugin-console-breadcrumbs';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import 'array-flat-polyfill';
import React from 'react';
import { render } from 'react-dom';
import App from './App';
import { removeStyleTags } from './utils/removeStyleTags';

Bugsnag.start({
  apiKey: 'd09445793f222126463c40b25a572e55',
  plugins: [BugsnagPluginConsoleBreadcrumbs, new BugsnagPluginReact()],
  enabledReleaseStages: ['production'],
  releaseStage: window.isProduction ? 'production' : 'development',
});

const ErrorBoundary =
  Bugsnag.getPlugin('react')?.createErrorBoundary(React) || React.Fragment;
const rootElement = document.querySelector('main');

if (rootElement) {
  removeStyleTags(rootElement);

  render(
    <ErrorBoundary>
      <App />
    </ErrorBoundary>,
    rootElement,
  );
}
