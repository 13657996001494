import * as React from 'react';

const LazyBrokerSignup = React.lazy<
  typeof import('../BrokerSignup').BrokerSignup
>(() =>
  import('../BrokerSignup').then((module) => ({
    default: module.BrokerSignup,
  })),
);
export const SuspenseBrokerSignup: typeof import('../BrokerSignup').BrokerSignup =
  (props) => (
    <React.Suspense fallback={''}>
      <LazyBrokerSignup {...props} />
    </React.Suspense>
  );

SuspenseBrokerSignup.page = 'broker-signup';
