import Box from '@material-ui/core/Box';
import * as H from 'history';
import * as React from 'react';
import posed, { PoseGroup } from 'react-pose';
import { Route, RouteComponentProps, RouteProps } from 'react-router';
import { MetaData } from '../hooks/useMeta';
import { Settings } from '../hooks/useSettings';
import { Page } from '../types';
import { sendBeacon } from '../utils/api';
import { getCurrentURL } from '../utils/url';

const PosedBox = posed(Box)({
  enter: {
    opacity: 1,
    transition: { duration: 300 },
  },
  exit: {
    opacity: 1,
  },
});

let lastPath = '';

const MemoizedBeacon: React.FC<{
  page: string;
  match?: string;
  isExact?: boolean;
}> = React.memo(({ page, match, isExact }) => {
  const { settings } = Settings.useContainer();
  const pathname = window.location.pathname;
  const isSame = pathname === lastPath;

  if (page && match && isExact && !isSame && settings.persist) {
    lastPath = pathname;

    try {
      sendBeacon(`view_${page}`, {
        pathname,
        match,
      });

      if (window.ga) {
        window.ga('set', 'page', getCurrentURL());
        window.ga('send', 'pageview');
      }
    } catch (e) {
      console.error(e);
    }
  }

  return <MetaData page={page} />;
});

const MemoizedComponent: React.FC<{
  component: Page;
  uuid: number;
  match?: string;
  history: H.History;
}> = React.memo((props) => {
  const { component: Component, uuid, history, match, ...params } = props;

  return (
    <PoseGroup flipMove={false} animateOnMount={true}>
      {!!match && (
        <PosedBox key={uuid} minHeight="100%" flex="1" width="100%">
          <Component match={match} history={history} {...params} />
        </PosedBox>
      )}
    </PoseGroup>
  );
});

const MemoizedPageRoute: React.FC<RouteProps> = React.memo((props) => {
  const component = props.component as Page;
  const key = Date.now();

  const render = React.useCallback((routeProps: RouteComponentProps<any>) => {
    const { match, history } = routeProps;

    return (
      <>
        <MemoizedBeacon
          page={component.page}
          isExact={match && match.isExact}
          match={match && match.url}
        />
        <MemoizedComponent
          component={component}
          history={history}
          match={match && match.url}
          uuid={key}
          {...match.params}
        />
      </>
    );
  }, []);

  return <Route path={props.path} render={render} />;
});

export const PageRoute: React.FC<RouteProps> = (props) => {
  return <MemoizedPageRoute path={props.path} component={props.component} />;
};

export const SimpleRoute: React.FC<RouteProps> = React.memo((props) => {
  const { component, ...rest } = props;
  const Component = component as Page;

  const render = (routeProps: RouteComponentProps<any>) => {
    const { match, history } = routeProps;

    return (
      <>
        <MemoizedBeacon
          page={Component.page}
          isExact={match && match.isExact}
          match={match && match.url}
        />
        <Component
          history={history}
          match={match && match.url}
          {...match.params}
        />
      </>
    );
  };

  return <Route render={render} {...rest} />;
});
