const personalNumberRegex = /^(16|19|20)?\d{10}$/;

export const clearPersonalNumber = (input?: string): string | undefined => {
  input = input && input.replace(/([^\d])/g, '');

  if (!input || !input.match(personalNumberRegex)) {
    return;
  }

  if (input.length === 10) {
    const todayYear = Number(
      new Date().getUTCFullYear().toString().substring(2),
    );
    const birthYear = Number(input.substring(0, 2));
    const prefix = birthYear > todayYear ? '19' : '20';
    input = prefix + input;
  }

  // Check valid date
  const y = Number(input.substring(0, 4));
  const m = Number(input.substring(4, 6)) - 1;
  const d = Number(input.substring(6, 8));
  const date = new Date(Date.UTC(y, m, d));

  const isCompany = m > 11;
  const isInvalid =
    Object.prototype.toString.call(date) !== '[object Date]' ||
    isNaN(date.getTime());

  if (!isCompany && isInvalid) {
    return;
  }

  if (isCompany) {
    input = '16' + input.substr(2);
  }

  const numdigits = input.length;
  const parity = numdigits % 2;
  let digit: number;
  let sum = 0;
  // Check luhn algorithm
  for (let i = 2; i < 12; i = i + 1) {
    digit = parseInt(input.charAt(i), 10);
    if (i % 2 === parity) {
      digit *= 2;
    }
    if (digit > 9) {
      digit -= 9;
    }
    sum += digit;
  }

  if (sum % 10 !== 0) {
    return;
  }

  if (isCompany) {
    input = input.substr(2);
  }

  return input;
};
