import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';
import { Lato, shadow } from '../styles';
import { theme } from '../styles/theme';

const styles = makeStyles<typeof theme, Partial<USPProps>>({
  main: {
    padding: '24px 0 32px',
    background: '#fff',
  },
  container: {
    padding: '24px 0',
    flexDirection: ({ reverse }) => (reverse ? 'row-reverse' : 'row'),
    position: 'relative',
  },
  dark: {
    background: '#fff',
    boxShadow: shadow,
    borderRadius: '12px',
  },
  primary: {
    '& h5': {
      color: theme.palette.text.primary,
      marginBottom: '8px',
    },
  },
  secondary: {
    display: 'flex',
    alignItems: 'center',

    '& h5': {
      fontFamily: Lato,
      color: theme.palette.primary.light,
      fontSize: '2rem',
      fontStyle: 'italic',
      marginBottom: '8px',
    },
    '& p, & li': {
      // fontSize: '1.1rem',
      color: theme.palette.primary.light,
      fontSize: '1rem',
    },
  },
});

interface USPProps {
  primary: React.ReactElement;
  secondary?: React.ReactElement;
  secondPrimary?: React.ReactElement;
  secondSecondary?: React.ReactElement;
  reverse?: true;
  dark?: true;
  id?: string;
}

export const USP: React.FC<USPProps> = (props) => {
  const { primary, secondary, secondPrimary, secondSecondary, id } = props;
  const {
    main,
    container,
    dark,
    primary: primaryClass,
    secondary: secondaryClass,
  } = styles(props);

  return (
    <Box className={main} component="section" id={id}>
      <Container>
        <Box px={2}>
          <Grid
            container
            spacing={6}
            className={`${container} ${props.dark ? dark : ''}`}
          >
            <Grid item sm={secondary ? 7 : undefined} className={primaryClass}>
              <Box p={1}>{primary}</Box>
            </Grid>
            {secondary && (
              <Grid item sm={5} className={secondaryClass}>
                <Box p={1}>{secondary}</Box>
              </Grid>
            )}
            {secondPrimary && (
              <Grid
                item
                sm={secondSecondary ? 7 : undefined}
                className={primaryClass}
              >
                <Box p={1}>{secondPrimary}</Box>
              </Grid>
            )}
            {secondSecondary && (
              <Grid item sm={5} className={secondaryClass}>
                <Box p={1}>{secondSecondary}</Box>
              </Grid>
            )}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};
