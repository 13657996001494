import * as React from 'react';
import { Account } from '../swagger';
import { formatPersonalNumber } from '../utils/formatPersonalNumber';
import { personalNumberToAge } from '../utils/personalNumberToAge';

export const AccountName: React.FC<{ account: Account }> = ({ account }) => {
  if (!account.sensitive) {
    return null;
  }

  const personnummer = account.sensitive.personnummer;
  const age = personalNumberToAge(personnummer);

  if (account.sensitive.fullName) {
    const output = [account.sensitive.fullName];
    if (typeof age === 'number') {
      output.push(`${Math.floor(age)}`);
    }
    return <>{output.join(', ')}</>;
  }

  return <>{formatPersonalNumber(personnummer)}</>;
};
