import * as React from 'react';

type BrokerChildModule = typeof import('../pdf/BrokerChild').BrokerChild;

const LazyBrokerChild = React.lazy<BrokerChildModule>(() =>
  import('../pdf/BrokerChild').then((module) => ({
    default: module.BrokerChild,
  })),
);
export const SuspenseBrokerChild: BrokerChildModule = (props) => (
  <React.Suspense fallback={''}>
    <LazyBrokerChild {...props} />
  </React.Suspense>
);

SuspenseBrokerChild.page = 'brokerchild';
