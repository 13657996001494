import * as React from 'react';

const LazyPurchaseInfo = React.lazy<
  typeof import('../pdf/PurchaseInfo').PurchaseInfo
>(() =>
  import('../pdf/PurchaseInfo').then((module) => ({
    default: module.PurchaseInfo,
  })),
);
export const SuspensePurchaseInfo: typeof import('../pdf/PurchaseInfo').PurchaseInfo =
  (props) => (
    <React.Suspense fallback={''}>
      <LazyPurchaseInfo {...props} />
    </React.Suspense>
  );

SuspensePurchaseInfo.page = 'purchaseinfo';
