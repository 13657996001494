import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Document } from '../Document';
import { InfoDialog } from './InfoDialog';

interface DocumentDialogProps {
  title?: string;
  href: string;
  open: boolean;
  close: () => void;
}

export const DocumentDialog: React.FC<DocumentDialogProps> = ({
  title,
  href,
  open,
  close,
}) => {
  return (
    <InfoDialog
      noMargins
      title={title ? <FormattedMessage id={title} /> : 'PDF'}
      isOpen={open}
      onClose={close}
      maxWidth="lg"
    >
      <Document path={href} />
    </InfoDialog>
  );
};
