import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Account } from '../hooks/useAccount';
import { healthQuestions } from '../hooks/useQuiz';
import { theme } from '../styles/theme';
import { Person, Quiz } from '../swagger';
import { parseQuiz } from '../utils/parseQuiz';
import { Markdown } from './Markdown';
import { OrderPersonDetails } from './OrderPersonDetails';
import {
  QuizTableOptions,
  styles as quizTableStyles,
} from './QuizTableOptions';
import TypeEnum = Person.TypeEnum;

const styles = makeStyles({
  questionStyle: {
    hyphens: 'auto',
    verticalAlign: 'middle',

    [theme.breakpoints.down('xs')]: {
      borderBottom: '0 none',
      paddingBottom: '8px',
    },
  },
  optionsStyle: {
    textAlign: 'right',

    '& input': {
      textAlign: 'inherit',
    },

    [theme.breakpoints.down('xs')]: {
      paddingTop: '0',
      textAlign: 'left',
    },
  },
});

type InsuranceQuizTableProps = {
  person: Person;
  onAnswer: (type: keyof Quiz, answer: number) => void;
  product?: Quiz.ProductTypesEnum;
  isBroker?: boolean;
  onPerson?: (person: Person) => void;
  disclaimer?: React.ReactNode;
};

export const InsuranceQuizTable: React.FC<InsuranceQuizTableProps> = (
  props,
) => {
  const { onAnswer, product, person, onPerson, isBroker, disclaimer } = props;
  const { admin } = Account.useContainer();
  const { questionStyle, optionsStyle } = styles();
  const { options } = quizTableStyles();
  const quiz = person.quiz;
  const isForChild = person.type === TypeEnum.Child;

  const translationPrefix = isForChild ? '.child' : '';
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [showQuiz, toggleQuiz] = React.useState(
    admin || !isBroker || !quiz || !!parseQuiz(quiz).activeQuestion,
  );

  const canToggleQuiz =
    isBroker && !admin && quiz && person.type !== TypeEnum.Spouse && !showQuiz;
  const quizFields =
    quiz &&
    showQuiz &&
    (person.type !== TypeEnum.Spouse || admin) &&
    healthQuestions.map((type) => {
      const currentAnswer = quiz[type];
      const optionsCell = (
        <TableCell key="options" className={`${options} ${optionsStyle}`}>
          <QuizTableOptions
            type={type}
            currentAnswer={
              typeof currentAnswer === 'number' ? currentAnswer : undefined
            }
            onAnswer={onAnswer}
          />
        </TableCell>
      );

      return (
        <React.Fragment key={type}>
          <TableRow key={`${type}question`}>
            {!isMobile && optionsCell}
            <TableCell key="question" className={questionStyle}>
              <FormattedMessage id={`question${translationPrefix}.${type}`} />
            </TableCell>
          </TableRow>
          {isMobile && (
            <TableRow key={`${type}options`}>{optionsCell}</TableRow>
          )}
        </React.Fragment>
      );
    });

  return (
    <>
      <Table style={{ width: 'auto', margin: '0 auto' }}>
        <TableBody>
          <OrderPersonDetails person={person} onPerson={onPerson} />
          {quizFields && disclaimer}
          {quizFields}
          {!quizFields && canToggleQuiz && (
            <TableRow key="showQuiz">
              <TableCell colSpan={2}>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => toggleQuiz(true)}
                >
                  <FormattedMessage id="fill_by_broker" />
                </Button>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {product === Quiz.ProductTypesEnum.Health && (
        <Markdown children="quiz.disclaimer" variant="body2" />
      )}
    </>
  );
};
