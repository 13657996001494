import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import IntersectionObserver from '@researchgate/react-intersection-observer';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CTA } from '../hooks/useCTA';
import { useRoutes } from '../hooks/useRoutes';
import { blue, gradient } from '../styles';
import { theme } from '../styles/theme';
import { LinkButton, SubmitButton } from './forms/SubmitButton';
import { Illustration } from './Illustration';

const panelClipPath = 'ellipse(140% 100% at 60% 0%)';
const overlap = '16vh';

const styles = makeStyles({
  container: {
    backgroundColor: blue,
    backgroundImage: gradient,
    color: '#fff',
    clipPath: panelClipPath,
    WebkitClipPath: panelClipPath,
    marginBottom: `-${overlap}`,
    position: 'relative',
  },
  padder: {
    textAlign: 'center',
    maxWidth: '720px',
    margin: '0 auto',
    padding: '10vh 24px 20vh',
    minHeight: '40vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',

    [theme.breakpoints.down('xs')]: {
      minHeight: '95vh',
      paddingTop: '6vh',
      justifyContent: 'flex-start',
    },
  },
  heading: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.8rem',
    },
  },
  subheading: {
    margin: '24px 0',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem',
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',

    '& button': {
      marginLeft: '12px',
      marginRight: '12px',
    },
  },
  cta: {
    display: 'flex',
    flexDirection: 'column',
  },
});

export const LandingHero: React.FC = () => {
  const { insurance } = useRoutes();
  const { container, padder, heading, subheading, buttons, cta } = styles();
  const { viewCTA, toggleLanding } = CTA.useContainer();

  React.useEffect(() => {
    toggleLanding(true);
    return () => toggleLanding(false);
  }, [toggleLanding]);

  const onCTAIntersection = React.useCallback(
    ({ isIntersecting }: IntersectionObserverEntry) => {
      viewCTA(isIntersecting);
    },
    [viewCTA],
  );

  const scrollToHowTo = React.useCallback(() => {
    const howTo = document.querySelector('#how-it-works');
    const header = document.querySelector('header');

    if (howTo && header) {
      const howToTop = (howTo as HTMLElement).offsetTop;
      const headerHeight = header.clientHeight;

      window.scrollTo({
        top: howToTop - headerHeight + 12,
        behavior: 'smooth',
      });
    }
  }, []);

  return (
    <Box className={container} component="section">
      <Illustration overlap={overlap} />
      <Box className={padder}>
        <Typography variant="h1" className={heading}>
          <FormattedMessage id="landing.heading" />
        </Typography>

        <Typography variant="h5" className={subheading}>
          <FormattedMessage id="landing.subheading" />
        </Typography>

        <Box className={buttons}>
          <Box pb={3}>
            <SubmitButton
              label="landing.learn"
              variant="outlined"
              color="inherit"
              size="large"
              onClick={scrollToHowTo}
            />
          </Box>
          <IntersectionObserver onChange={onCTAIntersection}>
            <Box pb={3} className={cta}>
              <LinkButton
                to={insurance}
                label="landing.cta"
                variant="contained"
                color="secondary"
                size="large"
              />
              <Typography variant="caption">
                <FormattedMessage id="landing.cta_note" />
              </Typography>
            </Box>
          </IntersectionObserver>
        </Box>
      </Box>
    </Box>
  );
};

export const CheckupHero: React.FC = () => {
  const { container, padder, heading, subheading, buttons, cta } = styles();
  const { checkup } = useRoutes();
  const { viewCTA, toggleCheckup } = CTA.useContainer();

  React.useEffect(() => {
    toggleCheckup(true);
    return () => toggleCheckup(false);
  }, [toggleCheckup]);

  const onCTAIntersection = React.useCallback(
    ({ isIntersecting }: IntersectionObserverEntry) => {
      viewCTA(isIntersecting);
    },
    [viewCTA],
  );

  const scrollToUSPs = React.useCallback(() => {
    const howTo = document.querySelector('#usps');
    const header = document.querySelector('header');

    if (howTo && header) {
      const howToTop = (howTo as HTMLElement).offsetTop;
      const headerHeight = header.clientHeight;

      window.scrollTo({
        top: howToTop - headerHeight + 12,
        behavior: 'smooth',
      });
    }
  }, []);

  return (
    <Box className={container} component="section">
      <Illustration overlap={overlap} />
      <Box className={padder}>
        <Typography variant="h1" className={heading}>
          <FormattedMessage id="checkup.heading" />
        </Typography>

        <Typography variant="h5" className={subheading}>
          <FormattedMessage id="checkup.subheading" />
        </Typography>

        <Box className={buttons}>
          <Box pb={3}>
            <SubmitButton
              label="checkup.learn"
              variant="outlined"
              color="inherit"
              size="large"
              onClick={scrollToUSPs}
            />
          </Box>
          <IntersectionObserver onChange={onCTAIntersection}>
            <Box pb={3} className={cta}>
              <LinkButton
                to={checkup}
                label="checkup.cta"
                variant="contained"
                color="secondary"
                size="large"
              />
              <Typography variant="caption">
                <FormattedMessage id="checkup.cta_note" />
              </Typography>
            </Box>
          </IntersectionObserver>
        </Box>
      </Box>
    </Box>
  );
};
