export const PERIODS: Record<string, number> = {
  monthly: 1,
  quarterly: 3,
  annually: 12,
};

export const HEALTH_CHECKUP_PERIODS: Record<string, number> = {
  oneTimeOff: 0,
  annually: 12,
};
