import AppBar from '@material-ui/core/AppBar/AppBar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Hidden from '@material-ui/core/Hidden';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CTA } from '../hooks/useCTA';
import { DrawerContainer } from '../hooks/useDrawer';
import { useRoutes } from '../hooks/useRoutes';
import { blue, gradient } from '../styles';
import { theme } from '../styles/theme';
import { LinkButton } from './forms/SubmitButton';
import { HeaderMenu } from './HeaderMenu';
import { Link } from './Link';
import { Logo } from './Logo';

const shadowClipPath = 'ellipse(180% 100% at 25% 0%)';
const panelClipPath = 'ellipse(120% 100% at 40% 0%)';

const styles = makeStyles({
  shadow: {
    position: 'absolute',
    filter: 'blur(4px)',
    width: '100%',
    height: '100%',
    top: '-2px',
    left: '0',
    transition: 'all 300ms',

    '&::before': {
      clipPath: shadowClipPath,
      WebkitClipPath: shadowClipPath,
      content: '" "',
      background: 'rgba(0,0,0,0.5)',
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
    },
  },
  panel: {
    clipPath: panelClipPath,
    WebkitClipPath: panelClipPath,
    background: '#fff',
    position: 'relative',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'stretch',

    [theme.breakpoints.up('md')]: {
      paddingLeft: '52px',
      paddingRight: '52px',
    },

    [theme.breakpoints.down('sm')]: {
      paddingLeft: '36px',
      paddingRight: '36px',
    },

    [theme.breakpoints.down('xs')]: {
      paddingLeft: '24px',
      paddingRight: '24px',
    },
  },
  container: {
    position: 'relative',
    flex: '1',
  },
  menu: {
    height: '100%',
    transition: 'all 500ms',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    opacity: 1,
    transform: 'translateY(0%) rotate3d(1, 0, 0, 0deg) perspective(1000px)',
    WebkitTransform:
      'translateY(0%) rotate3d(1, 0, 0, 0deg) perspective(1000px)',
  },
  menuCTA: {
    opacity: 0,
    transform: 'translateY(-30%) rotate3d(1, 0, 0, 15deg) perspective(1000px)',
    WebkitTransform:
      'translateY(-30%) rotate3d(1, 0, 0, 15deg) perspective(1000px)',
  },
  cta: {
    position: 'absolute',
    background: '#fff',
    left: '0',
    top: '0',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    transition: 'all 500ms',
    pointerEvents: 'none',
    opacity: 0,
    transform: 'translateY(30%) rotate3d(1, 0, 0, -15deg) perspective(1000px)',
    WebkitTransform:
      'translateY(30%) rotate3d(1, 0, 0, -15deg) perspective(1000px)',
  },
  ctaVisible: {
    pointerEvents: 'all',
    opacity: 1,
    transform: 'translateY(0%) rotate3d(1, 0, 0, 0deg) perspective(1000px)',
    WebkitTransform:
      'translateY(0%) rotate3d(1, 0, 0, 0deg) perspective(1000px)',
  },
  ctaHolder: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    whiteSpace: 'nowrap',
  },
  toolbar: {
    minHeight: '85px',
    backgroundColor: blue,
    backgroundImage: gradient,
  },
});

export const Header: React.FC = React.memo(() => {
  const { showCTA, checkupShown } = CTA.useContainer();
  const { insurance, checkup } = useRoutes();
  const { toggleDrawer } = DrawerContainer.useContainer();

  const {
    container,
    menu,
    menuCTA,
    cta,
    panel,
    shadow,
    header,
    toolbar,
    ctaVisible,
    ctaHolder,
  } = styles();

  return (
    <>
      <AppBar color="default" elevation={0}>
        <Box>
          <Box className={shadow} />
          <Toolbar disableGutters className={panel}>
            <Container className={header}>
              <Box style={{ marginRight: '24px' }}>
                <Link href="/" style={{ border: '0 none' }}>
                  <Logo />
                </Link>
              </Box>
              <Box className={container}>
                <Box className={`${menu} ${showCTA ? menuCTA : ''}`}>
                  <Hidden xsDown>
                    <HeaderMenu />
                  </Hidden>
                  <Hidden smUp>
                    <Button
                      color="primary"
                      aria-label="open menu"
                      onClick={() => toggleDrawer(true)}
                    >
                      Main menu
                      <MenuIcon style={{ marginLeft: '8px' }} />
                    </Button>
                  </Hidden>
                </Box>
                <Box className={`${cta} ${showCTA ? ctaVisible : ''}`}>
                  <Hidden xsDown>
                    <Typography
                      variant="h5"
                      color="primary"
                      style={{ margin: '0 16px' }}
                    >
                      <FormattedMessage
                        id={
                          checkupShown
                            ? 'checkup.cta_title'
                            : 'landing.subheading'
                        }
                      />
                    </Typography>
                  </Hidden>
                  <Box className={ctaHolder}>
                    <LinkButton
                      to={checkupShown ? checkup : insurance}
                      label={checkupShown ? 'checkup.cta' : 'landing.cta'}
                      variant="contained"
                      color="secondary"
                    />
                    <Typography variant="caption" color="textSecondary">
                      <FormattedMessage
                        id={
                          checkupShown ? 'checkup.cta_note' : 'landing.cta_note'
                        }
                      />
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Container>
          </Toolbar>
        </Box>
      </AppBar>
      <Toolbar className={`${toolbar} gradient-container`} />
    </>
  );
});
