import { useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import { createContainer } from 'unstated-next';
import { Product, ProductPayload, Quiz } from '../swagger';
import { api } from '../utils/api';
import { Account } from './useAccount';
import { useRoutes } from './useRoutes';

type InsurancePayload = {
  quiz: Quiz;
  productType?: Product.TypeEnum | Quiz.ProductTypesEnum;
  orderId?: string;
  personnummer?: string | null;
  paymentPeriod?: number;
  payer?: string;
};

const useOrder = () => {
  const { reloadAccount, account } = Account.useContainer();
  const [error, setError] = useState('');
  const { mina } = useRoutes();
  const history = useHistory();

  const createOrder = useCallback(
    async (paymentPeriod = 1, payer?: string) => {
      const order = await api.orderControllerCreate({ paymentPeriod, payer });
      void reloadAccount();
      return order;
    },
    [reloadAccount],
  );

  const addProduct = useCallback(
    async (
      orderId: string,
      productType: Product.TypeEnum,
      quiz: string,
      personnummer?: string | null,
      owner?: string | null,
    ) => {
      const payload: ProductPayload = {
        productType: `${productType}`,
        owner: owner || account.id,
        quiz,
      };

      if (personnummer) {
        payload.personnummer = personnummer;
      }

      const product = await api.orderControllerAddProduct(orderId, payload);

      await reloadAccount();

      return product;
    },
    [reloadAccount],
  );

  const createInsurance = useCallback(
    async (payload: InsurancePayload) => {
      const { orderId, paymentPeriod, personnummer, productType, quiz, payer } =
        payload;

      if (!productType) {
        return;
      }

      if (!account) {
        return history.push({
          pathname: mina,
          search: `${new URLSearchParams({
            product: `${productType}`,
            quiz: quiz.id,
          })}`,
        });
      }

      const params = new URLSearchParams(history.location.search);
      const owner = params.get('owner');

      try {
        const finalOrderId =
          orderId ||
          params.get('id') ||
          (await createOrder(paymentPeriod, payer)).id;
        const finalPersonnummer = personnummer || params.get('personnummer');

        await addProduct(
          finalOrderId,
          productType as Product.TypeEnum,
          quiz.id,
          finalPersonnummer,
          owner,
        );
      } catch (e) {
        if ('json' in e) {
          const response = await e.json();

          if (response.message) {
            setError(response.message);
          }
        }

        throw e;
      }

      if (owner) {
        history.replace(mina);
      } else {
        history.replace(`${mina}/order-${orderId}`);
      }
    },
    [createOrder, addProduct, history, history.location.search],
  );

  const removeProduct = async (productId: string, note = '') => {
    await api.orderControllerCancelProduct(productId, note);
    await reloadAccount();
  };

  return {
    createInsurance,
    createOrder,
    addProduct,
    removeProduct,
    error,
    setError,
  };
};

export const Order = createContainer(useOrder);
