import Bugsnag from '@bugsnag/js';
import React from 'react';
import { Order, OrderPayload, PaymentPayload, Person } from '../swagger';
import { api, getAuthHeaders } from '../utils/api';

export interface useMultiPreviewCheckupProp {
  selectedBroker?: string;
  consent?: boolean;
  offerId?: string;
  pendingMethod?: PaymentPayload;
  paymentPeriod?: number;
  persons: Array<Person>;
  selectedPricing?: string;
  startDate?: string;
  ready?: boolean;
  lab?: string;
}

export const useMultiPreview = (
  checkup: useMultiPreviewCheckupProp,
  setOrder: (order: Order | undefined) => void,
  productType?: OrderPayload.ProductTypeEnum,
) => {
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (!checkup.ready) {
      return;
    }

    let mounted = true;

    if (checkup.persons.length) {
      setLoading(true);
      const payload: OrderPayload = {
        persons: checkup.persons,
        paymentPeriod: checkup.paymentPeriod,
        startDate: checkup.startDate,
        paymentMethod: checkup.pendingMethod,
        consent: checkup.consent,
        offerId: checkup.offerId,
        pricingId: checkup.selectedPricing,
        brokerId: checkup.selectedBroker,
        meta: checkup.lab ? { city: checkup.lab } : undefined,
        productType,
      };

      api
        .orderControllerMultiPreview(payload, {
          headers: getAuthHeaders(),
        })
        .then((ord) => {
          if (mounted) {
            setOrder(ord);
          }
        })
        .catch((reason) => {
          console.error(reason);
          Bugsnag.notify(reason, (event) => {
            event.addMetadata('payload', payload);
          });
          setOrder(undefined);
        })
        .finally(() => {
          if (mounted) {
            setLoading(false);
          }
        });
    }

    return () => {
      mounted = false;
    };
  }, [
    JSON.stringify(checkup.persons),
    checkup.paymentPeriod,
    checkup.pendingMethod,
    checkup.selectedPricing,
    checkup.selectedBroker,
    checkup.startDate,
    checkup.consent,
    checkup.ready,
  ]);

  return {
    loading,
  };
};
