import makeStyles from '@material-ui/core/styles/makeStyles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import React from 'react';
import { DrawerContainer } from '../hooks/useDrawer';
import { HeaderMenu } from './HeaderMenu';

const styles = makeStyles({
  drawer: {
    '& .MuiDrawer-paper': {
      padding: '24px 24px',
    },
  },
});

export const DrawerMenu: React.FC = () => {
  const { drawer } = styles();
  const { drawerOpen, toggleDrawer } = DrawerContainer.useContainer();

  return (
    <SwipeableDrawer
      className={drawer}
      anchor="right"
      open={drawerOpen}
      onOpen={() => toggleDrawer(true)}
      onClose={() => toggleDrawer(false)}
    >
      <HeaderMenu insideDrawer />
    </SwipeableDrawer>
  );
};
