import Box from '@material-ui/core/Box';
import Card, { CardProps } from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Account } from '../hooks/useAccount';
import { useRoutes } from '../hooks/useRoutes';
import { inactiveCard } from '../styles';
import { theme } from '../styles/theme';
import { Person, Product } from '../swagger';
import { isHealthCheckup } from '../utils/isHealthCheckup';
import { getCurrentPeriod, getNextPeriod } from '../utils/product';
import { AccountName } from './AccountName';
import { FormattedDuration } from './FormattedDuration';
import { OpenAgreementButton } from './forms/OpenAgreementButton';
import { LinkButton } from './forms/SubmitButton';
import { InsuranceDetails } from './InsuranceDetails';
import { InsurancePersonStatus } from './InsurancePersonStatus';
import { OrderProductCancelButton } from './OrderProductCancelButton';
import { ProductID } from './ProductID';

interface OrderProductProps {
  product: Product;
  mainProduct?: Product;
  detached?: boolean;
  index: number;
}

const styles = makeStyles({
  nowrap: {
    whiteSpace: 'nowrap',
  },
  underscore: {
    borderBottom: '1px dashed rgba(0, 46, 92, 0.4)',
    cursor: 'pointer',
  },
  pending: {
    paddingBottom: theme.spacing(2),

    '& .MuiAlert-root': {
      paddingTop: 0,
      paddingBottom: 0,
      paddingRight: theme.spacing(1),
    },
  },
});

export const OrderProduct: React.FC<OrderProductProps> = (props) => {
  const { product, mainProduct, detached, index } = props;
  const { nowrap, pending } = styles();
  const { refreshAccount, iss } = Account.useContainer();
  const [person, setPerson] = React.useState<Person>({
    type: Person.TypeEnum.Spouse,
  });
  const { quiz } = useRoutes();
  const isHC = isHealthCheckup(product.type);
  const isCancelled = product.status === Product.StatusEnum.Cancelled;
  const isPending = product.status === Product.StatusEnum.Pending;
  const inactive: Partial<CardProps> = {
    ...inactiveCard,
    style: { ...inactiveCard.style, filter: 'saturate(0)' },
  };
  const [paidFrom, paidUntil] = getCurrentPeriod(product);
  const [nextFrom, nextUntil] = getNextPeriod(product);
  const payer = (detached && product.order?.payer) || undefined;

  React.useEffect(() => {
    const personnummer = product.account?.sensitive?.personnummer;

    setPerson({
      type: index === 0 ? Person.TypeEnum.Main : Person.TypeEnum.Spouse,
      quiz: product.quiz,
      personnummer,
      email: product.account?.sensitive?.email,
      phone: product.account?.sensitive?.phone,
    });
  }, [product.quiz, index]);

  const updatePerson = React.useCallback(
    async (pr: Person) => {
      if (isPending && typeof pr.quiz?.age === 'number') {
        await refreshAccount();
      }

      setPerson(pr);
    },
    [isPending],
  );

  if (product.hideFromUI) {
    return null;
  }

  if (isPending) {
    return (
      <Box className={pending}>
        <InsurancePersonStatus
          isOrder
          productId={product.id}
          shouldRefreshQuiz
          person={person}
          onUpdate={updatePerson}
        />
      </Box>
    );
  }

  return (
    <Card {...(isCancelled && inactive)}>
      <CardHeader
        style={{ paddingBottom: '8px' }}
        title={
          <Typography variant="h5">
            {product.account && <AccountName account={product.account} />}
          </Typography>
        }
      />
      <CardContent style={{ paddingTop: 0, paddingBottom: 0 }}>
        <Table style={{ marginBottom: '12px' }}>
          <TableBody>
            <TableRow>
              <TableCell className={nowrap}>
                <FormattedMessage id="order.title" />
              </TableCell>
              <TableCell>
                <InsuranceDetails type={product.type} />
                <br />
                <ProductID product={product} />
              </TableCell>
            </TableRow>
            {mainProduct && mainProduct.account && (
              <TableRow>
                <TableCell className={nowrap}>
                  <FormattedMessage id="main_applicant" />
                </TableCell>
                <TableCell>
                  <AccountName account={mainProduct.account} />
                </TableCell>
              </TableRow>
            )}
            {payer?.id !== product?.account?.id &&
              payer?.sensitive?.fullName && (
                <TableRow>
                  <TableCell className={nowrap}>
                    <FormattedMessage id="paid_by" />
                  </TableCell>
                  <TableCell>{payer.sensitive.fullName}</TableCell>
                </TableRow>
              )}
            {!!paidFrom && !!paidUntil && (
              <TableRow>
                <TableCell className={nowrap}>
                  <FormattedMessage id="current_period" />
                </TableCell>
                <TableCell>
                  <FormattedDuration from={paidFrom} to={paidUntil} />
                </TableCell>
              </TableRow>
            )}
            {isCancelled && product.note && (
              <TableRow>
                <TableCell className={nowrap}>
                  <FormattedMessage id="cancel.reason" />
                </TableCell>
                <TableCell>{product.note}</TableCell>
              </TableRow>
            )}
            {!isCancelled && !!nextFrom && !!nextUntil && (
              <TableRow>
                <TableCell className={nowrap}>
                  <FormattedMessage id="upcoming_period" />
                </TableCell>
                <TableCell>
                  <FormattedDuration from={nextFrom} to={nextUntil} />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </CardContent>
      <CardActions style={{ paddingTop: 0, justifyContent: 'space-between' }}>
        {!isHC && product.account?.id && (
          <OpenAgreementButton
            accountId={product.account.id}
            productId={product.id}
          />
        )}
        {!product.account && isPending && (
          <LinkButton
            size="small"
            variant="contained"
            label="quiz.health"
            to={`${quiz}/${product.quiz.id}`}
          />
        )}
        {!isCancelled && iss === 'admin' && (
          <OrderProductCancelButton id={product.id} />
        )}
      </CardActions>
    </Card>
  );
};
