import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as React from 'react';
import { Account } from '../hooks/useAccount';
import { Quiz } from '../hooks/useQuiz';
import { Order, Person } from '../swagger';
import { api } from '../utils/api';
import { AddPerson } from './AddPerson';
import {
  defaultQuizAnswers,
  InsuranceQuizDialog,
} from './dialogs/InsuranceQuizDialog';
import { InsuranceProductStatus, Status } from './InsuranceProductStatus';

export const InsureChild: React.FC<{
  order: Order;
}> = ({ order }) => {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [person, setPerson] = React.useState<Person>();
  const { refreshAccount } = Account.useContainer();
  const { saveOrCreateQuiz } = Quiz.useContainer();

  const createChild = React.useCallback(async () => {
    if (!person) {
      setLoading(true);
      setPerson({
        type: Person.TypeEnum.Child,
        quiz: await saveOrCreateQuiz(defaultQuizAnswers),
      });
      setLoading(false);
    }
    setOpen(true);
  }, [saveOrCreateQuiz, person]);

  const removeChild = React.useCallback(() => {
    setPerson(undefined);
    setOpen(false);
  }, []);

  const saveQuiz = React.useCallback((prs: Person) => {
    setPerson(prs);
    setOpen(false);
  }, []);

  const addChild = React.useCallback(async (prs: Person) => {
    setLoading(true);
    api
      .orderControllerAddPerson(order.id, prs)
      .then(refreshAccount)
      .finally(() => {
        removeChild();
        setLoading(false);
      });
  }, []);

  React.useLayoutEffect(() => {
    window.dispatchEvent(new Event('updateSwipeableViews'));
  });

  return (
    <Box pt={2} px={4}>
      <AddPerson
        type={Person.TypeEnum.Child}
        order={order}
        onClick={createChild}
        loading={loading}
      />
      <InsuranceQuizDialog
        person={open ? person : undefined}
        onClose={removeChild}
        onSave={saveQuiz}
      />
      {!open && person && (
        <InsuranceProductStatus person={person}>
          {(status) => {
            if (status === Status.SUCCESS) {
              if (!loading) {
                addChild(person);
              }

              return (
                <CircularProgress
                  variant="indeterminate"
                  color="secondary"
                  size={24}
                />
              );
            }

            return <></>;
          }}
        </InsuranceProductStatus>
      )}
    </Box>
  );
};
