import { Configuration, DefaultApi } from '../swagger';
import { getGAClientId, getIntercomId, UTM } from './analytics';

const LOCAL_API = `http://${window.location.hostname}:3000`;

export const API =
  process.env.NODE_ENV === 'production'
    ? 'https://api.unikaforsakringar.se'
    : LOCAL_API;

export const DOC = window.isProduction
  ? 'https://mina.unikaforsakringar.se'
  : API;

export const apiConfiguration: Configuration = {};
export const getAuthHeaders = () => {
  const headers: Record<string, string> = {};

  if (typeof apiConfiguration.apiKey === 'string') {
    headers.Authorization = apiConfiguration.apiKey;
  }

  return headers;
};

let requestCounter = 0;
const fetchWithCredentials: typeof window.fetch = (url, init) => {
  if (init) {
    const gaClientId = getGAClientId();
    const intercomId = getIntercomId();

    if (!init.headers) {
      init.headers = {};
    }

    if (gaClientId) {
      (init.headers as Record<string, string>)['X-GA'] = gaClientId;
    }

    if (intercomId) {
      (init.headers as Record<string, string>)['X-Intercom'] = intercomId;
    }

    (init.headers as Record<string, string>)['X-Lang'] =
      document.documentElement.lang;

    Object.assign(init.headers, UTM());
  }

  const requestIndex = requestCounter;
  requestCounter++;

  return window.fetch(url, init).then((response) => {
    if (response.status === 401 && requestIndex !== 0) {
      window.alert('Session expired, please log in again');
    }

    return response;
  });
};

export const api = new DefaultApi(apiConfiguration, API, fetchWithCredentials);

export const sendBeacon = (
  event: string,
  data: Record<string, string | number | undefined | boolean> = {},
) => {
  const formData = new FormData();
  const gaClientId = getGAClientId();
  const intercomId = getIntercomId();
  const completeData = { ...data, ...UTM() };

  for (const key of Object.keys(completeData)) {
    const value = completeData[key];
    if (value !== undefined && value !== '') {
      formData.append(key, `${value}`);
    }
  }

  if (gaClientId) {
    formData.append('X-GA', gaClientId);
  }
  if (intercomId) {
    formData.append('X-Intercom', intercomId);
  }
  if (apiConfiguration.apiKey) {
    formData.append('X-Authorization', `${apiConfiguration.apiKey}`);
  }

  if (window.navigator.sendBeacon) {
    window.navigator.sendBeacon(`${API}/log/${event}`, formData);
  }
};
