import * as React from 'react';

const LazyLetter = React.lazy<typeof import('../pdf/Letter').Letter>(() =>
  import('../pdf/Letter').then((module) => ({
    default: module.Letter,
  })),
);

export const SuspenseLetter: typeof import('../pdf/Letter').Letter = (
  props,
) => (
  <React.Suspense fallback={''}>
    <LazyLetter {...props} />
  </React.Suspense>
);

SuspenseLetter.page = 'letter';
