import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import LibraryAdd from '@material-ui/icons/LibraryAdd';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Content } from '../components/Content';
import { LogoutButton } from '../components/forms/LogoutButton';
import { LinkButton } from '../components/forms/SubmitButton';
import { Markdown } from '../components/Markdown';
import { useRoutes } from '../hooks/useRoutes';

export const InsuranceDone: React.FC<{
  isBroker: boolean;
  finalOrderId: string;
}> = ({ isBroker, finalOrderId }) => {
  const { mina, secureInsurance } = useRoutes();

  React.useEffect(() => {
    try {
      if (!isBroker) {
        window.gtag('event', 'conversion', {
          send_to: 'AW-753969230/ttWMCLa-2r4DEM7QwucC',
          transaction_id: finalOrderId,
        });
      }
    } catch (e) {
      console.error(e);
    }
  }, [finalOrderId, isBroker]);

  return (
    <Content>
      <Box pb={4}>
        <Typography variant="h2" color="secondary" align="center">
          <CheckCircleOutlineIcon fontSize="large" color="primary" />
          <br />
          <FormattedMessage id="thanks" />
        </Typography>

        <Markdown style={{ textAlign: 'center' }}>
          {isBroker ? 'broker.next' : 'customer.next'}
        </Markdown>

        <Box
          display="flex"
          justifyContent="space-evenly"
          maxWidth="480px"
          mx="auto"
        >
          {isBroker && (
            <>
              <LinkButton
                to={secureInsurance}
                color="secondary"
                label="add_insurances"
                endIcon={<LibraryAdd />}
              />
              <LinkButton
                to={`${mina}?tab=admin.customers`}
                label="view_customers"
                endIcon={<ArrowForwardIcon />}
              />
            </>
          )}
          {!isBroker && (
            <>
              <LinkButton
                to={`${mina}/order-${finalOrderId}`}
                color="secondary"
                label="goto.mina"
                endIcon={<ArrowForwardIcon />}
              />
              <LogoutButton variant="outlined" color="secondary" size="large" />
            </>
          )}
        </Box>
      </Box>
    </Content>
  );
};
