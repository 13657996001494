import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { alpha, styled } from '@material-ui/core/styles';
import React from 'react';
import { Documents } from '../components/Documents';
import { LandingHero } from '../components/Hero';
import { InsuranceProductSchema } from '../components/InsuranceProductSchema';
import { Markdown } from '../components/Markdown';
import { USP } from '../components/USP';
import { Video } from '../components/Video';
import { Globe } from '../icons/Globe';
import aboutVideo from '../public/about-optimised.m4v';
import aboutVideoPoster from '../public/about.png';
import { yellow } from '../styles';
import { theme } from '../styles/theme';
import { Page } from '../types';

export const BoxContainer = styled(Box)({
  backgroundColor: '#fff',
  color: alpha(theme.palette.text.secondary, 0.65),

  '& section h3': {
    color: theme.palette.text.primary,
    lineHeight: '140%',
    position: 'relative',

    [theme.breakpoints.down('xs')]: {
      fontSize: '1.4rem',
    },

    '&::before': {
      content: '" "',
      position: 'absolute',
      left: 0,
      top: '-0.6em',
      maxWidth: '20vw',
      minWidth: '20%',
      height: '3px',
      background: yellow,
    },
  },
});

export const BoxCenteredHeading = styled(Box)({
  display: 'flex',
  justifyContent: 'center',

  [theme.breakpoints.down('xs')]: {
    display: 'block',
  },
});

export const Landing: Page = () => {
  return (
    <BoxContainer>
      <LandingHero />

      <USP
        dark
        primary={<Markdown>landing.why</Markdown>}
        secondary={<Markdown>landing.why_cta</Markdown>}
      />

      <USP
        primary={<Markdown>landing.usp1</Markdown>}
        secondary={<Markdown>landing.usp1_cta</Markdown>}
      />

      <USP
        reverse
        primary={<Markdown>landing.usp2</Markdown>}
        secondary={
          <>
            <Box
              position="relative"
              paddingTop="15%"
              overflow="hidden"
              marginTop="-15%"
            >
              <Box
                position="absolute"
                left="0"
                top="0"
                height="100%"
                width="25%"
              >
                <Globe color />
              </Box>
            </Box>
            <Markdown>landing.usp2_cta</Markdown>
          </>
        }
      />

      <USP
        primary={<Markdown>landing.usp3</Markdown>}
        secondary={<Markdown>landing.usp3_cta</Markdown>}
      />
      <USP
        dark
        id="how-it-works"
        primary={
          <>
            <BoxCenteredHeading>
              <Markdown>landing.how</Markdown>
            </BoxCenteredHeading>
            <Grid container spacing={2}>
              <Grid item sm={6} md={3}>
                <Markdown>landing.how1</Markdown>
              </Grid>
              <Grid item sm={6} md={3}>
                <Markdown>landing.how2</Markdown>
              </Grid>
              <Grid item sm={6} md={3}>
                <Markdown>landing.how3</Markdown>
              </Grid>
              <Grid item sm={6} md={3}>
                <Markdown>landing.how4</Markdown>
              </Grid>
            </Grid>
            <Video video={aboutVideo} videoPoster={aboutVideoPoster} />
          </>
        }
      />

      <Box pt={2} pb={10} px={8}>
        <Documents />
      </Box>

      <InsuranceProductSchema />
    </BoxContainer>
  );
};

Landing.page = 'landing';
