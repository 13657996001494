import makeStyles from '@material-ui/core/styles/makeStyles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Account } from '../hooks/useAccount';
import { theme } from '../styles/theme';
import { Person } from '../swagger';
import { validateEmail } from '../utils/validateEmail';
import { validatePersonalNumber } from '../utils/validatePersonalNumber';
import { validatePhone } from '../utils/validatePhone';
import { LabsSelect } from './forms/LabsSelect';

const styles = makeStyles({
  questionStyle: {
    hyphens: 'auto',
    verticalAlign: 'middle',

    [theme.breakpoints.down('xs')]: {
      borderBottom: '0 none',
      paddingBottom: '8px',
    },
  },
  optionsStyle: {
    textAlign: 'right',

    '&.label-first': {
      textAlign: 'left',
    },

    '& input': {
      textAlign: 'inherit',
    },

    [theme.breakpoints.down('xs')]: {
      paddingTop: '0',
      textAlign: 'left',
    },
  },
});

type Props = {
  person: Person;
  onPerson?: (person: Person) => void;
  labelFirst?: boolean;
  labsOnChange?: (locality: string) => void;
  isCheckup?: boolean;
};

export const OrderPersonDetails: React.FC<Props> = (props) => {
  const { person, onPerson, labelFirst } = props;
  const isForChild = person.type === Person.TypeEnum.Child;
  const isForMain = person.type === Person.TypeEnum.Main;
  const { admin } = Account.useContainer();
  const { questionStyle, optionsStyle } = styles();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const [pnoInvalid, setPNOInvalid] = React.useState(
    !!person.personnummer && !validatePersonalNumber(person.personnummer),
  );
  const [emailInvalid, setEmailInvalid] = React.useState(
    !isForChild && !!person.email && !validateEmail(person.email),
  );
  const [phoneInvalid, setPhoneInvalid] = React.useState(
    !isForChild && !!person.phone && !validatePhone(person.phone),
  );

  const onPNOChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const isInvalid = !!value && !validatePersonalNumber(value);
    person.personnummer = isInvalid ? undefined : value;
    setPNOInvalid(isInvalid);
    onPerson?.(person);
  };
  const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const isInvalid = !!value && !validateEmail(value);
    person.email = isInvalid ? undefined : value;
    setEmailInvalid(isInvalid);
    onPerson?.(person);
  };
  const onPhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const isInvalid = !!value && !validatePhone(value);
    person.phone = isInvalid ? undefined : value;
    setPhoneInvalid(isInvalid);
    onPerson?.(person);
  };

  const personalFields = [
    {
      label: isForChild ? 'child.personal_number' : 'personal_number',
      field: (
        <TextField
          variant="outlined"
          name="personnummer"
          type="tel"
          size="small"
          placeholder="YYYYMMDD-XXXX"
          error={pnoInvalid}
          autoFocus={!person.personnummer}
          defaultValue={person.personnummer}
          onChange={onPNOChange}
          style={{ width: '14em' }}
        />
      ),
    },
  ];

  if (!isForChild) {
    personalFields.push({
      label: 'email',
      field: (
        <TextField
          variant="outlined"
          name="email"
          type="email"
          size="small"
          error={emailInvalid}
          autoFocus={!!person.personnummer && !person.email}
          defaultValue={person.email}
          onChange={onEmailChange}
          style={{ width: '14em' }}
        />
      ),
    });
  }

  if (isForMain || (!isForChild && admin)) {
    personalFields.push({
      label: 'phone',
      field: (
        <TextField
          variant="outlined"
          name="phone"
          type="tel"
          size="small"
          error={phoneInvalid}
          autoFocus={!!person.personnummer && !!person.email && !person.phone}
          defaultValue={person.phone}
          onChange={onPhoneChange}
          style={{ width: '14em' }}
        />
      ),
    });
  }

  if (props.isCheckup) {
    personalFields.push({
      label: 'Labs',
      field: (
        <LabsSelect
          onChange={(locality: string) => {
            if (props.labsOnChange) props.labsOnChange(locality);
          }}
        ></LabsSelect>
      ),
    });
  }

  return (
    <React.Fragment>
      {personalFields &&
        personalFields.map(({ label, field }) => {
          return (
            <React.Fragment key={label}>
              <TableRow>
                {!isMobile && !labelFirst && (
                  <TableCell className={optionsStyle}>{field}</TableCell>
                )}
                <TableCell className={questionStyle}>
                  <FormattedMessage id={label} />
                </TableCell>
                {!isMobile && labelFirst && (
                  <TableCell className={`${optionsStyle} label-first`}>
                    {field}
                  </TableCell>
                )}
              </TableRow>

              {isMobile && (
                <TableRow>
                  <TableCell className={optionsStyle}>{field}</TableCell>
                </TableRow>
              )}
            </React.Fragment>
          );
        })}
    </React.Fragment>
  );
};
