import { Language, Settings } from './useSettings';

export const useLangRedirect = () => {
  const url = new URL(window.location.href);
  const {
    settings: { language },
  } = Settings.useContainer();
  const currentLanguage = url.pathname.match(/^(\/(\w{2}))(\/|$)/);
  const isValidLanguage =
    !currentLanguage ||
    Object.values(Language).includes(currentLanguage[2] as Language);

  if (currentLanguage && !isValidLanguage) {
    url.pathname = url.pathname.replace(currentLanguage[1], '');
  }

  if (!currentLanguage || !isValidLanguage) {
    url.pathname = `/${language}/${url.pathname}`.replace(/\/\//g, '/');
    return url.href.replace(url.origin, '');
  }
};
