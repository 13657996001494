import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { makeStyles } from '@material-ui/core/styles';
import { Color } from '@material-ui/lab/Alert/Alert';
import React from 'react';
import { useIntl } from 'react-intl';
import { Account } from '../hooks/useAccount';
import { Person, Product } from '../swagger';
import { ageOrPnoToAge } from '../utils/ageOrPersonalNumber';
import { parseQuiz } from '../utils/parseQuiz';
import { InsuranceProductStatusWrapper } from './InsuranceProductStatusWrapper';
import { Markdown } from './Markdown';
import TypeEnum = Person.TypeEnum;

const styles = makeStyles({
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',

    '& button': {
      margin: '8px 0',
      marginRight: '8px',

      '&:last-of-type': {
        marginRight: 0,
      },
    },
  },
});

export enum Status {
  EMPTY = 'empty',
  IN_PROGRESS = 'in_progress',
  PENDING = 'pending',
  SUCCESS = 'success',
  ADULT18 = 'adult18',
  ADULT65 = 'adult65',
  CHILD18 = 'child18',
  ERROR = 'error',
  HEALTH_CHECKUP = 'health_checkup',
}

const severityMap: Record<Status, Color> = {
  [Status.EMPTY]: 'info',
  [Status.IN_PROGRESS]: 'info',
  [Status.PENDING]: 'success',
  [Status.SUCCESS]: 'success',
  [Status.CHILD18]: 'error',
  [Status.ADULT18]: 'error',
  [Status.ADULT65]: 'error',
  [Status.ERROR]: 'error',
  [Status.HEALTH_CHECKUP]: 'info',
};

export const getStatus = (
  person: Person,
  isHealthCheckup?: boolean,
  isAdmin?: boolean,
): Status => {
  if (!person.quiz) {
    return Status.EMPTY;
  }

  const { isDone, hasProducts } = parseQuiz(person.quiz);
  const age = parseInt(ageOrPnoToAge(person.personnummer), 10);

  if (!isHealthCheckup && isDone && !hasProducts) {
    return Status.ERROR;
  }

  if (Number.isNaN(age)) {
    return Status.IN_PROGRESS;
  } else {
    if (
      person.type === 'spouse' &&
      person.email &&
      typeof person.quiz.age !== 'number'
    ) {
      return Status.PENDING;
    }
    if (person.type === 'child' && age >= 18 && !isAdmin) {
      return Status.CHILD18;
    }
    if (person.type !== 'child' && age >= 65) {
      return Status.ADULT65;
    }
    if (person.type !== 'child' && age < 18) {
      return Status.ADULT18;
    }
  }

  if (isDone && hasProducts) {
    return Status.SUCCESS;
  }

  return Status.IN_PROGRESS;
};

type Props = {
  person: Person;
  products?: Product[];
  isBroker?: boolean;
  isOrder?: boolean;
  isHealthCheckup?: boolean;
  children?: (status: Status) => React.ReactElement;
};

export const InsuranceProductStatus: React.FC<Props> = ({
  person,
  products: orderProducts,
  children,
  isOrder,
  isBroker,
  isHealthCheckup,
}) => {
  const { actions } = styles();
  const { admin, iss } = Account.useContainer();
  const [status, setStatus] = React.useState(Status.EMPTY);
  const quiz = !isHealthCheckup ? person.quiz : undefined;
  const parsedQuiz = quiz && parseQuiz(quiz);
  const productTypes =
    parsedQuiz?.products || orderProducts?.map((pr) => pr.type) || [];
  const product: Product.TypeEnum | undefined = productTypes[0];
  const availableTreatments =
    quiz?.availableTreatments || orderProducts?.[0]?.availableTreatments || [];
  const unavailableTreatments =
    quiz?.unavailableTreatments ||
    orderProducts?.[0]?.unavailableTreatments ||
    [];
  const { messages } = useIntl();
  const labelPrefix = isHealthCheckup ? 'checkup_status' : 'insurance_status';
  const possibleLabels = [
    ...(isOrder
      ? [
          `${labelPrefix}.${status}.${person.type}.order`,
          `${labelPrefix}.${status}.order`,
        ]
      : []),
    ...(isBroker
      ? [
          `${labelPrefix}.${status}.${person.type}.broker`,
          `${labelPrefix}.${status}.broker`,
        ]
      : []),
    `${labelPrefix}.${status}.${person.type}`,
    `${labelPrefix}.${status}`,
  ];

  const statusLabel = possibleLabels.find((label) => label in messages);
  const hasProducts = !!orderProducts?.length;

  React.useEffect(() => {
    if (isHealthCheckup && hasProducts) {
      setStatus(Status.SUCCESS);
    } else {
      setStatus(
        getStatus(
          {
            ...person,
            type:
              person.type === TypeEnum.Spouse && admin
                ? TypeEnum.Main
                : person.type,
          },
          isHealthCheckup,
          admin || iss === 'admin',
        ),
      );
    }
  }, [
    quiz,
    person.personnummer,
    person.type,
    admin,
    isHealthCheckup,
    hasProducts,
    iss,
  ]);

  return (
    <InsuranceProductStatusWrapper severity={severityMap[status]}>
      {status !== Status.SUCCESS && statusLabel && (
        <Markdown children={statusLabel} variant="body2" />
      )}
      {status === Status.SUCCESS && product && (
        <>
          <Markdown children={`product.${product}.title`} variant="h6" />
          {!!availableTreatments.length && (
            <List>
              {availableTreatments.map((treatment) => (
                <ListItem className="yes" key={treatment}>
                  {messages[`treatment.${treatment}`] ||
                    `treatment.${treatment}`}
                </ListItem>
              ))}
            </List>
          )}
          {!!unavailableTreatments.length && (
            <List>
              {unavailableTreatments.map((treatment) => (
                <ListItem className="no" key={treatment}>
                  {messages[`treatment.${treatment}`] ||
                    `treatment.${treatment}`}
                </ListItem>
              ))}
            </List>
          )}
        </>
      )}
      {!statusLabel &&
        possibleLabels.map((label) => <Box key={label}>{label}</Box>)}
      {children && <Box className={actions}>{children(status)}</Box>}
    </InsuranceProductStatusWrapper>
  );
};
