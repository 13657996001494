export const getGAClientId = (): string | undefined => {
  const gaCookie = document.cookie
    .split('; ')
    .find((cookie) => cookie.startsWith('_ga'));

  if (gaCookie) {
    return gaCookie.split('=')[1].split('.').reverse().splice(0, 2).join('.');
  }
};

export const getIntercomId = (): string | undefined => {
  const intercomCookie = document.cookie
    .split('; ')
    .find((cookie) => cookie.startsWith('intercom-id-'));

  if (intercomCookie) {
    return intercomCookie.split('=')[1];
  }
};

export const UTM = (): Record<string, string> => {
  const tags: Record<string, string> = {};

  for (let i = 0; i < window.localStorage.length; i++) {
    const key = window.localStorage.key(i);

    if (key && key.startsWith('utm_')) {
      const value = window.localStorage.getItem(key);

      if (value) {
        tags['X-' + key.replace('_', '-')] = value;
      }
    }
  }

  return tags;
};
