import React from 'react';
import { createContainer } from 'unstated-next';

const defaultState = {
  page: '',
};

const useMetaContainer = () => {
  const [state, updateState] = React.useState(defaultState);

  return {
    ...state,
    updateState,
  };
};

export const Meta = createContainer(useMetaContainer);

export const MetaData: React.FC<typeof defaultState> = React.memo((props) => {
  const { updateState } = Meta.useContainer();

  React.useEffect(() => {
    updateState(props);
    return () => updateState(defaultState);
  }, [props]);

  return null;
});
