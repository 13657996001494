import * as React from 'react';

const LazyEmployee = React.lazy<typeof import('../pdf/Employee').Employee>(() =>
  import('../pdf/Employee').then((module) => ({ default: module.Employee })),
);
export const SuspenseEmployee: typeof import('../pdf/Employee').Employee = (
  props,
) => (
  <React.Suspense fallback={''}>
    <LazyEmployee {...props} />
  </React.Suspense>
);

SuspenseEmployee.page = 'employee';
